import Answers from "@/components/assessment/pregnancy/answers.json";
import i18n from "@/plugins/i18n";

export class PregnancyReportCirculationService {
  constructor(results) {
    this.results = results;
  }

  getText() {
    const textArray = [];

    const seatedStandingText = this.getSeatedStandingText();
    if (seatedStandingText) {
      textArray.push(seatedStandingText);
    }

    const exerciseText = this.getExerciseText();
    if (exerciseText) {
      textArray.push(exerciseText);
    }

    const requiredExerciseText = i18n.t(
      "pregnancyAssessment.report.content.circulation.text.requiredExercise"
    );
    textArray.push(requiredExerciseText);

    return textArray;
  }

  getSeatedStandingText() {
    const { circulation } = this.results;

    const showSittingText =
      circulation?.timeSpentSittingOrStanding ===
        Answers.circulation.timeSpentSittingOrStanding.mostlySitting ||
      circulation?.mostComfortableSittingPosition ===
        Answers.circulation.mostComfortableSittingPosition.crossLegged;

    if (showSittingText) {
      return i18n.t(
        "pregnancyAssessment.report.content.circulation.text.sittingOrCrossLegged"
      );
    }

    const showStandingText =
      circulation?.timeSpentSittingOrStanding ===
      Answers.circulation.timeSpentSittingOrStanding.mostlyStanding;
    if (showStandingText) {
      return i18n.t(
        "pregnancyAssessment.report.content.circulation.text.standingTooMuch"
      );
    }

    return null;
  }

  getExerciseText() {
    const { lifestyle } = this.results;
    const isPhysicallyActive =
      lifestyle?.physicalActivity ===
      Answers.lifestyle.physicalActivity.threeOrMore;

    return i18n.t(
      `pregnancyAssessment.report.content.circulation.text.${
        isPhysicallyActive ? "active" : "notActive"
      }`
    );
  }
}
