import BaseScoreHelper from "./base-score-helper";
import DeskSetupScoreHelper from "./desk-setup-score-helper";
import HealthScoreHelper from "./health-score-helper";
import EquipmentScoreHelper from "./equipment-score-helper";
import WorkHabitsScoreHelper from "./work-habits-score-helper";
import EnvironmentScoreHelper from "./environment-score-helper";
import { withErrorHandling } from "@/components/assessment/desk/results-helpers/error-handling.js";

export default class OverallScoreHelper extends BaseScoreHelper {
  static getOverallScore(results) {
    const weights = OverallScoreHelper.calculateSectionWeights(results);

    // Get raw scores first
    const webcamRawScore =
      DeskSetupScoreHelper.calculateDeskSetupScore(results);
    const healthRawScore = HealthScoreHelper.calculateHealthScore(results);
    const setupScore =
      OverallScoreHelper.calculateSetupAndHabitsPercentage(results);

    // Calculate percentages at this level
    const webcamScore = this.getPercentageScore(
      webcamRawScore,
      DeskSetupScoreHelper.getMaxWebcamScore(results)
    );
    const healthScore = this.getPercentageScore(
      healthRawScore,
      HealthScoreHelper.maxHealthScore
    );

    return Math.round(
      webcamScore * weights.webcamWeight +
        setupScore * weights.setupWeight +
        healthScore * weights.healthWeight
    );
  }

  static calculateSectionWeights(results) {
    const webcamResult = results?.webcam?.pictureTaken;
    const healthResult =
      results?.health?.smoker && results?.health?.exercise != null;

    let setupWeight = 0.5;
    let webcamWeight = webcamResult ? 0.35 : 0;
    let healthWeight = healthResult ? 0.15 : 0;

    const totalWeights = setupWeight + webcamWeight + healthWeight;

    // Normalize weights to sum to 1
    return {
      setupWeight: setupWeight / totalWeights,
      webcamWeight: webcamWeight / totalWeights,
      healthWeight: healthWeight / totalWeights
    };
  }

  static calculateSetupAndHabitsPercentage(results) {
    const workHabitsPercentage =
      WorkHabitsScoreHelper.getWorkHabitsPercentage(results);
    const equipmentPercentage =
      EquipmentScoreHelper.calculateEquipmentPercentage(results);
    const environmentPercentage =
      EnvironmentScoreHelper.calculateEnvironmentPercentage(results);

    if (environmentPercentage !== null) {
      return Math.round(
        workHabitsPercentage * WorkHabitsScoreHelper.getWorkHabitsWeight() +
          equipmentPercentage * EquipmentScoreHelper.getEquipmentWeight() +
          environmentPercentage * EnvironmentScoreHelper.getEnvironmentWeight()
      );
    }

    const workHabitsWeight = 0.3;
    const equipmentWeight = 0.7;

    return Math.round(
      workHabitsPercentage * workHabitsWeight +
        equipmentPercentage * equipmentWeight
    );
  }
}

OverallScoreHelper.getOverallScore = withErrorHandling(
  OverallScoreHelper.getOverallScore,
  0
);

OverallScoreHelper.calculateSectionWeights = withErrorHandling(
  OverallScoreHelper.calculateSectionWeights,
  {
    setupWeight: 1,
    webcamWeight: 0,
    healthWeight: 0
  }
);

OverallScoreHelper.calculateSetupAndHabitsPercentage = withErrorHandling(
  OverallScoreHelper.calculateSetupAndHabitsPercentage,
  0
);
