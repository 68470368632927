<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M150.238 392L189.8 528.325L208.407 630.68H227.084L208.441 392H150.238Z"
      fill="#997659"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M132.46 392C135.221 462.82 134.132 501.447 133.514 507.882C132.896 514.316 129.389 557.409 105.488 632.84H124.913C156.566 560.477 167.616 517.384 171.246 507.882C174.876 498.38 185.598 459.752 201.252 392H132.46Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M149.391 392C159.681 438.91 177.479 513.79 202.785 616.64H230.102C232.119 511.074 223.536 441.594 208.673 392H149.391Z"
      fill="#17282F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M132.326 392C135.03 462.82 128.023 532.591 106.32 617.743H135.466C167.176 546.459 189.904 477.032 205.437 392L136 372.5C136 372.5 133.735 377.869 133 381.5C132.185 385.527 132.326 392 132.326 392Z"
      fill="#223A43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M102.719 646.879L103.707 630.949C103.75 630.257 104.422 629.784 105.1 629.928C114.46 631.917 127.371 627.439 127.371 627.439C140.364 636.643 159.096 640.867 165.691 642.132C166.968 642.376 167.786 643.664 167.432 644.914L165.956 650.119H127.371H104.862L102.719 646.879Z"
      fill="#17282F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M204.238 646.879L205.226 630.949C205.269 630.257 205.942 629.784 206.619 629.928C215.98 631.917 228.89 627.439 228.89 627.439C241.883 636.643 260.615 640.867 267.211 642.132C268.487 642.376 269.306 643.664 268.951 644.914L267.475 650.119H228.89H206.382L204.238 646.879Z"
      fill="#17282F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M170.975 230.091C179.589 235.283 188.053 237.791 192.026 236.767C201.988 234.199 202.904 197.754 195.196 183.386C187.488 169.019 147.86 162.756 145.863 191.223C145.17 201.104 149.318 209.921 155.448 217.116L144.457 268.342H176.382L170.975 230.091Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M198.211 189.839C197.895 187.976 197.122 185.908 196.498 184.146C195.695 181.875 194.478 179.827 193.139 177.897C190.618 174.263 187.594 170.981 184.255 168.258C178.156 163.283 170.393 160.414 162.759 161.345C158.904 161.816 155.162 163.286 151.977 165.733C149.1 167.945 146.332 171.2 142.606 171.433C138.552 171.688 134.836 168.109 131.584 165.899C127.918 163.408 124.019 161.592 119.734 160.887C112.556 159.705 105.813 162.13 100.866 168.019C95.6024 174.286 91.7758 183.476 95.2328 191.779C95.8753 193.323 96.702 194.624 97.8884 195.702C98.9765 196.691 100.638 197.744 101.079 199.324C101.548 201.004 100.148 203.171 99.6054 204.686C98.8278 206.858 98.1894 209.137 98.4163 211.497C98.7893 215.373 101.225 219.074 103.797 221.621C106.415 224.214 109.636 225.7 113.077 226.386C115.375 226.843 117.723 227.096 120.058 226.924C121.217 226.839 122.24 226.517 123.363 226.261C124.455 226.012 125.066 226.302 125.992 226.901C130.297 229.684 134.925 230.76 139.896 230.346C144.091 229.997 148.926 228.98 152.348 226.106C156.148 222.914 156.073 218.512 155.178 213.809C156.126 214.279 158.747 214.569 157.181 212.698C156.56 211.956 155.388 211.655 154.592 211.219C153.665 210.711 152.723 210.055 152.003 209.227C149.097 205.883 152.033 199.079 155.686 197.958C161.114 196.292 162.329 203.968 166.204 205.954C168.432 207.095 170.762 205.535 172.431 203.942C174.667 201.807 176.191 198.979 177.507 196.102C178.591 193.732 179.599 191.329 180.704 188.971C181.219 187.87 183.005 184.757 182.108 183.512C185.996 182.736 190.326 184.352 193.675 186.471C195.059 187.347 196.114 188.312 196.852 189.858C197.01 190.19 197.354 191.175 197.787 191.215C198.591 191.29 198.291 190.307 198.211 189.839Z"
      fill="#070707"
    />
    <path
      d="M129.286 290.242C127.433 281.869 129.942 272.652 135.784 266.373C137.271 264.774 139.439 263.258 141.483 264.028C143.529 264.8 144.156 267.385 144.164 269.572C144.196 278.675 138.135 287.495 129.626 290.727"
      fill="#E7E8E5"
    />
    <path
      d="M136.773 272.692C136.729 272.662 136.44 272.994 135.955 273.625C135.462 274.25 134.809 275.199 134.034 276.394C132.554 278.801 130.543 282.305 129.709 286.655C129.299 288.821 129.283 290.951 129.55 292.842C129.81 294.74 130.411 296.367 130.977 297.682C131.59 298.98 132.161 299.991 132.639 300.632C133.096 301.287 133.368 301.632 133.414 301.602C133.548 301.518 132.543 300.061 131.492 297.466C131.007 296.158 130.481 294.572 130.271 292.744C130.055 290.921 130.09 288.888 130.485 286.802C131.285 282.614 133.168 279.137 134.514 276.68C135.916 274.234 136.878 272.765 136.773 272.692Z"
      fill="#DADBD8"
    />
    <path
      d="M178.87 251.447C179.125 251.759 192.227 269.606 192.227 269.606C192.227 269.606 212.003 295.084 213.858 303.444C216.599 315.808 210.992 321.599 210.992 321.599C210.992 321.599 224.838 351.875 226.13 361.659C227.422 371.443 225.207 379.752 221.884 387.136C218.561 394.521 211.398 407.504 211.398 407.504C211.398 407.504 134.895 394.99 128.433 386.313L137.886 361.659L127.548 290.584C127.548 290.584 124.38 262.186 133.194 254.959C138.941 250.248 147.493 245.999 147.493 245.999L178.87 251.447Z"
      fill="#F8A531"
    />
    <path
      d="M176.13 328.292C176.132 328.318 176.561 328.322 177.333 328.243C178.105 328.166 179.217 327.969 180.547 327.562C183.191 326.771 186.748 324.909 189.499 321.615C192.246 318.317 193.44 314.484 193.744 311.74C193.908 310.359 193.9 309.23 193.838 308.457C193.777 307.684 193.696 307.263 193.67 307.266C193.57 307.269 193.82 308.983 193.417 311.695C193.035 314.384 191.818 318.102 189.14 321.317C186.46 324.525 183.02 326.391 180.444 327.248C177.848 328.131 176.116 328.192 176.13 328.292Z"
      fill="#C68427"
      stroke="#C68427"
    />
    <path
      d="M177.813 331.52C180.62 331.52 183.509 330.438 185.696 328.63C189.172 325.757 191.138 321.447 192.285 317.072C192.257 317.136 192.229 317.201 192.2 317.265C191.837 318.272 191.243 319.299 190.499 320.292C190.191 320.74 189.857 321.183 189.496 321.616C186.745 324.909 183.189 326.771 180.544 327.563C179.82 327.785 179.161 327.944 178.586 328.055C175.529 329.113 172.599 330.008 172.599 330.008C174.152 331.05 175.964 331.52 177.813 331.52Z"
      fill="#DB8E24"
    />
    <path
      d="M178.588 328.053C179.164 327.942 179.822 327.783 180.547 327.561C183.191 326.769 186.747 324.907 189.499 321.614C189.859 321.181 190.193 320.738 190.502 320.29C188.482 322.988 185.362 325.427 182.798 326.487C181.577 326.991 180.067 327.541 178.588 328.053ZM192.202 317.263C192.231 317.199 192.26 317.134 192.287 317.069C192.33 316.905 192.373 316.74 192.414 316.576C192.357 316.803 192.285 317.033 192.202 317.263Z"
      fill="#0F191D"
    />
    <path
      d="M192.053 404.141C195.595 404.87 199.21 405.058 202.722 404.462L203.239 401.23C203.176 400.685 203.03 400.148 202.787 399.63C202.062 398.086 200.916 396.785 199.758 395.533C184.107 378.609 163.842 366.794 143.899 355.233C143.637 355.08 143.359 354.924 143.068 354.847C141.6 356.667 139.964 358.699 138.275 360.809C138.098 361.383 137.986 361.976 137.964 362.589C137.89 364.619 138.814 366.55 139.885 368.276C145.175 376.796 154.151 382.168 162.383 387.898C168.485 392.145 174.373 396.769 180.993 400.152C184.441 401.915 188.204 403.348 192.053 404.141Z"
      fill="#DB8E24"
    />
    <path
      d="M124.41 286.011C124.663 279.958 125.92 267.062 132.477 259.748C140.361 250.998 149.65 251.867 155.581 262.044C161.629 272.42 158.81 283.194 159.251 288.349C159.795 294.706 156.741 317.418 156.228 322.786L155.755 341.879C155.755 341.879 196.811 384.007 205.848 388.518L196.811 403.072C186.264 402.026 138.237 370.086 128.161 350.669C124.001 342.654 124.158 292.063 124.41 286.011Z"
      fill="#B28B67"
    />
    <path
      d="M159.859 307.264L120.999 299.446C120.999 299.446 124.194 275.147 128.477 263.341C131.808 254.157 142.153 250.698 142.153 250.698L159.236 261.018C159.236 261.018 162.158 274.842 161.929 287.488C161.698 300.136 159.859 307.264 159.859 307.264Z"
      fill="#F8A531"
    />
    <path
      d="M138.239 256.067C138.237 256.083 138.465 256.118 138.899 256.171C139.378 256.227 140.009 256.301 140.797 256.392C142.439 256.601 144.839 256.856 147.688 257.639C150.504 258.411 153.871 259.881 156.382 262.879C158.892 265.856 160.333 270.062 160.818 274.521C161.367 278.999 160.83 283.326 160.701 287.242C160.514 291.152 160.344 294.678 160.202 297.658C160.078 300.585 159.977 302.973 159.905 304.684C159.881 305.477 159.862 306.113 159.848 306.595C159.839 307.032 159.843 307.263 159.859 307.264C159.875 307.265 159.903 307.036 159.942 306.6C159.978 306.119 160.026 305.485 160.086 304.694C160.194 302.985 160.345 300.599 160.531 297.674C160.703 294.693 160.905 291.168 161.131 287.257C161.283 283.342 161.837 279.02 161.282 274.468C160.791 269.952 159.313 265.651 156.71 262.601C155.415 261.086 153.884 259.922 152.334 259.082C150.783 258.23 149.214 257.708 147.775 257.321C144.88 256.569 142.467 256.365 140.816 256.211C139.988 256.141 139.344 256.099 138.907 256.078C138.47 256.055 138.24 256.051 138.239 256.067Z"
      fill="#C68427"
      stroke="#C68427"
    />
    <path
      d="M205.61 387.57C205.61 387.57 216.234 384.355 219.303 384.798C222.371 385.242 225.812 390.055 225.812 390.055C225.812 390.055 225.557 391.247 224.9 392.399C224.9 392.399 226.96 398.833 222.643 401.377C222.643 401.377 223.968 404.352 220.053 406.937C220.053 406.937 218.823 409.114 215.692 410.288C215.692 410.288 214.567 414.37 211.405 414.865C208.242 415.359 206.179 414.339 206.179 414.339C206.179 414.339 197.14 407.467 194.757 402.895L205.61 387.57Z"
      fill="#B28B67"
    />
    <path
      d="M219.906 390.562C219.879 390.694 220.99 391.005 222.293 391.542C223.599 392.072 224.609 392.629 224.684 392.517C224.755 392.417 223.826 391.661 222.469 391.11C221.117 390.554 219.924 390.441 219.906 390.562Z"
      fill="#B77A23"
    />
    <path
      d="M221.922 328.293C221.947 327.949 221.735 324.597 219.654 323.178C217.573 321.759 211.358 321.282 211.358 321.282L205.97 326.193C205.97 326.193 206.013 328.728 209.692 328.769C209.692 328.769 201.941 332.453 201.772 334.742C201.603 337.03 202.997 338.398 202.997 338.398C202.997 338.398 199.455 339.633 200.079 342.094C200.702 344.557 203.016 344.383 203.016 344.383C203.016 344.383 201.838 347.862 205.869 347.815C209.899 347.767 216.115 346.156 216.115 346.156C216.115 346.156 216.294 348.402 213.991 348.439C211.688 348.474 209.874 349.607 209.874 349.607C209.874 349.607 209.094 352.426 211.735 352.506C214.375 352.585 216.743 353.22 219.363 352.033C221.981 350.846 224.295 350.673 224.244 348.252C224.193 345.833 223.884 345.349 223.884 345.349C223.884 345.349 225.851 342.503 224.818 340.702C223.784 338.899 223.784 338.899 223.784 338.899C223.784 338.899 226.056 336.19 224.827 334.144C223.597 332.097 221.927 329.788 221.927 329.788L221.922 328.293Z"
      fill="#B28B67"
    />
    <path
      d="M202.983 338.279C203.009 338.342 203.884 338.028 205.292 337.512C206.699 336.995 208.642 336.285 210.805 335.546C212.968 334.807 214.939 334.181 216.369 333.728C217.798 333.275 218.681 332.988 218.664 332.924C218.649 332.863 217.734 333.039 216.277 333.411C214.822 333.783 212.825 334.363 210.653 335.104C208.482 335.846 206.549 336.609 205.169 337.205C203.789 337.801 202.959 338.223 202.983 338.279Z"
      fill="#B77A23"
    />
    <path
      d="M202.484 344.351C202.521 344.474 206.139 343.466 210.561 342.098C214.984 340.731 218.538 339.523 218.5 339.4C218.462 339.277 214.846 340.286 210.423 341.653C206.001 343.02 202.445 344.228 202.484 344.351Z"
      fill="#B77A23"
    />
    <path
      d="M206.002 330.027C206.041 330.15 207.99 329.637 210.354 328.881C212.72 328.124 214.605 327.412 214.566 327.29C214.526 327.166 212.577 327.68 210.213 328.436C207.848 329.192 205.963 329.905 206.002 330.027Z"
      fill="#B77A23"
    />
    <rect
      width="143"
      height="100"
      transform="translate(307 123)"
      fill="white"
    />
    <path
      d="M441.836 130.156C442.388 130.156 442.836 130.604 442.836 131.156V214.844C442.836 215.396 442.388 215.844 441.836 215.844H315.164C314.612 215.844 314.164 215.396 314.164 214.844V131.156C314.164 130.604 314.612 130.156 315.164 130.156H441.836ZM307 222C307 222.552 307.448 223 308 223H449C449.552 223 450 222.552 450 222V124C450 123.448 449.552 123 449 123L308 123C307.448 123 307 123.448 307 124V222Z"
      fill="#934B2A"
    />
    <g opacity="0.6">
      <path d="M423 206.908H333V138.999H423V206.908Z" fill="white" />
      <path
        d="M424.636 199.363C424.636 199.916 424.189 200.363 423.636 200.363H334C333.448 200.363 333 199.916 333 199.363V144.909C333 144.356 333.448 143.909 334 143.909H423.636C424.189 143.909 424.636 144.356 424.636 144.909V199.363Z"
        fill="#2E2838"
      />
      <path
        d="M343.637 185.83L362.091 151.273C362.091 151.273 379.292 158.026 398.284 151.538L416.918 185.565C416.918 185.565 381.442 202.116 343.637 185.83Z"
        fill="#4B4259"
      />
      <path
        d="M402.099 164.175C401.951 163.695 401.769 163.226 401.539 162.776C400.693 161.116 399.174 159.857 397.21 159.458C392.854 158.574 389.242 160.832 385.219 161.772C383.624 162.145 381.92 162.246 380.344 161.811C378.924 161.419 377.686 160.62 376.348 160.033C372.732 158.45 368.29 158.642 364.562 159.828C361.396 160.835 358.69 163.151 357.568 166.083C356.649 168.485 356.741 171.138 357.361 173.621C359.389 181.747 366.635 188.519 375.965 188.635C380.939 188.696 385.776 187.232 390.219 185.277C394.172 183.538 398.204 181.385 400.553 177.85C402.785 174.49 403.151 170.317 402.579 166.413C402.469 165.659 402.324 164.905 402.099 164.175Z"
        fill="#2E2838"
      />
      <path
        d="M391.345 171.791C391.345 171.791 393.037 177.377 386.937 178C386.937 178 385.152 177.65 383.399 178.649C381.646 179.648 376.48 182.824 373.232 180.589C369.984 178.354 372.35 174.096 372.557 173.03C372.764 171.964 370.617 172.513 369.258 171.255C367.898 169.997 367.877 167.551 369.516 167.408C371.156 167.266 370.015 169.26 370.015 169.26C370.015 169.26 372.681 170.688 374.03 170.723C375.378 170.758 374.59 174.498 374.59 174.498C374.59 174.498 375.545 172.536 377.474 172.99C377.176 172.919 377.819 171.582 377.887 171.493C378.508 170.689 379.518 170.449 380.205 169.765C380.645 169.327 380.694 168.336 381.493 168.398C382.557 168.481 382.224 169.546 381.925 170.125C381.525 170.903 380.601 171.276 380.268 172.091C379.869 173.069 380.584 173.496 381.469 173.553C381.469 173.553 380.286 172.202 381.884 171.422C383.481 170.642 383.274 169.774 384.591 169.234C385.909 168.693 389.26 166.914 391.345 171.791Z"
        fill="#787185"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SecondTrimester"
};
</script>
