export const Theme = {
  WEBCAM: "webcam",
  PAIN: "pain",
  SETUP: "setup",
  HEALTH: "health",
  REPORT: "report"
};

export const StepId = {
  WELCOME: "welcome",
  GOAL_SELECTION: "goalSelection",
  WEBCAM_INSTRUCTIONS: "webcamInstructions",
  WEBCAM: "webcam",
  PAIN: "pain",
  WORKHABITS: "workhabits",
  SIMS: "sims",
  ELEVATION_PLANNER: "elevationPlanner",
  HEIGHT: "height",
  SEATING: "seating",
  EQUIPMENT: "equipment",
  HEALTH: "health",
  REPORT: "report"
};

export const AssessmentSteps = {
  welcome: {
    id: StepId.WELCOME,
    component: "DeskAssessmentLanding",
    navigation: {
      showStepper: false,
      showNavigation: false
    },
    validation: false
  },
  goalSelection: {
    id: StepId.GOAL_SELECTION,
    navigation: {
      showStepper: false,
      showNavigation: false
    },
    validation: false
  },
  webcamInstructions: {
    id: StepId.WEBCAM_INSTRUCTIONS,
    theme: Theme.WEBCAM,
    navigation: {
      showStepper: false,
      showNavigation: true,
      canSkip: true
    },
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #E8F2FF 560px, #BDD3EE 560px)"
    }
  },
  webcam: {
    id: StepId.WEBCAM,
    theme: Theme.WEBCAM,
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.webcam.title"
    },
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #E8F2FF 560px, #BDD3EE 560px)"
    }
  },
  pain: {
    id: StepId.PAIN,
    theme: Theme.PAIN,
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.pain.title"
    },
    form: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #F3F9FA 550px, #DBEEEF 550px)"
    }
  },
  workhabits: {
    id: StepId.WORKHABITS,
    theme: Theme.SETUP,
    image: "undraw_in_the_office.svg",
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.setup.title"
    },
    form: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #E8F2FF 560px, #BDD3EE 560px)"
    }
  },
  sims: {
    id: StepId.SIMS,
    theme: Theme.SETUP,
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.setup.title"
    },
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #E8F2FF 560px, #BDD3EE 560px)"
    }
  },
  elevationPlanner: {
    id: StepId.ELEVATION_PLANNER,
    theme: Theme.SETUP,
    navigation: {
      showStepper: true,
      showNavigation: true
    },
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #E8F2FF 560px, #BDD3EE 560px)"
    }
  },
  height: {
    id: StepId.HEIGHT,
    theme: Theme.SETUP,
    navigation: {
      showStepper: true,
      showNavigation: true
    },
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #E8F2FF 560px, #BDD3EE 560px)"
    }
  },
  seating: {
    id: StepId.SEATING,
    theme: Theme.SETUP,
    image: "undraw_multitasking.svg",
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.setup.title"
    },
    form: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #E8F2FF 560px, #BDD3EE 560px)"
    }
  },
  equipment: {
    id: StepId.EQUIPMENT,
    theme: Theme.SETUP,
    image: "undraw_work_time.svg",
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "deskAssessment.setup.title"
    },
    form: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #E8F2FF 560px, #BDD3EE 560px)"
    }
  },
  health: {
    id: StepId.HEALTH,
    theme: Theme.HEALTH,
    image: "undraw_working_out.svg",
    navigation: {
      showStepper: true,
      showNavigation: true,
      stepperLabel: "assessment.form.health.shortTitle"
    },
    form: true,
    BACKGROUND: {
      backgroundImage:
        "linear-gradient(to bottom, #FDF9E6 560px, #F6EDBE 560px)"
    }
  },
  report: {
    id: StepId.REPORT,
    navigation: {
      showStepper: false,
      showNavigation: false
    }
  }
};

export const STEP_ORDER = Object.keys(AssessmentSteps);

export const getStepByIndex = index => {
  return index >= 0 && index < STEP_ORDER.length
    ? AssessmentSteps[STEP_ORDER[index]]
    : null;
};

export const getStepIndex = stepId => {
  return STEP_ORDER.findIndex(step => step === stepId);
};

export const getSchemaPageFromId = (schema, id) => {
  for (const sectionKey in schema) {
    const section = schema[sectionKey];

    if (section.pages) {
      const page = section.pages.find(page => page.name === id);

      if (page) {
        return page;
      }
    }
  }

  return null;
};

export const PRE_ASSESSMENT_STEPS = [StepId.WELCOME, StepId.GOAL_SELECTION];

export const isPreAssessmentStep = stepId =>
  PRE_ASSESSMENT_STEPS.includes(stepId);

export const getFirstAssessmentStepIndex = () =>
  STEP_ORDER.findIndex(step => !isPreAssessmentStep(AssessmentSteps[step].id));
