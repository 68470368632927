<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M371.53 449.572C371.368 451.116 371.04 459.173 371.53 461C372.172 463.398 373.653 464.397 374.5 465.5C375.713 467.079 376.363 469.042 376 471C375.517 473.606 375 474.336 371.126 475.688C366.059 477.456 364.058 487.995 358.5 492.5C356.791 493.886 354.153 494.016 351.739 494.016C349.284 494.016 346.722 493.902 345.877 492.744C345.031 491.586 344.711 489.909 345.324 488.568C346.364 486.291 350.876 483.761 353.919 480.743C358.085 476.612 358.875 464.681 358.875 464.681L356.59 450.084L371.53 449.572Z"
      fill="#33535F"
    />
    <path
      d="M334.294 278.748C333.759 280.087 322.783 310.872 331.082 339.782C339.38 368.693 345.002 379.668 344.199 390.375C343.396 401.083 356.049 460.791 356.049 460.791L373.132 458.474L363.876 333.893L368.023 327.86L363.876 283.835L334.294 278.748Z"
      fill="#17282F"
    />
    <path
      d="M328.357 324.846C328.292 324.189 328.12 322.39 328.061 321.16C328.075 321.165 328.09 321.168 328.105 321.172C328.165 322.389 328.248 323.614 328.357 324.846Z"
      fill="#A7A9A8"
    />
    <path
      d="M346.842 329.924C340.206 329.924 328.385 325.095 328.385 325.095C328.385 325.095 328.375 325.003 328.359 324.844C328.25 323.612 328.167 322.387 328.107 321.17C334.238 322.835 343.315 325.095 347.419 325.095C348.449 325.095 349.165 324.953 349.444 324.623C351.118 322.64 353.377 298.127 354.124 285.728C354.346 285.75 354.568 285.771 354.791 285.792C355.308 292.527 355.908 302.404 355.477 307.599C354.781 315.961 354.332 325.159 350.562 328.886C349.814 329.627 348.488 329.924 346.842 329.924Z"
      fill="#0F191D"
    />
    <path
      d="M376.321 269.113C374.983 269.648 361.062 285.442 361.062 285.442L368.023 327.86C369.88 329.739 380.475 331.119 384.998 331.852L375.07 332.738L377.905 348.94C377.905 348.94 426.542 359.779 437.785 351.748C449.028 343.718 444.829 329.645 441.081 325.094C437.334 320.543 414.869 303.109 390.509 296.417L376.321 269.113Z"
      fill="#17282F"
    />
    <path
      d="M372.331 332.657C372.331 332.657 366.485 330.496 362.737 331.513C358.989 332.529 359.624 334.181 359.497 337.23C359.37 340.28 363.276 346.316 363.53 351.246C363.785 356.176 363.343 369.633 365.55 372.174C365.55 372.174 368.424 372.746 368.091 370.871C367.757 368.997 369.496 364.396 370.494 361.327C371.239 359.031 373.316 349.452 377.906 348.615L375.07 332.738L372.331 332.657Z"
      fill="#33535F"
    />
    <path
      d="M429.896 343.61C429.857 343.646 429.255 342.975 428.049 341.899C426.855 340.814 424.979 339.379 422.396 338.087C419.812 336.803 416.535 335.687 412.788 334.924C409.046 334.134 404.858 333.603 400.47 333.062C396.077 332.554 391.869 332.246 388.045 331.91C384.219 331.585 380.777 331.218 377.901 330.799C375.024 330.382 372.714 329.925 371.134 329.549C370.34 329.371 369.735 329.191 369.321 329.082C368.909 328.968 368.695 328.9 368.699 328.885C368.703 328.869 368.925 328.905 369.344 328.989C369.809 329.085 370.416 329.211 371.173 329.367C372.762 329.687 375.073 330.092 377.947 330.466C380.82 330.841 384.257 331.172 388.082 331.473C391.904 331.784 396.117 332.079 400.525 332.589C404.923 333.132 409.114 333.675 412.88 334.495C416.648 335.286 419.955 336.448 422.549 337.787C425.144 339.134 427.011 340.628 428.174 341.762C428.791 342.296 429.179 342.803 429.478 343.109C429.764 343.427 429.908 343.599 429.896 343.61Z"
      fill="#33535F"
    />
    <path
      d="M380.441 268.078C380.448 267.984 380.453 267.886 380.46 267.783C380.461 267.784 380.462 267.785 380.464 267.786L380.441 268.078Z"
      fill="#A7A9A8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M361.456 177.145C367.278 180.654 372.999 182.35 375.684 181.657C382.418 179.922 383.037 155.287 377.827 145.576C372.617 135.864 345.831 131.631 344.481 150.873C344.013 157.551 346.817 163.511 350.96 168.374L343.531 203H365.11L361.456 177.145Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M379.863 149.938C379.649 148.679 379.127 147.281 378.705 146.09C378.162 144.555 377.34 143.171 376.435 141.866C374.731 139.41 372.687 137.192 370.43 135.351C366.308 131.988 361.06 130.049 355.9 130.679C353.294 130.997 350.765 131.99 348.612 133.645C346.668 135.139 344.797 137.339 342.278 137.497C339.538 137.669 337.026 135.251 334.828 133.757C332.35 132.073 329.714 130.846 326.818 130.369C321.967 129.57 317.409 131.209 314.065 135.189C310.507 139.425 307.921 145.637 310.257 151.25C310.692 152.293 311.25 153.172 312.052 153.901C312.788 154.57 313.911 155.281 314.209 156.349C314.526 157.485 313.58 158.949 313.213 159.974C312.687 161.442 312.256 162.982 312.409 164.578C312.661 167.198 314.308 169.699 316.046 171.421C317.815 173.173 319.993 174.178 322.319 174.641C323.872 174.95 325.459 175.121 327.037 175.005C327.821 174.947 328.512 174.73 329.271 174.557C330.009 174.388 330.422 174.584 331.048 174.989C333.958 176.87 337.087 177.598 340.446 177.318C343.282 177.082 346.55 176.395 348.863 174.452C351.432 172.294 351.381 169.319 350.776 166.14C351.416 166.458 353.188 166.654 352.129 165.389C351.71 164.888 350.918 164.684 350.379 164.389C349.753 164.046 349.117 163.603 348.63 163.043C346.665 160.783 348.65 156.184 351.119 155.426C354.788 154.3 355.609 159.488 358.228 160.831C359.734 161.602 361.31 160.547 362.438 159.47C363.949 158.028 364.979 156.116 365.868 154.171C366.601 152.569 367.283 150.945 368.029 149.351C368.378 148.607 369.585 146.503 368.979 145.661C371.607 145.137 374.533 146.229 376.797 147.661C377.732 148.253 378.445 148.906 378.944 149.951C379.051 150.175 379.284 150.841 379.576 150.868C380.12 150.919 379.917 150.254 379.863 149.938Z"
      fill="#070707"
    />
    <path
      d="M333.277 217.804C332.025 212.144 333.721 205.913 337.67 201.67C338.675 200.589 340.14 199.564 341.522 200.084C342.905 200.607 343.328 202.354 343.334 203.832C343.356 209.985 339.259 215.946 333.508 218.131"
      fill="#E7E8E5"
    />
    <path
      d="M338.338 205.941C338.308 205.92 338.113 206.145 337.785 206.571C337.452 206.994 337.011 207.635 336.487 208.442C335.486 210.07 334.127 212.438 333.564 215.378C333.286 216.842 333.275 218.282 333.456 219.56C333.632 220.843 334.038 221.943 334.421 222.832C334.835 223.709 335.221 224.392 335.544 224.826C335.853 225.268 336.037 225.502 336.067 225.481C336.158 225.425 335.479 224.44 334.768 222.686C334.441 221.802 334.085 220.729 333.943 219.494C333.797 218.262 333.821 216.888 334.088 215.478C334.628 212.647 335.901 210.297 336.811 208.636C337.759 206.983 338.409 205.99 338.338 205.941Z"
      fill="#DADBD8"
    />
    <path
      d="M366.792 191.581C366.964 191.792 377.696 203.783 377.696 203.783C377.696 203.783 393.242 216.625 394.256 226.088C395.395 231.227 392.959 234.928 390.913 237.016C389.884 238.066 389.85 239.966 391.017 240.86C395.589 244.366 405.163 252.281 409.125 259.885C412.015 265.432 413.65 268.858 414.149 275.093C414.943 285.032 413.806 292.729 406.421 299.426C401.914 303.513 392.227 305.848 392.227 305.848C392.227 305.848 340.21 289.588 333.203 283.277C332.859 282.967 332.801 282.478 332.966 282.046L339.09 266.076L332.102 218.035C332.102 218.035 329.961 198.84 335.918 193.955C339.803 190.77 345.583 187.899 345.583 187.899L366.792 191.581Z"
      fill="#F8A531"
    />
    <path
      d="M364.941 243.522C364.942 243.54 365.232 243.542 365.754 243.489C366.276 243.437 367.027 243.304 367.926 243.029C369.714 242.494 372.117 241.235 373.977 239.009C375.834 236.78 376.641 234.189 376.847 232.334C376.957 231.4 376.952 230.637 376.91 230.115C376.868 229.592 376.814 229.308 376.796 229.31C376.729 229.312 376.897 230.471 376.625 232.304C376.367 234.121 375.544 236.634 373.735 238.807C371.923 240.976 369.598 242.237 367.856 242.817C366.102 243.413 364.931 243.455 364.941 243.522Z"
      fill="#C68427"
      stroke="#C68427"
      stroke-width="0.675926"
    />
    <path
      d="M366.077 245.703C367.974 245.703 369.927 244.972 371.406 243.75C373.755 241.808 375.084 238.895 375.859 235.937C375.841 235.981 375.821 236.025 375.802 236.068C375.556 236.749 375.155 237.443 374.652 238.114C374.444 238.417 374.218 238.716 373.974 239.009C372.115 241.235 369.711 242.494 367.924 243.029C367.434 243.179 366.989 243.286 366.6 243.361C364.533 244.076 362.553 244.682 362.553 244.682C363.603 245.386 364.828 245.703 366.077 245.703Z"
      fill="#DB8E24"
    />
    <path
      d="M366.6 243.359C366.989 243.284 367.435 243.177 367.924 243.027C369.712 242.492 372.116 241.233 373.975 239.007C374.219 238.714 374.445 238.415 374.653 238.113C373.288 239.936 371.179 241.585 369.446 242.301C368.621 242.642 367.6 243.013 366.6 243.359ZM375.803 236.066C375.822 236.023 375.841 235.979 375.86 235.936C375.889 235.825 375.918 235.713 375.946 235.602C375.907 235.756 375.858 235.911 375.803 236.066Z"
      fill="#0F191D"
    />
    <path
      d="M372.216 298.67C374.557 299.369 376.981 299.708 379.381 299.513L379.919 297.367C379.909 296.996 379.841 296.626 379.708 296.263C379.311 295.181 378.615 294.237 377.909 293.326C368.365 281.01 355.413 271.864 342.664 262.907C342.496 262.789 342.318 262.668 342.127 262.599C341.032 263.737 339.811 265.01 338.55 266.331C338.396 266.708 338.286 267.1 338.236 267.512C338.066 268.874 338.575 270.229 339.195 271.454C342.256 277.502 347.985 281.647 353.191 285.989C357.05 289.207 360.743 292.667 365.002 295.334C367.221 296.724 369.67 297.91 372.216 298.67Z"
      fill="#DB8E24"
    />
    <path
      d="M329.981 214.942C330.152 210.85 331.001 202.134 335.434 197.19C340.763 191.276 347.041 191.863 351.05 198.742C355.138 205.755 353.233 213.038 353.531 216.522C353.898 220.819 351.834 236.17 351.487 239.799L351.168 252.704C351.168 252.704 376.318 290.969 382.426 294.018L374.315 299.764C367.185 299.056 339.327 271.77 332.516 258.646C329.704 253.228 329.81 219.032 329.981 214.942Z"
      fill="#B28B67"
    />
    <path
      d="M353.942 229.309L327.675 224.024C327.675 224.024 329.835 207.6 332.73 199.62C334.982 193.413 341.974 191.074 341.974 191.074L353.521 198.05C353.521 198.05 355.496 207.394 355.341 215.942C355.185 224.491 353.942 229.309 353.942 229.309Z"
      fill="#F8A531"
    />
    <path
      d="M339.328 194.703C339.326 194.714 339.48 194.738 339.774 194.774C340.098 194.812 340.524 194.861 341.057 194.923C342.167 195.064 343.789 195.237 345.715 195.766C347.618 196.288 349.894 197.282 351.591 199.308C353.288 201.32 354.261 204.163 354.59 207.177C354.961 210.204 354.598 213.128 354.51 215.776C354.384 218.418 354.269 220.801 354.173 222.816C354.089 224.794 354.021 226.409 353.972 227.565C353.956 228.101 353.943 228.531 353.934 228.857C353.928 229.152 353.931 229.308 353.942 229.309C353.952 229.309 353.971 229.155 353.997 228.86C354.021 228.535 354.054 228.106 354.095 227.572C354.168 226.416 354.27 224.804 354.395 222.826C354.512 220.812 354.649 218.429 354.801 215.785C354.904 213.139 355.278 210.218 354.903 207.141C354.571 204.089 353.572 201.181 351.813 199.12C350.937 198.096 349.903 197.309 348.855 196.741C347.806 196.165 346.746 195.813 345.774 195.551C343.816 195.043 342.186 194.905 341.07 194.801C340.51 194.754 340.075 194.725 339.779 194.711C339.484 194.695 339.328 194.692 339.328 194.703Z"
      fill="#C68427"
      stroke="#C68427"
      stroke-width="0.675926"
    />
    <path
      d="M397.275 239.8C397.2 239.59 396.19 237.653 394.579 237.357C393.44 237.148 391.195 237.764 389.979 238.143C389.445 238.309 389.02 238.705 388.795 239.217L387.238 242.754C387.238 242.754 387.931 244.25 390.129 243.306C390.129 243.306 386.491 247.537 386.994 248.943C387.496 250.348 388.686 250.794 388.686 250.794C388.686 250.794 386.905 252.461 387.924 253.76C388.943 255.061 390.273 254.348 390.273 254.348C390.273 254.348 390.489 256.726 392.873 255.636C395.257 254.547 398.528 251.951 398.528 251.951C398.528 251.951 399.226 253.239 397.867 253.867C396.506 254.496 395.727 255.646 395.727 255.646C395.727 255.646 396.006 257.527 397.596 256.879C399.187 256.231 400.763 255.985 402.007 254.59C403.251 253.194 404.581 252.481 403.913 251.056C403.245 249.631 402.935 249.425 402.935 249.425C402.935 249.425 403.355 247.214 402.266 246.416C401.176 245.616 401.176 245.616 401.176 245.616C401.176 245.616 401.813 243.408 400.543 242.515C399.273 241.621 397.672 240.689 397.672 240.689L397.275 239.8Z"
      fill="#B28B67"
    />
    <path
      d="M388.642 250.73C388.674 250.76 389.111 250.343 389.812 249.666C390.512 248.988 391.481 248.054 392.571 247.045C393.663 246.036 394.67 245.143 395.401 244.498C396.132 243.853 396.581 243.449 396.554 243.415C396.528 243.384 396.032 243.729 395.264 244.333C394.495 244.938 393.461 245.809 392.366 246.822C391.27 247.835 390.321 248.798 389.658 249.516C388.995 250.234 388.613 250.703 388.642 250.73Z"
      fill="#B77A23"
    />
    <path
      d="M389.944 254.472C389.999 254.535 391.883 252.983 394.152 251.005C396.422 249.027 398.217 247.372 398.162 247.309C398.107 247.246 396.223 248.798 393.953 250.776C391.684 252.753 389.889 254.408 389.944 254.472Z"
      fill="#B77A23"
    />
    <path
      d="M389.937 243.619C389.992 243.681 391.016 242.862 392.222 241.79C393.43 240.718 394.363 239.797 394.307 239.734C394.251 239.672 393.228 240.491 392.021 241.563C390.814 242.636 389.881 243.556 389.937 243.619Z"
      fill="#B77A23"
    />
    <path
      d="M403.652 299.436C403.652 299.436 403.022 295.698 400.776 295.867C398.529 296.037 398.259 296.207 396.821 295.442C395.384 294.678 394.485 292.553 390.71 292.468C386.934 292.383 383.16 294.083 381.003 293.233L373.093 299.436C373.093 299.436 380.374 305.724 382.71 306.659C385.047 307.594 390.62 307.848 391.519 307.509C392.417 307.169 398.529 305.724 399.158 305.384C399.787 305.045 405 302.665 405.09 301.9C405.18 301.136 404.155 300.453 403.419 300.628C402.683 300.803 398.812 301.813 397.001 300.847C395.19 299.882 394.666 299.767 394.666 299.767L403.652 299.436Z"
      fill="#B28B67"
    />
    <path
      d="M405.41 282.72C405.386 282.737 405.612 283.02 406.15 283.129C406.659 283.242 407.528 283.106 408.032 282.38C408.509 281.636 408.301 280.781 408.002 280.355C407.695 279.899 407.347 279.8 407.34 279.829C407.31 279.873 407.594 280.035 407.817 280.464C408.041 280.871 408.169 281.612 407.779 282.212C407.375 282.801 406.642 282.967 406.18 282.917C405.698 282.875 405.439 282.675 405.41 282.72Z"
      fill="#C68427"
    />
  </svg>
</template>

<script>
export default {
  name: "ThirdTrimesterBalance"
};
</script>
