<template>
  <section :class="$vuetify.breakpoint.xs ? 'mx-n4' : 'mx-n8'">
    <div class="outer-container" :style="{ backgroundColor: backgroundColor }">
      <div class="container py-0 px-4 px-md-8">
        <div class="blob-wrapper">
          <background-blob v-if="showBackgroundBlob" :color="blobColor" />
        </div>
        <div class="content-wrapper">
          <div class="content">
            <h1 class="title-text text-h1 vflTextDark--text mb-6 mb-md-8">
              {{ title }}
            </h1>
            <p
              class="description-text vflTextDark--text mb-4 mb-sm-8"
              v-html="description"
            />
            <confidentiality-callout
              class="mb-8 mb-sm-16"
              :color="calloutColor"
            />
            <div class="button-terms-container">
              <vfl-button
                class="mb-5 action-button"
                style="min-width: 180px"
                @click="onNext"
                size="medium"
                >{{ $t("menopauseAssessment.landingPage.next") }}</vfl-button
              >
              <p
                class="text-body-2 terms-text vflNeutralDark1--text"
                v-html="$t('welcomeToAssessment.termsOfService')"
              />
            </div>
          </div>

          <div class="image-container">
            <image-blob
              class="image-blob"
              v-if="showImageBlob"
              :color="blobColor"
            />
            <picture>
              <source media="(min-width: 800px)" :srcset="imageSrc" />
              <img
                :src="mobileImageSrc || desktopImageSrc"
                alt=""
                aria-hidden="true"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VflButton from "@/components/vitrue-foundation-library/VflButton.vue";
import ConfidentialityCallout from "@/components/assessment/common/ConfidentialityCallout.vue";
import BackgroundBlob from "./BackgroundBlob.vue";
import ImageBlob from "./ImageBlob.vue";

export default {
  name: "LandingPage",
  components: { VflButton, ConfidentialityCallout, BackgroundBlob, ImageBlob },
  props: {
    title: {
      type: String,
      required: true
    },
    description: { type: String, required: true },
    imageSrc: {
      type: String,
      required: true
    },
    mobileImageSrc: {
      type: String,
      required: false
    },
    showBackgroundBlob: {
      type: Boolean,
      default: true
    },
    showImageBlob: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: "#FFEDE8"
    },
    calloutColor: {
      type: String,
      default: "#FFDBD2"
    },
    blobColor: {
      type: String,
      default: "#FFBEAC"
    }
  },
  mounted() {
    document.body.classList.add("assessment-landing-page");
  },
  beforeDestroy() {
    document.body.classList.remove("assessment-landing-page");
  },
  methods: {
    onNext() {
      this.$emit("next");
    }
  }
};
</script>

<style lang="scss" scoped>
$layout-change: 800px;
$mobile-view: 425px;

section {
  --container-min-height: calc(100vh - var(--topbar-height));
}

.outer-container {
  min-height: var(--container-min-height);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: $layout-change) {
    padding-top: 1.5rem;
    align-items: start;
  }

  @media (max-width: $mobile-view) {
    padding-top: 1rem;
  }
}

.description-text {
  font-size: 1.25rem;

  @media (max-width: $layout-change) {
    font-size: 1rem;
  }
}

.container {
  max-width: 1320px;
  position: relative;
}

.content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  height: 100%;
  position: relative;
  align-items: center;

  @media (max-width: $layout-change) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  @media (max-width: $mobile-view) {
    gap: 1rem;
  }
}

.title-text {
  font-size: 3rem !important;
  font-weight: 600 !important;

  @media (max-width: $layout-change) {
    font-size: 2rem !important;
    font-weight: 500 !important;
  }

  @media (max-width: $mobile-view) {
    font-size: 1.5rem !important;
  }
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  order: 0;

  .image-blob {
    position: absolute;
    top: -20%;
  }

  picture {
    position: relative;
    z-index: 1;
    img {
      width: 100%;
    }
  }

  @media (max-width: $layout-change) {
    order: -1;
    justify-content: start;

    .image-blob {
      display: none;
    }

    picture {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.button-terms-container {
  @media (max-width: $mobile-view) {
    display: flex;
    flex-direction: column-reverse;

    .action-button {
      width: 100%;
    }

    .terms-text {
      color: var(--v-vflGrey-base) !important;
    }
  }
}

.blob-wrapper {
  position: absolute;
  right: -20%;
  bottom: -50%;
  width: 1000px;
  height: 1000px;
  overflow: hidden;

  @media (max-width: $layout-change) {
    display: none;
  }
}
</style>
