import store from "@/store";

export default {
  getAssessmentDataPointValuesForHealth(
    index,
    schema,
    key,
    value,
    answers,
    results
  ) {
    for (var i = 0; i < schema[index - 1].pages.length; i++) {
      let question = schema[index - 1].pages[i].questions.find(
        a => a.name == key
      );

      this.addAnswer(question, value, answers, key);
    }
  },
  getAssessmentDataPointValuesForPain(
    index,
    schema,
    painKey,
    key,
    value,
    answers
  ) {
    for (var i = 0; i < schema[index - 1].pages.length; i++) {
      let question = schema[index - 1].pages[i].questions[painKey].find(
        a => a.name == key
      );
      this.addAnswer(question, value, answers, key, painKey);
    }
  },
  getAssessmentDataPointValues(
    index,
    schema,
    pageKey,
    key,
    value,
    answers,
    group
  ) {
    let page = schema[index - 1].pages[pageKey];
    let question = page.questions.find(a => a.name == key);

    this.addAnswer(question, value, answers, key, group);
  },
  getAssessmentDataPointValuesFromPage(page, key, value, answers, group) {
    const question = page.questions?.find(q => q.name === key);

    this.addAnswer(question, value, answers, key, group);
  },
  getAssessmentDataPointValuesForPainFromPage(
    page,
    painKey,
    key,
    value,
    answers
  ) {
    const areaQuestions = page.questions[painKey];
    if (!areaQuestions) {
      throw new Error(`No questions found for pain area: ${painKey}`);
    }

    const question = areaQuestions.find(q => q.name === key);
    if (question) {
      this.addAnswer(question, value, answers, key, painKey);
    }
  },
  // For schema v2
  getAssessmentDataPointValuesForHealthFromPage(page, key, value, answers) {
    const question = page.questions.find(q => q.name === key);
    if (question) {
      this.addAnswer(question, value, answers, key);
    }
  },
  addDeskPlanObjectsToData(objects, answers, group) {
    if (objects.length === 0) {
      // If there are no objects, still push an item with no value
      // to ensure items are deleted if necessary
      answers.push({
        Name: "VisualDeskPlanner",
        Group: group,
        FieldType: "VisualDeskPlanner",
        Value: {}
      });
    }
    for (let i = 0; i < objects.length; i++) {
      answers.push({
        Name: "VisualDeskPlanner",
        Group: group,
        FieldType: "VisualDeskPlanner",
        Value: {
          ItemId: objects[i].id,
          Version: objects[i].version,
          Width: objects[i].width,
          Height: objects[i].height,
          X: objects[i].x,
          Y: objects[i].y,
          Z: objects[i].z,
          Rotation: objects[i].rotation,
          ZIndex: objects[i].zIndex,
          WebcamScreen: objects[i].elevationWebcam
        }
      });
    }
  },
  addDeskPlanImageUrlToData(image, answers, group) {
    if (!image) {
      return;
    }
    answers.push({
      Name: "VisualDeskPlannerImage",
      Group: group,
      FieldType: "VisualDeskPlannerImage",
      Value: image
    });
  },
  addAnswer(question, value, answers, key, group) {
    if (!question || value === undefined || value === null) {
      return;
    }

    const dataPoint = {
      Name: key,
      Group: group,
      FieldType: question.fieldType,
      Value: value
    };
    answers.push(dataPoint);
  },
  getGenericDataPointDTO(group, name, value) {
    return [
      {
        FieldType: "RadioButtonQuestion",
        Group: group,
        Name: name,
        Value: { IntValue: value, Score: null }
      }
    ];
  },
  getPainDataPointDTO(painAreas, painLevel) {
    let values = [];
    painAreas.forEach((element, index) => {
      if (painLevel[index] != null) {
        values.push({
          FieldType: "PainRatingQuestion",
          Group: element,
          Name: "level",
          Value: painLevel[index]
        });
      }
    });
    return values;
  }
};
