export const deskAssessmentSchemaStore = {
  namespaced: true,
  state: {
    isV2: false
  },
  mutations: {
    SET_SCHEMA_VERSION(state, isV2) {
      state.isV2 = isV2;
    }
  },
  actions: {
    setSchemaVersion({ commit }, isV2) {
      commit("SET_SCHEMA_VERSION", isV2);
    }
  },
  getters: {
    schemaVersion: state => state.isV2
  }
};
