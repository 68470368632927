<template>
  <div>
    <p v-if="showLabels" class="d-md-none font-weight-bold mb-2 text-small">
      <span class="mr-1">{{ `${currentStep}/${mainStepsCount}` }}</span>
      {{ stepsArray[currentMainStepIndex] }}
    </p>
    <ol class="pl-0 d-flex" :class="{ 'is-compact': compact }">
      <template v-for="(step, index) in mainSteps">
        <li
          :key="`${index}-step`"
          :class="[
            { 'is-complete': isStepComplete(index) },
            { 'is-current': isCurrentStep(index) },
            { 'is-partial': isPartialStep(index) },
            { 'is-mobile': $vuetify.breakpoint.smAndDown },
            sizeClass
          ]"
          class="d-flex flex-grow-1"
          :data-index="`${index + 1}/${mainStepsCount}`"
        >
          <span v-if="isStepComplete(index)" class="d-sr-only"
            >{{ $t("vfl.stepper.complete") }}{{ hasLabels ? `: ${step}` : "" }}
          </span>
          <span v-if="isCurrentStep(index)" class="d-sr-only"
            >{{ $t("vfl.stepper.current") }}{{ hasLabels ? `: ${step}` : "" }}
          </span>

          <span v-if="showLabels" class="label d-flex flex-column">{{
            step
          }}</span>
          <span v-else class="label d-flex flex-column"></span>
        </li>
      </template>
    </ol>
  </div>
</template>

<script>
export default {
  name: "VflStepper",
  props: {
    steps: {
      type: [Array, Number],
      required: true
    },
    currentStep: {
      type: [String, Number],
      default: 1
    },
    currentTheme: null,
    size: {
      type: String,
      default: "large",
      validator: value => ["small", "large"].includes(value)
    },
    compact: Boolean,
    stepStructure: {
      type: Array,
      default: () => []
    },
    displayLabels: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalCurrentStep: this.currentStep
    };
  },
  computed: {
    mainSteps() {
      if (this.stepStructure.length) {
        return this.stepStructure.map(step => step.mainStep);
      }
      return this.stepsArray;
    },
    mainStepsCount() {
      return this.mainSteps.length;
    },
    currentMainStepIndex() {
      return Math.max(0, this.currentStep - 1);
    },
    hasLabels() {
      return Array.isArray(this.steps);
    },
    showLabels() {
      return this.hasLabels && this.displayLabels;
    },
    sizeClass() {
      return this.size === "small" ? "is-small" : "is-large";
    },
    stepsArray() {
      if (Array.isArray(this.steps)) {
        return this.steps;
      }
      return Array.from({ length: this.steps }, (_, i) => `Step ${i + 1}`);
    }
  },
  methods: {
    isStepComplete(index) {
      return index < this.currentMainStepIndex;
    },
    isCurrentStep(index) {
      return index === this.currentMainStepIndex;
    },
    isPartialStep(index) {
      const step = this.stepStructure[index];
      if (!step?.hasSubstep) return false;

      const isMainTheme = this.currentTheme === step.mainStep;
      return this.isCurrentStep(index) && isMainTheme;
    }
  },
  watch: {
    currentStep(newVal) {
      this.internalCurrentStep = newVal;
    },
    internalCurrentStep(newVal) {
      if (newVal !== this.currentStep) {
        this.$emit("update:currentStep", newVal);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
ol {
  gap: 0.75rem;

  @media screen and (min-width: 768px) {
    gap: 1rem;

    &.is-compact {
      gap: 0.5rem;
      width: 50%;
    }
  }
}

li {
  flex-basis: 0;
  list-style-type: none;

  &.is-complete {
    .label:before {
      background: var(--v-vflPrimary-base);
    }
  }

  &.is-current {
    .label:before {
      background: var(--v-vflPrimary-base);
    }

    &.is-partial .label:before {
      background: linear-gradient(
        to right,
        var(--v-vflPrimary-base) 50%,
        var(--v-vflGreyVeryLight-base) 50%
      );
    }
  }

  &.is-mobile {
    .label {
      font-size: 0;
    }
  }

  &.is-small {
    .label:before {
      height: 4px;
    }
  }

  &.is-large {
    .label:before {
      height: 8px;
    }
  }

  .label {
    gap: 0.25rem;
    flex: 1;
    font-size: 0.625rem;

    &:before {
      background: var(--v-vflGreyVeryLight-base);
      border-radius: 9999px;
      content: "";
    }
  }
}
</style>
