<template>
  <section>
    <end-of-report-sign-up
      :assessmentType="assessmentType"
      :assessmentId="assessmentId"
    />
    <short-feedback
      v-model="quickFeedbackSubmitted"
      v-show="displayResultsFeedback"
      :assessmentId="assessmentId"
      urlSuffix="report"
      bottom-offset="5rem"
      trigger-on-scroll
    />
    <template v-for="section in visibleSections">
      <component
        :key="section.id"
        :is="section.component"
        v-bind="section.props"
        :results="results"
        :assessmentId="assessmentId"
        :sectionNumber="section.sectionNumber"
        :backgroundColor="section.backgroundColor"
      />
    </template>

    <report-footer
      :assessmentId="assessmentId"
      :assessmentType="assessmentType"
      :reportContext="reportContext"
    />
  </section>
</template>

<script>
import { assessmentTypes } from "@/constants/constants.js";
import ReportFooter from "@/components/common/report/ReportFooter.vue";
import EndOfReportSignUp from "@/components/authentication/EndOfReportSignUp.vue";
import ShortFeedback from "@/components/common/Feedback/ShortFeedback.vue";
import { routes } from "@/router/route-constants.js";
import PainAssessmentService from "@/services/assessment/pain/pain-assessment-service.js";

import { REPORT_SECTION_CONFIG } from "@/components/assessment/pregnancy/report/constants/index.js";

import PregnancyReportHeader from "./PregnancyReportHeader.vue";
import PregnancyReportIntroduction from "./PregnancyReportIntroduction.vue";
import PregnancyReportPain from "./PregnancyReportPain.vue";
import PregnancyReportNoPain from "./PregnancyReportNoPain.vue";
import PregnancyReportBalance from "./PregnancyReportBalance.vue";
import PregnancyReportCirculation from "./PregnancyReportCirculation.vue";
import PregnancyReportPostnatal from "./PregnancyReportPostnatal.vue";

const BACKGROUND_COLORS = ["#F7F7F7", "#FFFFFF"];

export default {
  name: "MenstruationAssessmentReport",
  components: {
    ReportFooter,
    EndOfReportSignUp,
    ShortFeedback,
    PregnancyReportHeader,
    PregnancyReportIntroduction,
    PregnancyReportPain,
    PregnancyReportNoPain,
    PregnancyReportBalance,
    PregnancyReportPostnatal,
    PregnancyReportCirculation
  },
  data() {
    return {
      quickFeedbackSubmitted: false
    };
  },
  props: {
    results: Object,
    assessmentId: String,
    reportContext: {
      type: String,
      required: true,
      validator: value => ["assessment", "archive"].includes(value)
    }
  },
  computed: {
    assessmentType() {
      return assessmentTypes.pregnancyAssessment;
    },
    visibleSections() {
      let sectionNumber = 0;
      return REPORT_SECTION_CONFIG.filter(section =>
        section.isVisible(this)
      ).map(section => {
        const config = {
          ...section,
          sectionNumber,
          backgroundColor:
            section.id === "header"
              ? null
              : BACKGROUND_COLORS[(sectionNumber - 1) % 2],
          props:
            section.id === "pain" ? { assessmentId: this.assessmentId } : {}
        };
        if (!section.skipSectionNumber) {
          sectionNumber++;
        }
        return config;
      });
    },
    displayResultsFeedback() {
      return this.$route.name === routes.PREGNANCY_ASSESSMENT;
    },
    hasPain() {
      return PainAssessmentService.hasPain(this.results);
    }
  }
};
</script>

<style lang="scss" scoped>
.iframe-container {
  grid-column: 1/-1;
  min-height: calc(100vh - var(--topbar-height));
  position: relative;
  margin-left: -32px;
  margin-right: -32px;
  margin-top: -16px;
  margin-bottom: -64px;

  iframe {
    border: none;
    display: block;
    height: 100%;
    width: 100%;
  }
}

section {
  margin-left: -48px;
  margin-right: -48px;
  margin-top: -16px;

  @media (max-width: 1000px) {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.patient-report-dialog-open section {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
</style>
