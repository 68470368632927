<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{
      $t("pregnancyAssessment.report.content.pain.title")
    }}</template>
    <template #content>
      <div class="content-wrapper">
        <div class="vfl-card-light py-4 px-8 pb-12">
          <h4 class="text-h6 text-center mb-8 text-body-darkest">
            {{ $t("pregnancyAssessment.report.content.pain.painAreaTitle") }}
          </h4>
          <pain-person
            v-model="results.pain"
            disabled
            :painPersonMaxWidth="280"
          />
        </div>

        <report-info-card
          v-if="showBackCard"
          class="backCard report-info-card"
          :title="$t('pregnancyAssessment.report.content.pain.back.title')"
          illustration="/images/pregnancy-assessment/report/back-pain.svg"
          :text="$t('pregnancyAssessment.report.content.pain.back.text')"
          :assessmentType="$options.assessmentTypes.pregnancyAssessment"
        />
        <report-info-card
          v-if="showHipCard"
          class="hipsCard report-info-card"
          :title="$t('pregnancyAssessment.report.content.pain.hip.title')"
          illustration="/images/pregnancy-assessment/report/hip-pain.svg"
          :text="$t('pregnancyAssessment.report.content.pain.hip.text')"
          :assessmentType="$options.assessmentTypes.pregnancyAssessment"
        />
        <report-info-card
          v-if="showAnyPainCard"
          class="anyPainCard report-info-card"
          :title="
            $t(
              'pregnancyAssessment.report.content.pain.painDuringPregnancy.title'
            )
          "
          illustration="/images/pregnancy-assessment/report/any-pain.svg"
          :text="
            $t(
              'pregnancyAssessment.report.content.pain.painDuringPregnancy.text'
            )
          "
          :assessmentType="$options.assessmentTypes.pregnancyAssessment"
        />
      </div>
      <vfl-callout
        v-if="painStartedDuringPregnancy"
        class="mt-6"
        icon="Lightbulb"
        :icon-width="25"
        backgroundColor="vflNeutralLight2"
        >{{ $t("pregnancyAssessment.report.content.pain.callout") }}
      </vfl-callout>
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import ReportInfoCard from "@/components/assessment/common/report/ReportInfoCard.vue";
import PainPerson from "@/components/painareas/PainPerson.vue";
import { assessmentTypes } from "@/constants/constants.js";
import PainAssessmentService from "@/services/assessment/pain/pain-assessment-service.js";
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";

export default {
  name: "MenopauseReportPain",
  components: {
    PainPerson,
    ReportSection,
    ReportInfoCard,
    VflCallout
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  computed: {
    showBackCard() {
      return PainAssessmentService.hasPainArea(this.results, "lowerback");
    },
    showHipCard() {
      return PainAssessmentService.hasSomePainAreas(this.results, [
        "lefthip",
        "righthip"
      ]);
    },
    showAnyPainCard() {
      return !this.showBackCard || !this.showHipCard;
    },
    painStartedDuringPregnancy() {
      return Object.values(this.results.pain?.areas).some(
        area => area.experiencePainBeforePregnancy === 2
      );
    }
  }
};
</script>

<style scoped lang="scss">
.content-wrapper {
  $breakpoint-large: 1400px;
  $breakpoint-medium: 1000px;

  display: grid;
  grid-gap: 1rem;

  grid-template-columns: minmax(300px, 430px) 1fr;

  @media screen and (max-width: $breakpoint-medium) {
    grid-template-columns: 1fr;
  }

  // Target when exactly one info card is visible
  .report-info-card:only-child {
    grid-template-columns: 1fr;
  }

  // Target when exactly two info cards are visible
  &:has(.report-info-card:nth-last-of-type(2)) {
    grid-template-columns: minmax(300px, 430px) 1fr 1fr;

    & > .vfl-card-light {
      grid-column: 1;
    }

    & > .report-info-card {
      grid-column: span 1;
    }

    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: 1fr;

      & > .vfl-card-light,
      & > .report-info-card {
        grid-column: 1;
      }
    }
  }
}

.vfl-card-light {
  background: white;
}
</style>
