import { cloneDeep } from "lodash";
import QuestionSchema from "@/components/assessment/desk/question-schema.json";
import { StepId, Theme } from "@/components/assessment/desk/steps.js";
import { withErrorHandling } from "@/components/assessment/desk/results-helpers/error-handling.js";
import { questionsByTheme } from "@/components/assessment/desk/results-helpers/questions-by-theme.js";

export default class BaseScoreHelper {
  static get questionsByTheme() {
    return questionsByTheme;
  }

  static getSchemaPages = withErrorHandling(function (schema) {
    return Object.entries(schema).reduce((acc, [theme, data]) => {
      data.pages.forEach(page => acc.push({ theme, page }));
      return acc;
    }, []);
  }, []);

  static getCurrentAssessmentIndex = withErrorHandling(function (
    originalResults
  ) {
    const results = cloneDeep(originalResults);
    const pages = this.getSchemaPages(QuestionSchema);

    if (results.completedAt) {
      return pages.filter(x => x.theme !== Theme.REPORT).length;
    }

    const pageStatuses = pages.map(p => ({
      theme: p.theme,
      page: p.page,
      completed: false
    }));

    for (const section of pageStatuses) {
      const answers = results[section.theme];
      const questionIds = this.getSchemaSectionQuestionIds(section);
      const answerIds = this.getAnswerIds(section, answers);
      section.completed = this.schemaSectionIsComplete(
        section,
        questionIds,
        answerIds,
        answers
      );
    }

    const lastAnsweredSection = pageStatuses.filter(x => x.completed).pop();
    if (!lastAnsweredSection) return 0;

    const lastAnsweredIndex = pageStatuses.indexOf(lastAnsweredSection);
    return lastAnsweredSection.page.name === StepId.SIMS
      ? lastAnsweredIndex
      : lastAnsweredIndex + 1;
  },
  0);

  static getAnswerIds = withErrorHandling(function (section, answers) {
    if (section.theme === Theme.PAIN) {
      const painAnswers = Object.values(answers?.areas || {});
      answers = painAnswers.length > 0 ? Object.assign({}, ...painAnswers) : {};
    }
    return [...new Set(Object.keys(answers || {}))];
  }, []);

  static getSchemaSectionQuestionIds = withErrorHandling(function (section) {
    let questions = section.page.questions ?? [];

    if (section.theme === Theme.PAIN) {
      questions = Object.values(questions).flat();
    }

    const mandatoryQuestions = questions.filter(x => !x.showOn);
    return [...new Set(mandatoryQuestions.map(a => a.name))];
  }, []);

  static schemaSectionIsComplete = withErrorHandling(function (
    section,
    questionIds,
    answerIds,
    answers
  ) {
    if (section.theme === Theme.WEBCAM) {
      return Object.values(answers || {}).some(a => a !== null);
    }

    if (section.page.name === StepId.SIMS) {
      return (answers?.deskItems?.length || 0) > 0;
    }

    return (
      answerIds.length > 0 &&
      questionIds.length > 0 &&
      questionIds.every(a => answerIds.includes(a))
    );
  },
  false);

  static getPercentageScore = withErrorHandling(function (
    scoreValue,
    totalPossibleScore
  ) {
    if (scoreValue === 0) return 100;
    const percentage = (1 - scoreValue / totalPossibleScore) * 100;
    return Math.round(percentage);
  },
  0);
}
