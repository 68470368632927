import BaseScoreHelper from "./base-score-helper";
import SchemaService from "@/services/question-schema/schema-service.js";
import { withErrorHandling } from "@/components/assessment/desk/results-helpers/error-handling.js";
import PainHelper from "@/components/assessment/desk/results-helpers/pain-helper.js";

export default class WorkHabitsScoreHelper extends BaseScoreHelper {
  static maxWorkHabitsScore = 4;

  static calculateWorkHabitsScore(results) {
    const workHabitsQuestions = { ...this.questionsByTheme.setup.workhabits };

    // Exclude environmental factors - these are handled separately
    delete workHabitsQuestions.noise;
    delete workHabitsQuestions.temperature;

    return WorkHabitsScoreHelper.getWorkHabitsScoreFromResult(
      workHabitsQuestions,
      results.setup
    );
  }

  static getWorkHabitsScoreFromResult(workHabitsQuestions, setupResults) {
    let totalScore = 0;

    for (const [key, question] of Object.entries(workHabitsQuestions)) {
      const result = setupResults?.[key];

      if (key === "hours") {
        totalScore += this.getSetupHoursScore(result);
        continue;
      }

      if (result != null && question.scores) {
        totalScore += SchemaService.getScoreFromResult(question, result);
      }
    }

    return totalScore;
  }

  static getSetupHoursScore(result) {
    if (!result || result <= 7) return 0;
    if (result === 8) return 1;
    return 2;
  }

  static getWorkHabitsPercentage(results) {
    return this.getPercentageScore(
      WorkHabitsScoreHelper.calculateWorkHabitsScore(results),
      WorkHabitsScoreHelper.maxWorkHabitsScore
    );
  }

  static getWorkHabitsWeight() {
    return 0.2;
  }
}

WorkHabitsScoreHelper.calculateWorkHabitsScore = withErrorHandling(
  WorkHabitsScoreHelper.calculateWorkHabitsScore,
  0
);
WorkHabitsScoreHelper.getWorkHabitsScoreFromResult = withErrorHandling(
  WorkHabitsScoreHelper.getWorkHabitsScoreFromResult,
  0
);
