<template>
  <div class="pregnancy-report-header pa-8">
    <img
      class="background-image"
      src="/images/illustrations/pregnant-woman-sitting-with-plant.svg"
    />
    <div class="content">
      <div class="content-inner">
        <h1
          class="text-overline vflNeutralDark1--text mb-1"
          style="font-size: 1rem !important"
        >
          {{ $t("pregnancyAssessment.report.content.header.title") }}
        </h1>
        <h3
          class="text-h2 text-body-darkest"
          style="line-height: 1.2 !important"
          :class="$vuetify.breakpoint.mdAndUp ? 'text-h2' : 'text-h4'"
        >
          {{ $t("pregnancyAssessment.report.content.header.content") }}
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PregnancyReportHeader",
  props: {
    results: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.pregnancy-report-header {
  align-items: center;
  background-color: #fff3df;
  display: flex;
  min-height: 400px;
  overflow: hidden;
  position: relative;

  .background-image {
    bottom: 0;
    position: absolute;
    right: 5%;

    @media screen and (max-width: 1225px) {
      opacity: 0.4;
    }

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .content {
    flex-grow: 1;
    margin: -2rem auto 0;
    max-width: 1320px;

    &-inner {
      max-width: 75ch;
      position: relative;
    }
  }
}
</style>
