import Answers from "@/components/assessment/pregnancy/answers.json";
import i18n from "@/plugins/i18n";
import sentry from "@/sentry.js";

const SCORE_RANGES = {
  high: { min: 8, max: 12 },
  medium: { min: 3, max: 7 },
  low: { min: 0, max: 2 }
};

const SCORING_MAP = {
  steadyWhilstWalking: {
    [Answers.balance.steadyWhilstWalking.always]: 0,
    [Answers.balance.steadyWhilstWalking.sometimes]: 2,
    [Answers.balance.steadyWhilstWalking.oftenUnstable]: 3
  },
  differenceUsingStairs: {
    [Answers.balance.differenceUsingStairs.noChange]: 0,
    [Answers.balance.differenceUsingStairs.moreCautious]: 2,
    [Answers.balance.differenceUsingStairs.needSupport]: 3
  },
  anyFallsDuringPregnancy: {
    [Answers.balance.anyFallsDuringPregnancy.no]: 0,
    [Answers.balance.anyFallsDuringPregnancy.yes]: 3
  },
  physicalActivity: {
    [Answers.lifestyle.physicalActivity.never]: 3,
    [Answers.lifestyle.physicalActivity.once]: 2,
    [Answers.lifestyle.physicalActivity.twice]: 1,
    [Answers.lifestyle.physicalActivity.threeOrMore]: 0
  }
};

export class PregnancyReportBalanceService {
  constructor(results) {
    this.results = results;
  }

  getBalanceScore() {
    try {
      const balanceResults = this.results?.balance || {};
      const lifestyleResults = this.results?.lifestyle || {};

      if (
        Object.keys(balanceResults).length === 0 &&
        Object.keys(lifestyleResults).length === 0
      ) {
        return 0;
      }

      // Get score for each answer if it exists
      const balanceScores = {
        steadyWhilstWalking:
          SCORING_MAP.steadyWhilstWalking[balanceResults.steadyWhilstWalking] ||
          0,
        differenceUsingStairs:
          SCORING_MAP.differenceUsingStairs[
            balanceResults.differenceUsingStairs
          ] || 0,
        anyFallsDuringPregnancy:
          SCORING_MAP.anyFallsDuringPregnancy[
            balanceResults.anyFallsDuringPregnancy
          ] || 0,
        physicalActivity:
          SCORING_MAP.physicalActivity[lifestyleResults.physicalActivity] || 0
      };

      const totalScore = Object.values(balanceScores).reduce(
        (sum, score) => sum + score,
        0
      );
      return totalScore;
    } catch (error) {
      sentry.captureException(error);
      return 0;
    }
  }

  getRiskLevel() {
    const score = this.getBalanceScore();
    return (
      Object.entries(SCORE_RANGES).find(
        ([_, range]) => score >= range.min && score <= range.max
      )?.[0] || "low"
    );
  }

  getText() {
    const riskLevel = this.getRiskLevel();

    const balanceFeelingMapping = {
      [Answers.balance.steadyWhilstWalking.always]: "steady",
      [Answers.balance.steadyWhilstWalking.sometimes]: "unsure",
      [Answers.balance.steadyWhilstWalking.oftenUnstable]: "wobbly"
    };

    const nearFallsLastMonthMapping = {
      [Answers.balance.nearFallsLastMonth.never]: "havent",
      [Answers.balance.nearFallsLastMonth.onceOrTwice]: "have",
      [Answers.balance.nearFallsLastMonth.moreThanTwice]: "have"
    };
    const balanceFeelingText = i18n.t(
      `pregnancyAssessment.report.content.balance.answerSpecifics.balanceFeeling.${
        balanceFeelingMapping[this.results.balance?.steadyWhilstWalking]
      }`
    );

    const experiencedIssuesText = i18n.t(
      `pregnancyAssessment.report.content.balance.answerSpecifics.hasExperiencedIssues.${
        nearFallsLastMonthMapping[this.results.balance?.nearFallsLastMonth]
      }`
    );

    const translatedStrings = i18n.t(
      `pregnancyAssessment.report.content.balance.risk.${riskLevel}`,
      {
        balanceFeeling: balanceFeelingText,
        experiencedIssues: experiencedIssuesText
      },
      { returnObjects: true }
    );

    return translatedStrings.map(text => {
      return text
        .replace(/\{balanceFeeling\}/g, balanceFeelingText)
        .replace(/\{experiencedIssues\}/g, experiencedIssuesText);
    });
  }

  getIllustrationPath() {
    const riskLevel = this.getRiskLevel();
    return `/images/pregnancy-assessment/report/balance-${riskLevel}-risk.svg`;
  }
}
