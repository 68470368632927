import i18n from "@/plugins/i18n.js";
import Answers from "@/components/assessment/pregnancy/answers.json";
import BodyAreas from "@/components/painareas/body-areas.json";

export class OutroService {
  getTrimesterContent({ pregnancyTrimester, pregnancyCurrentFeeling }) {
    const trimesterKey = {
      [Answers.trimester.pregnancyTrimester.first]: "first",
      [Answers.trimester.pregnancyTrimester.second]: "second",
      [Answers.trimester.pregnancyTrimester.third]: "third"
    }[pregnancyTrimester];

    const currentFeelingKey = {
      [Answers.trimester.pregnancyCurrentFeeling.okay]: "okay",
      [Answers.trimester.pregnancyCurrentFeeling.good]: "good",
      [Answers.trimester.pregnancyCurrentFeeling.struggling]: "struggling"
    }[pregnancyCurrentFeeling];

    const imageName = {
      [Answers.trimester.pregnancyTrimester.first]: "first_trimester",
      [Answers.trimester.pregnancyTrimester.second]: "second_trimester",
      [Answers.trimester.pregnancyTrimester.third]: "third_trimester"
    }[pregnancyTrimester];

    return {
      title: `pregnancyAssessment.outroPage.trimester.heading`,
      paragraphs: [
        ...this.getTranslationArray(
          `pregnancyAssessment.outroPage.trimester.pregnancyTrimester.${trimesterKey}`
        ),
        `pregnancyAssessment.outroPage.trimester.pregnancyCurrentFeeling.${currentFeelingKey}`
      ].filter(Boolean),
      imagePath: `/images/pregnancy-assessment/outro-images/${imageName}.svg`
    };
  }

  getPainContent(results) {
    const painAreas = Object.entries(results.pain?.areas || {})
      .filter(([_, area]) => area.level > 0)
      .map(([areaName, _]) => areaName);

    // if no pain return default no pain text
    if (painAreas.length === 0) {
      return {
        title: `pregnancyAssessment.outroPage.pain.noPain.heading`,
        paragraphs: [
          ...this.getTranslationArray(
            `pregnancyAssessment.outroPage.pain.noPain.text`
          )
        ],
        imagePath: `/images/pregnancy-assessment/outro-images/pain.svg`
      };
    }

    const translatedAreas = Object.keys(results.pain?.areas).map(area => {
      return i18n.t(BodyAreas[area].shortenedName).toLowerCase();
    });

    const formatAreasList = areas => {
      const connector = i18n.t("form.generator.connector");

      if (areas.length === 0) return "";
      if (areas.length === 1) return areas[0];
      if (areas.length === 2) return `${areas[0]} ${connector} ${areas[1]}`;
      return `${areas.slice(0, -1).join(", ")}, ${connector} ${
        areas[areas.length - 1]
      }`;
    };

    const formattedAreas = formatAreasList(translatedAreas);

    const paragraphs = [
      // Pain since pregnancy text
      Object.values(results.pain?.areas).some(
        area => area.experiencePainBeforePregnancy === 2
      ) && `pregnancyAssessment.outroPage.pain.hasPain.painAfterPregnancy`,

      // Pain before pregnancy text
      Object.values(results.pain?.areas).some(
        area => area.experiencePainBeforePregnancy === 1
      ) && `pregnancyAssessment.outroPage.pain.hasPain.painBeforePregnancy`,

      // Always include closing paragraph
      `pregnancyAssessment.outroPage.pain.hasPain.closingParagraph`
    ].filter(Boolean);

    return {
      title: `pregnancyAssessment.outroPage.pain.hasPain.heading.${
        painAreas.length > 3 ? "moreThanThreeAreas" : "regular"
      }`,
      titleParams: { painAreas: formattedAreas },
      paragraphs,
      imagePath: `/images/pregnancy-assessment/outro-images/pain.svg`
    };
  }

  getBalanceContent({ anyFallsDuringPregnancy }) {
    const fallsKey = {
      [Answers.balance.anyFallsDuringPregnancy.yes]: "hadFalls",
      [Answers.balance.anyFallsDuringPregnancy.no]: "noFalls"
    }[anyFallsDuringPregnancy];

    return {
      title: `pregnancyAssessment.outroPage.balance.heading`,
      paragraphs: [
        ...this.getTranslationArray(
          `pregnancyAssessment.outroPage.balance.falls.${fallsKey}`
        )
      ].filter(Boolean),
      imagePath: `/images/pregnancy-assessment/outro-images/balance.svg`
    };
  }

  getCirculationContent() {
    return {
      title: `pregnancyAssessment.outroPage.circulation.heading`,
      paragraphs: [
        ...this.getTranslationArray(
          `pregnancyAssessment.outroPage.circulation.text`
        )
      ].filter(Boolean),
      imagePath: `/images/pregnancy-assessment/outro-images/circulation.svg`
    };
  }

  getTranslationArray(key) {
    return Array.isArray(i18n.t(key)) ? i18n.t(key) : [i18n.t(key)];
  }
}
