<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{
      $t("pregnancyAssessment.report.content.noPain.title")
    }}</template>
    <template #content>
      <div class="content-wrapper">
        <div class="vfl-card-light pa-12 pb-8 d-flex justify-center">
          <first-trimester-no-pain-illustration
            v-if="pregnancyService.isFirstTrimester()"
          />
          <post-first-trimester-no-pain-illustration v-else />
        </div>

        <report-info-card
          class="report-info-card"
          :title="pregnancyService.getTitle()"
          :illustration="pregnancyService.getIllustrationPath()"
          :text="pregnancyService.getText()"
          :assessmentType="$options.assessmentTypes.pregnancyAssessment"
        />
      </div>
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import ReportInfoCard from "@/components/assessment/common/report/ReportInfoCard.vue";
import { assessmentTypes } from "@/constants/constants.js";
import FirstTrimesterNoPainIllustration from "./NoPainIllustrations/FirstTrimesterNoPainIllustration.vue";
import PostFirstTrimesterNoPainIllustration from "./NoPainIllustrations/PostFirstTrimesterNoPainIllustration.vue";
import { PregnancyReportNoPainService } from "@/components/assessment/pregnancy/report/pregnancy-report-no-pain-service.js";

export default {
  name: "PregnancyReportNoPain",
  components: {
    FirstTrimesterNoPainIllustration,
    PostFirstTrimesterNoPainIllustration,
    ReportSection,
    ReportInfoCard
  },
  assessmentTypes: assessmentTypes,
  data() {
    return {
      pregnancyService: null
    };
  },
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  created() {
    this.pregnancyService = new PregnancyReportNoPainService(this.results);
  }
};
</script>

<style scoped lang="scss">
.content-wrapper {
  $breakpoint-large: 1400px;
  $breakpoint-medium: 1000px;

  display: grid;
  grid-gap: 1rem;

  grid-template-columns: minmax(300px, 430px) 1fr;

  @media screen and (max-width: $breakpoint-medium) {
    grid-template-columns: 1fr;
  }
}

.vfl-card-light {
  background: white;
}
</style>
