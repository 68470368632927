<template>
  <v-card class="elevation-0 pa-4 confirm-code-container">
    <v-row justify="center" no-gutters :class="reportPageSignUp ? 'pa-5' : ''">
      <v-col cols="12" class="text-center mb-6 text-h4" v-if="!passwordReset">
        {{ $t("authentication.confirmCode.title") }}
      </v-col>
      <v-col
        cols="10"
        class="text-body-1 font-weight-medium vitrueGrey--text text-center"
        v-if="!passwordReset"
      >
        {{ $t("authentication.confirmCode.information") }}
      </v-col>
      <v-col :cols="columnWidth">
        <v-form ref="codeForm" v-model="codeValid" @submit.prevent>
          <v-text-field
            v-model="code"
            :label="$t('authentication.confirmCode.labels.code')"
            class="mt-10"
            outlined
            prepend-inner-icon="mdi-ticket-confirmation"
            :rules="[rules.codeRequired]"
            :success-messages="
              userConfirmed ? $t('authentication.confirmCode.confirmed') : null
            "
            :error-messages="
              !userConfirmed && !!code && !passwordReset
                ? $t('authentication.confirmCode.invalid')
                : null
            "
            @input="confirmUser()"
            hide-details="auto"
          ></v-text-field>
        </v-form>
        <v-hover v-slot="{ hover }">
          <v-btn
            text
            color="primary"
            class="text-none text-body-1 my-3"
            :class="hover ? 'text-decoration-underline' : ''"
            width="100%"
            @click="resendConfirmationCode()"
            :loading="resendingEmail"
            small
          >
            {{ $t("authentication.confirmCode.resendCode") }}
          </v-btn>
        </v-hover>
        <vfl-alert
          v-if="errorMessage"
          :message="errorMessage"
          type="error"
          isLight
        />
      </v-col>
      <v-col :cols="columnWidth" v-if="passwordReset">
        <v-form ref="newPasswordForm" v-model="passwordValid" @submit.prevent>
          <v-text-field
            v-model="newPassword"
            autocomplete="new-password"
            type="password"
            :label="$t('authentication.confirmCode.labels.newPassword')"
            outlined
            prepend-inner-icon="mdi-lock"
            :rules="[
              rules.passwordRequired,
              rules.passwordAtLeast8Characters,
              rules.passwordContainsNumber,
              rules.passwordContainsSpecialCharacter,
              rules.passwordContainsBothCases
            ]"
            @keydown.enter="resetPassword"
          ></v-text-field>
        </v-form>
        <v-btn
          width="100%"
          color="primary"
          @click="resetPassword()"
          :loading="resettingPassword"
          rounded
        >
          {{ $t("buttons.confirm") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapGetters } from "vuex";
import { EventBus } from "@/services/events/event-bus";
import Rules from "@/utils/stringValidation";

export default {
  name: "ConfirmCode",
  data() {
    return {
      code: "",
      userConfirmed: false,
      errorMessage: "",
      showErrorMessage: false,
      resendingEmail: false,
      resettingPassword: false,
      newPassword: "",
      passwordValid: false,
      codeValid: false,
      rules: Rules
    };
  },
  props: {
    email: String,
    password: String,
    signUp: Boolean,
    reportPageSignUp: Boolean,
    passwordReset: Boolean,
    assessmentId: String,
    userState: String
  },
  computed: {
    ...mapGetters(["teamId"]),
    columnWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return 12;
      }
      return this.signUp ? 8 : 10;
    },
    trimmedCode() {
      return this.code.replace(/\s/g, "");
    }
  },
  methods: {
    async resendConfirmationCode() {
      this.errorMessage = "";
      try {
        this.resendingEmail = true;
        await Auth.resendSignUp(this.email);
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.resendingEmail = false;
      }
    },
    async confirmUser() {
      if (this.passwordReset) {
        return;
      }
      try {
        this.$gtag.event("Sign up dialog - confirm sign up", {
          event_category: this.reportPageSignUp
            ? "Desk Assessment - Report"
            : "Main SignUp"
        });
        await Auth.confirmSignUp(this.email, this.trimmedCode, {
          // Optional. Force user confirmation irrespective of existing alias. By default set to True.
          forceAliasCreation: true
        });
        this.userConfirmed = true;
        if (this.reportPageSignUp) {
          this.$emit("confirmed");
        }
        EventBus.$emit("signInWithEmail");
      } catch (err) {
        this.userConfirmed = false;
      }
    },
    async resetPassword() {
      this.errorMessage = "";
      this.$refs.codeForm.validate();
      this.$refs.newPasswordForm.validate();
      if (!this.codeValid || !this.passwordValid) {
        return;
      }
      this.resettingPassword = true;
      try {
        if (this.userState === "UNCONFIRMED") {
          await Auth.confirmSignUp(this.email, this.trimmedCode);
        } else {
          await Auth.forgotPasswordSubmit(
            this.email,
            this.trimmedCode,
            this.newPassword
          );
        }
        this.$router.push({
          name: "Login",
          params: { successMsg: this.$t("authentication.confirmCode.success") }
        });
      } catch (error) {
        this.errorMessage = error.message;
        this.$logger.captureException(error);
      } finally {
        this.resettingPassword = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.confirm-code-container {
  border-radius: 12px;
}
</style>
