<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.56">
      <rect
        x="317"
        y="258"
        width="251"
        height="138"
        rx="29"
        stroke="#E08670"
        stroke-width="6"
      />
      <rect
        x="251"
        y="153"
        width="382"
        height="298"
        rx="27"
        stroke="#E08670"
        stroke-width="10"
      />
      <rect x="439" y="158" width="6" height="97" fill="#E08670" />
      <path
        d="M301 152.485L334.75 133L334.75 171.971L301 152.485Z"
        fill="#E08670"
      />
      <path
        d="M346 152.485L379.75 133L379.75 171.971L346 152.485Z"
        fill="#E08670"
      />
      <path
        d="M569.75 451.485L536 432L536 470.971L569.75 451.485Z"
        fill="#E08670"
      />
      <path
        d="M524.75 451.485L491 432L491 470.971L524.75 451.485Z"
        fill="#E08670"
      />
      <path
        d="M251.485 368.75L232 335L270.971 335L251.485 368.75Z"
        fill="#E08670"
      />
      <path
        d="M251.485 323.75L232 290L270.971 290L251.485 323.75Z"
        fill="#E08670"
      />
      <path
        d="M633.486 201L652.971 234.75L614 234.75L633.486 201Z"
        fill="#E08670"
      />
      <path
        d="M633.486 246L652.971 279.75L614 279.75L633.486 246Z"
        fill="#E08670"
      />
      <path
        d="M317.877 286L329.754 306.571L306 306.571L317.877 286Z"
        fill="#E08670"
      />
      <path
        d="M317.877 313.428L329.754 333.999L306 333.999L317.877 313.428Z"
        fill="#E08670"
      />
      <path
        d="M441.877 187L453.754 207.571L430 207.571L441.877 187Z"
        fill="#E08670"
      />
      <path
        d="M441.877 214.428L453.754 234.999L430 234.999L441.877 214.428Z"
        fill="#E08670"
      />
      <path
        d="M568.877 369L580.754 348.429L557 348.429L568.877 369Z"
        fill="#E08670"
      />
      <path
        d="M568.877 341.572L580.754 321.001L557 321.001L568.877 341.572Z"
        fill="#E08670"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "CirculationBase"
};
</script>
