export const layoutStore = {
  namespaced: true,
  state: {
    pageTitle: "",
    showSidebar: false,
    showTopbar: false,
    isAuthPageHeader: false,
    isFullscreen: false
  },
  mutations: {
    SET_PAGE_TITLE(state, title) {
      state.pageTitle = title;
    },
    SET_SHOW_SIDEBAR(state, showSidebar) {
      state.showSidebar = showSidebar;
    },
    SET_SHOW_TOPBAR(state, showTopbar) {
      state.showTopbar = showTopbar;
    },
    SET_AUTH_PAGE_HEADER(state, isAuthPageHeader) {
      state.isAuthPageHeader = isAuthPageHeader;
    },
    SET_FULLSCREEN(state, isFullscreen) {
      state.isFullscreen = isFullscreen;
    }
  },
  actions: {
    setPageTitle({ commit }, title) {
      commit("SET_PAGE_TITLE", title);
    },
    setShowSidebar({ commit }, showSidebar) {
      commit("SET_SHOW_SIDEBAR", showSidebar);
    },
    setShowTopbar({ commit }, showTopbar) {
      commit("SET_SHOW_TOPBAR", showTopbar);
    },
    setAuthPageHeader({ commit }, isAuthPageHeader) {
      commit("SET_AUTH_PAGE_HEADER", isAuthPageHeader);
    },
    setFullscreen({ commit }, isFullscreen) {
      commit("SET_FULLSCREEN", isFullscreen);
    },
    configureLayoutForAssessmentReport({ dispatch }, { condition, pageTitle }) {
      dispatch("setShowSidebar", condition);
      dispatch("setShowTopbar", condition);

      if (condition) {
        dispatch("setPageTitle", pageTitle);
      }
    }
  },
  getters: {
    pageTitle: state => state.pageTitle,
    showSidebar: state => state.showSidebar,
    showTopbar: state => state.showTopbar,
    isAuthPageHeader: state => state.isAuthPageHeader,
    isFullscreen: state => state.isFullscreen
  }
};
