import Answers from "@/components/assessment/pregnancy/answers.json";
import i18n from "@/plugins/i18n";
import sentry from "@/sentry.js";

const basePath = "pregnancyAssessment.report.content.noPain";
export class PregnancyReportNoPainService {
  constructor(results) {
    this.results = results;
  }

  getCurrentTrimester() {
    return this.results?.trimester?.pregnancyTrimester;
  }

  isFirstTrimester() {
    return (
      this.getCurrentTrimester() === Answers.trimester.pregnancyTrimester.first
    );
  }

  getTitle() {
    const trimesterPath = {
      [Answers.trimester.pregnancyTrimester.first]: "firstTrimester",
      [Answers.trimester.pregnancyTrimester.second]: "secondTrimester",
      [Answers.trimester.pregnancyTrimester.third]: "thirdTrimester"
    };

    const currentTrimesterPath = trimesterPath[this.getCurrentTrimester()];
    if (!currentTrimesterPath) {
      sentry.captureException(
        `No title path mapped for pregnancy trimester: ${this.getCurrentTrimester()}`
      );
      return "";
    }

    return i18n.t(`${basePath}.${currentTrimesterPath}.title`);
  }

  getText() {
    const trimesterPath = {
      [Answers.trimester.pregnancyTrimester.first]: "firstTrimester",
      [Answers.trimester.pregnancyTrimester.second]: "secondTrimester",
      [Answers.trimester.pregnancyTrimester.third]: "thirdTrimester"
    };

    const currentTrimesterPath = trimesterPath[this.getCurrentTrimester()];
    if (!currentTrimesterPath) {
      sentry.captureException(
        `No text path mapped for pregnancy trimester: ${this.getCurrentTrimester()}`
      );
      return [];
    }

    return i18n.t(`${basePath}.${currentTrimesterPath}.text`);
  }

  getIllustrationPath() {
    const imageName = {
      [Answers.trimester.pregnancyTrimester.first]: "sneak-peak",
      [Answers.trimester.pregnancyTrimester.second]: "sneak-peak",
      [Answers.trimester.pregnancyTrimester.third]: "understanding-body"
    };

    return `/images/pregnancy-assessment/report/${
      imageName[this.getCurrentTrimester()]
    }.svg`;
  }
}
