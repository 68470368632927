<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4896_54065)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.6133 420.188L126.41 526.309L140.895 605.987H155.433L140.921 420.188H95.6133Z"
        fill="#997659"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.7717 420.188C83.9205 475.317 83.073 505.387 82.5921 510.395C82.1112 515.404 79.3808 548.95 60.7754 607.669H75.8969C100.537 551.338 109.138 517.792 111.964 510.395C114.79 502.998 123.137 472.929 135.322 420.188H81.7717Z"
        fill="#B28B67"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M94.9531 420.188C102.964 456.704 116.819 514.995 136.518 595.058H157.782C159.353 512.881 152.672 458.794 141.101 420.188H94.9531Z"
        fill="#17282F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.666 420.188C83.7705 475.317 78.3165 529.631 61.4219 595.916H84.1099C108.795 540.426 126.488 486.381 138.579 420.188L84.526 405.008C84.526 405.008 82.7625 409.187 82.1906 412.014C81.5563 415.149 81.666 420.188 81.666 420.188Z"
        fill="#223A43"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.6191 618.598L59.3883 606.197C59.4217 605.658 59.9453 605.29 60.4727 605.402C67.7594 606.95 77.8093 603.465 77.8093 603.465C87.924 610.63 102.506 613.918 107.64 614.902C108.634 615.093 109.271 616.095 108.995 617.068L107.846 621.12H77.8093H60.2878L58.6191 618.598Z"
        fill="#17282F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M137.648 618.598L138.418 606.197C138.451 605.658 138.975 605.29 139.502 605.402C146.789 606.95 156.839 603.465 156.839 603.465C166.953 610.63 181.535 613.918 186.669 614.902C187.663 615.093 188.3 616.095 188.024 617.068L186.875 621.12H156.839H139.317L137.648 618.598Z"
        fill="#17282F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M111.753 294.149C118.458 298.191 125.046 300.144 128.139 299.346C135.894 297.347 136.607 268.977 130.607 257.792C124.606 246.608 93.7584 241.733 92.2036 263.893C91.6639 271.584 94.8934 278.448 99.665 284.049L91.1094 323.926H115.961L111.753 294.149Z"
        fill="#B28B67"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M132.955 262.815C132.708 261.365 132.107 259.755 131.621 258.384C130.996 256.615 130.048 255.021 129.006 253.519C127.044 250.69 124.69 248.135 122.091 246.015C117.343 242.143 111.3 239.909 105.357 240.634C102.356 241.001 99.4433 242.145 96.9641 244.05C94.7242 245.772 92.5696 248.305 89.6691 248.487C86.5132 248.685 83.6203 245.9 81.0892 244.179C78.2354 242.24 75.1995 240.827 71.8642 240.277C66.2767 239.357 61.0276 241.245 57.1768 245.829C53.079 250.708 50.1003 257.862 52.7913 264.326C53.2915 265.527 53.935 266.54 54.8586 267.38C55.7056 268.149 56.9992 268.969 57.3426 270.199C57.7074 271.506 56.6177 273.193 56.1952 274.373C55.5899 276.063 55.0929 277.838 55.2696 279.675C55.5599 282.692 57.4561 285.573 59.4583 287.556C61.4959 289.574 64.0037 290.731 66.6822 291.265C68.4707 291.621 70.2986 291.818 72.1163 291.684C73.0187 291.618 73.8149 291.367 74.6892 291.168C75.5394 290.974 76.0146 291.199 76.7359 291.666C80.0869 293.832 83.6899 294.67 87.5592 294.348C90.8247 294.076 94.5886 293.285 97.2525 291.047C100.211 288.562 100.152 285.136 99.4558 281.475C100.193 281.84 102.234 282.066 101.015 280.609C100.532 280.032 99.6189 279.798 98.9991 279.458C98.2774 279.063 97.5447 278.552 96.9843 277.908C94.7216 275.305 97.0069 270.008 99.8509 269.135C104.076 267.838 105.022 273.814 108.039 275.36C109.773 276.248 111.587 275.034 112.886 273.793C114.627 272.132 115.813 269.93 116.837 267.69C117.681 265.846 118.466 263.975 119.326 262.139C119.727 261.282 121.118 258.859 120.419 257.89C123.446 257.286 126.817 258.544 129.423 260.193C130.5 260.875 131.322 261.627 131.896 262.83C132.02 263.088 132.288 263.855 132.624 263.886C133.251 263.945 133.016 263.179 132.955 262.815Z"
        fill="#070707"
      />
      <path
        d="M79.3007 340.975C77.8583 334.456 79.8115 327.281 84.3593 322.393C85.5171 321.149 87.2044 319.968 88.7955 320.568C90.3886 321.169 90.8764 323.181 90.8827 324.884C90.9078 331.97 86.1898 338.836 79.5659 341.352"
        fill="#E7E8E5"
      />
      <path
        d="M85.1287 327.312C85.0938 327.289 84.8691 327.548 84.4916 328.039C84.1078 328.525 83.5997 329.264 82.9961 330.194C81.844 332.068 80.2787 334.795 79.6298 338.182C79.3101 339.868 79.2976 341.526 79.5055 342.998C79.7079 344.476 80.1762 345.742 80.6165 346.766C81.0938 347.776 81.5383 348.563 81.9103 349.062C82.2662 349.572 82.4776 349.841 82.5132 349.817C82.6179 349.752 81.8356 348.617 81.017 346.598C80.6395 345.58 80.2306 344.344 80.0666 342.922C79.8984 341.503 79.9263 339.92 80.2334 338.296C80.8558 335.036 82.322 332.329 83.3694 330.417C84.4609 328.513 85.2103 327.369 85.1287 327.312Z"
        fill="#DADBD8"
      />
      <path
        d="M117.9 310.774C118.099 311.017 128.298 324.91 128.298 324.91C128.298 324.91 143.693 344.743 145.136 351.251C147.27 360.876 142.905 365.384 142.905 365.384C142.905 365.384 153.683 388.952 154.69 396.568C155.695 404.185 153.971 410.652 151.384 416.4C148.797 422.149 143.222 432.256 143.222 432.256C143.222 432.256 83.6675 422.515 78.6375 415.76L85.9962 396.568L77.9487 341.24C77.9487 341.24 75.4826 319.134 82.3437 313.508C86.8175 309.84 93.4749 306.533 93.4749 306.533L117.9 310.774Z"
        fill="#F8A531"
      />
      <path
        d="M115.767 370.594C115.769 370.615 116.103 370.617 116.704 370.556C117.305 370.496 118.17 370.343 119.206 370.026C121.264 369.41 124.033 367.961 126.174 365.397C128.313 362.83 129.243 359.846 129.479 357.71C129.606 356.634 129.601 355.756 129.552 355.154C129.504 354.552 129.442 354.225 129.421 354.227C129.344 354.23 129.538 355.564 129.225 357.675C128.927 359.768 127.98 362.662 125.895 365.165C123.809 367.662 121.131 369.114 119.125 369.782C117.105 370.469 115.756 370.517 115.767 370.594Z"
        fill="#C68427"
        stroke="#C68427"
        stroke-width="0.778448"
      />
      <path
        d="M117.076 373.105C119.261 373.105 121.51 372.263 123.213 370.856C125.918 368.62 127.449 365.265 128.342 361.858C128.32 361.909 128.298 361.959 128.276 362.009C127.993 362.793 127.53 363.593 126.952 364.366C126.712 364.714 126.451 365.059 126.171 365.396C124.029 367.96 121.261 369.409 119.202 370.025C118.638 370.198 118.125 370.322 117.677 370.408C115.298 371.232 113.017 371.929 113.017 371.929C114.226 372.74 115.637 373.105 117.076 373.105Z"
        fill="#DB8E24"
      />
      <path
        d="M117.679 370.408C118.127 370.322 118.64 370.198 119.204 370.025C121.262 369.409 124.031 367.96 126.172 365.396C126.453 365.059 126.713 364.714 126.953 364.366C125.381 366.465 122.952 368.364 120.956 369.189C120.006 369.582 118.83 370.01 117.679 370.408ZM128.277 362.009C128.299 361.959 128.322 361.909 128.343 361.858C128.377 361.731 128.41 361.602 128.442 361.474C128.397 361.651 128.341 361.83 128.277 362.009Z"
        fill="#0F191D"
      />
      <path
        d="M128.163 429.638C130.92 430.206 133.734 430.352 136.468 429.888L136.871 427.372C136.822 426.948 136.708 426.53 136.518 426.127C135.955 424.925 135.062 423.912 134.161 422.937C121.977 409.763 106.202 400.565 90.6777 391.566C90.4732 391.447 90.2567 391.326 90.0304 391.266C88.8878 392.682 87.6143 394.264 86.2996 395.906C86.1617 396.354 86.0747 396.815 86.0577 397.292C85.9997 398.872 86.7189 400.376 87.5525 401.719C91.6708 408.351 98.6582 412.534 105.066 416.993C109.816 420.3 114.4 423.899 119.553 426.533C122.237 427.905 125.166 429.021 128.163 429.638Z"
        fill="#DB8E24"
      />
      <path
        d="M75.5055 337.68C75.7023 332.969 76.6806 322.93 81.7853 317.236C87.9228 310.425 95.1538 311.101 99.7706 319.023C104.478 327.101 102.284 335.488 102.628 339.5C103.05 344.449 100.674 362.129 100.274 366.308L99.906 381.171C99.906 381.171 131.866 413.965 138.9 417.477L131.866 428.807C123.655 427.992 86.2689 403.128 78.4252 388.013C75.1873 381.774 75.3094 342.392 75.5055 337.68Z"
        fill="#B28B67"
      />
      <path
        d="M103.099 354.225L72.8489 348.139C72.8489 348.139 75.336 329.223 78.6703 320.033C81.2634 312.884 89.3164 310.191 89.3164 310.191L102.614 318.225C102.614 318.225 104.889 328.986 104.711 338.83C104.531 348.676 103.099 354.225 103.099 354.225Z"
        fill="#F8A531"
      />
      <path
        d="M86.2706 314.371C86.2692 314.383 86.4464 314.41 86.7842 314.451C87.1575 314.495 87.6488 314.553 88.2622 314.624C89.5406 314.786 91.4087 314.985 93.6264 315.595C95.8183 316.195 98.4394 317.34 100.394 319.673C102.348 321.991 103.469 325.265 103.848 328.736C104.275 332.222 103.857 335.59 103.756 338.639C103.61 341.682 103.478 344.427 103.368 346.747C103.271 349.026 103.192 350.885 103.137 352.216C103.118 352.834 103.103 353.329 103.092 353.704C103.086 354.045 103.088 354.224 103.101 354.225C103.114 354.225 103.135 354.047 103.165 353.708C103.193 353.333 103.231 352.84 103.278 352.225C103.362 350.894 103.479 349.037 103.624 346.759C103.758 344.439 103.915 341.695 104.091 338.65C104.209 335.603 104.64 332.239 104.208 328.695C103.826 325.179 102.676 321.831 100.649 319.457C99.6411 318.278 98.4499 317.371 97.2426 316.717C96.0354 316.054 94.8142 315.648 93.6941 315.347C91.4401 314.761 89.5623 314.603 88.2768 314.483C87.632 314.428 87.131 314.396 86.7904 314.379C86.4506 314.361 86.2713 314.358 86.2706 314.371Z"
        fill="#C68427"
        stroke="#C68427"
        stroke-width="0.778448"
      />
      <path
        d="M138.717 416.739C138.717 416.739 146.987 414.236 149.376 414.581C151.764 414.927 154.443 418.673 154.443 418.673C154.443 418.673 154.244 419.601 153.733 420.498C153.733 420.498 155.337 425.507 151.976 427.487C151.976 427.487 153.007 429.803 149.96 431.815C149.96 431.815 149.002 433.51 146.565 434.424C146.565 434.424 145.69 437.601 143.228 437.987C140.766 438.371 139.16 437.577 139.16 437.577C139.16 437.577 132.123 432.228 130.268 428.669L138.717 416.739Z"
        fill="#B28B67"
      />
      <path
        d="M149.842 419.067C149.821 419.169 150.686 419.412 151.7 419.83C152.717 420.242 153.504 420.676 153.562 420.588C153.617 420.511 152.894 419.923 151.838 419.493C150.785 419.06 149.857 418.973 149.842 419.067Z"
        fill="#B77A23"
      />
      <path
        d="M151.414 370.593C151.434 370.326 151.268 367.716 149.649 366.611C148.028 365.507 143.19 365.135 143.19 365.135L138.996 368.959C138.996 368.959 139.03 370.932 141.894 370.964C141.894 370.964 135.86 373.832 135.728 375.613C135.597 377.395 136.682 378.46 136.682 378.46C136.682 378.46 133.924 379.421 134.41 381.337C134.895 383.254 136.697 383.119 136.697 383.119C136.697 383.119 135.78 385.827 138.917 385.79C142.055 385.753 146.894 384.499 146.894 384.499C146.894 384.499 147.033 386.247 145.24 386.276C143.447 386.304 142.035 387.185 142.035 387.185C142.035 387.185 141.428 389.38 143.484 389.442C145.539 389.503 147.383 389.998 149.422 389.074C151.46 388.15 153.261 388.015 153.222 386.131C153.182 384.247 152.941 383.871 152.941 383.871C152.941 383.871 154.473 381.655 153.668 380.253C152.864 378.85 152.864 378.85 152.864 378.85C152.864 378.85 154.632 376.741 153.675 375.148C152.718 373.555 151.418 371.757 151.418 371.757L151.414 370.593Z"
        fill="#B28B67"
      />
      <path
        d="M136.67 378.369C136.69 378.418 137.372 378.173 138.467 377.771C139.563 377.37 141.076 376.817 142.759 376.241C144.443 375.666 145.977 375.178 147.09 374.826C148.203 374.473 148.89 374.25 148.877 374.2C148.865 374.152 148.153 374.289 147.019 374.58C145.886 374.869 144.332 375.32 142.641 375.897C140.951 376.475 139.446 377.069 138.372 377.533C137.298 377.997 136.651 378.325 136.67 378.369Z"
        fill="#B77A23"
      />
      <path
        d="M136.282 383.096C136.312 383.191 139.127 382.406 142.57 381.342C146.013 380.278 148.78 379.337 148.75 379.241C148.72 379.146 145.905 379.931 142.462 380.995C139.02 382.059 136.252 383 136.282 383.096Z"
        fill="#B77A23"
      />
      <path
        d="M139.021 371.945C139.051 372.041 140.568 371.641 142.409 371.053C144.25 370.464 145.718 369.909 145.687 369.814C145.656 369.718 144.139 370.118 142.298 370.707C140.457 371.295 138.99 371.85 139.021 371.945Z"
        fill="#B77A23"
      />
    </g>
    <defs>
      <clipPath id="clip0_4896_54065">
        <rect width="708" height="704" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CirculationSecondTrimester"
};
</script>
