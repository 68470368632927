<template>
  <div class="report-footer-container" :class="{ 'pt-2': isDeskAssessment }">
    <div class="d-flex flex-column align-center text-center">
      <h3
        v-if="isBurnoutAssessment"
        class="text-h5 mb-8"
        style="color: var(--v-vflTextDark-base)"
        v-html="$t('assessment.footer.title.generic')"
      />
      <i18n
        v-else
        path="assessment.footer.title.programme"
        tag="h3"
        class="text-h5 mb-8"
        style="color: var(--v-vflTextDark-base)"
      >
        <template v-slot:aim>
          <span class="vflBrightGreenDark1--text">{{
            $t(programmeAimTitle)
          }}</span>
        </template>
      </i18n>
      <h5 class="text-body-1 mb-8" style="color: var(--v-vflTextDark-base)">
        {{ $t("assessment.footer.subtitle") }}
      </h5>

      <button
        class="vfl-button-primary has-icon pl-6 pr-8 mb-4"
        id="dashboardRedirect"
        @click="goToDashboard"
      >
        {{ ctaText }}
      </button>

      <email-report-button
        class="mb-6"
        v-if="showEmailButton"
        id="sendReportCheckbox"
        :assessmentId="assessmentId"
        :assessmentType="assessmentType"
      />

      <v-img
        v-if="isDeskAssessment || isWomensHealthAssessment"
        :src="imageToShow"
        contain
        class="mt-6 zoom"
        @click="goToDashboard"
        style="cursor: pointer"
        max-height="300px"
      />
    </div>
    <report-footer-animated-emojis v-if="reportContext === 'assessment'" />
  </div>
</template>

<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { mapGetters } from "vuex";
import { viewingOwnResults } from "@/services/privacy-service.js";
import { DeskAssessmentGoals } from "@/constants/constants.js";
import EmailReportButton from "@/components/common/report/EmailReportButton.vue";
import { assessmentTypes } from "@/constants/constants.js";
import ReportFooterAnimatedEmojis from "@/components/common/report/ReportFooterAnimatedEmojis.vue";
import { getRankedPainAreas } from "@/services/assessment/pain/pain-rating-service.js";
import BodyAreas from "@/components/painareas/body-areas.json";
import { routes } from "@/router/route-constants.js";

export default {
  name: "EndOfReportFooter",
  components: {
    ReportFooterAnimatedEmojis,
    EmailReportButton,
    VitrueTooltip
  },
  props: {
    assessmentId: String,
    assessmentType: String,
    results: Object,
    reportContext: {
      type: String,
      required: true,
      validator: value => ["assessment", "archive"].includes(value)
    }
  },
  data() {
    return {
      emailReportCheckbox: false,
      isRedirectingToDashboard: false
    };
  },
  methods: {
    async goToDashboard() {
      if (this.isRedirectingToDashboard) return;

      this.isRedirectingToDashboard = true;

      if (this.signedIn) {
        this.$mixpanel.track(`Go to dashboard after ${this.assessmentType}`);
        const showSnackbar = this.reportContext === "assessment";
        await this.$router.push({
          name: routes.WELLNESS,
          params: {
            showAssessmentCompletedSnackbar: showSnackbar
          }
        });
        this.isRedirectingToDashboard = false;
        return;
      }
    },
    getPainTitle() {
      if (this.rankedPainAreas.length === 0) return "";
      const priorityPainArea = BodyAreas[this.rankedPainAreas[0].area].name;
      const painAreaText = this.$t(priorityPainArea).toLowerCase();
      const title =
        this.rankedPainAreas.length > 1 ? "painMultipleAreas" : "painOneArea";
      return this.$t(`assessment.footer.title.${title}`, {
        pain: painAreaText
      });
    }
  },
  computed: {
    ...mapGetters(["signedIn", "userEmail"]),
    showEmailButton() {
      return this.viewingOwnResults && !this.isWomensHealthAssessment;
    },
    viewingOwnResults() {
      return viewingOwnResults(this.$route);
    },
    isDeskAssessment() {
      return this.assessmentType === assessmentTypes.deskAssessment;
    },
    isBurnoutAssessment() {
      return this.assessmentType === assessmentTypes.burnoutAssessment;
    },
    isWomensHealthAssessment() {
      return (
        this.assessmentType === assessmentTypes.menopauseAssessment ||
        this.assessmentType === assessmentTypes.menstruationAssessment
      );
    },
    imageToShow() {
      const isEnglish = this._i18n.locale.includes("en");
      const baseImage = this.isWomensHealthAssessment
        ? "painCoachTeaser2"
        : "painCoachTeaser";

      return `/images/wellness/${baseImage}${isEnglish ? "" : "Es"}.webp`;
    },
    userGoal() {
      return this.results?.userGoal || DeskAssessmentGoals.IMPROVE_WELLBEING;
    },
    ctaText() {
      if (this.isWomensHealthAssessment) {
        return this.$t(`assessment.footer.endOfReportCta.explore`);
      }

      return this.$t("assessment.footer.endOfReportCta.saveAndContinue");
    },
    programmeAimTitle() {
      if (this.rankedPainAreas.length > 0) {
        return this.getPainTitle();
      }
      const aim =
        this.userGoal === DeskAssessmentGoals.FIX_POSTURE
          ? "posture"
          : "wellbeing";
      return this.$t(`assessment.footer.title.${aim}`);
    },
    rankedPainAreas() {
      if (!this.results?.pain) return [];
      return getRankedPainAreas(this.results, this.assessmentType);
    }
  }
};
</script>

<style scoped lang="scss">
.report-footer-container {
  background-image: linear-gradient(to bottom, white, transparent 20%),
    url("/images/report-footer-gradient.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover, cover;
  // use negative margin to align the gradient with the bottom of the page
  margin-bottom: calc((12px + var(--main-padding-bottom)) * -1);
  overflow: hidden;
  padding: 6rem 1rem;
  position: relative;
  width: 100%;
}

.v-image {
  min-height: 300px;
}

.zoom {
  transition: transform 0.12s ease-out;
}
.zoom:hover {
  transform: scale(1.0125);
}
</style>
