<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4796_18912)">
      <rect
        x="101.678"
        y="449.527"
        width="513"
        height="9"
        rx="2"
        transform="rotate(10 101.678 449.527)"
        fill="#C27100"
      />
    </g>
    <defs>
      <clipPath id="clip0_4796_18912">
        <rect width="708" height="704" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ModeratelyRightTiltedPlank"
};
</script>
