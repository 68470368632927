<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="346.5" cy="157.5" r="62.5" fill="#8AAFAF" />
    <g clip-path="url(#clip0_4896_54075)">
      <rect x="282" y="86" width="125" height="125" rx="60.6796" fill="white" />
      <rect
        x="282"
        y="86"
        width="125"
        height="125"
        rx="60.6796"
        fill="#C6DDDD"
      />
      <path
        d="M293.785 151.05V212.848"
        stroke="#A7A6B4"
        stroke-width="2.76405"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M328 172.348V234.145"
        stroke="#A7A6B4"
        stroke-width="2.76405"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M353.141 139.529V210.404"
        stroke="#A7A6B4"
        stroke-width="2.76405"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M387.354 160.826V222.623"
        stroke="#A7A6B4"
        stroke-width="2.76405"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M296.521 154.893H291V150.47H296.521V154.893Z" fill="#A7A6B4" />
      <path
        d="M384.077 160.469C388.326 163.229 387.72 166.208 382.729 167.088L331.977 176.037C326.987 176.918 319.427 175.378 315.177 172.618L293.832 158.749C289.582 155.988 290.189 153.009 295.179 152.129L345.932 143.18C350.922 142.3 358.482 143.839 362.731 146.6L384.077 160.469Z"
        fill="#A7A6B4"
      />
      <path
        d="M384.077 156.046C388.326 158.807 387.72 161.786 382.729 162.666L331.977 171.616C326.987 172.495 319.427 170.956 315.177 168.196L293.832 154.326C289.582 151.565 290.189 148.587 295.179 147.707L345.932 138.757C350.922 137.878 358.482 139.416 362.731 142.177L384.077 156.046Z"
        fill="#B9C0D6"
      />
      <path
        d="M399.08 198.36C399.097 200.976 399.111 203.676 399.111 206.652C399.46 211.627 408.663 212.348 411.331 214.305C417.441 218.785 405.162 218.551 400.682 217.679C391.186 215.829 390.731 217.912 388.666 215.293C387.113 213.325 388.394 210.7 389.324 208.838C390.382 205.808 390.332 202.019 389.335 198.592"
        fill="#3E79AE"
      />
      <path
        d="M355.91 217.955C355.876 218.039 357.158 216.215 357.123 216.3C356.915 216.811 356.081 217.569 355.91 217.955C354.252 222.667 357.82 227.996 360.962 230.964C361.661 231.575 362.827 233.272 362.827 234.406C362.74 235.628 360.819 236.064 359.859 236.238C356.28 236.676 351.683 235.462 350.257 232.312C348.599 228.646 345.545 225.766 345.545 222.186C345.545 220.703 346.068 219.568 346.854 218.434C347.29 217.823 348.25 215.728 348.599 213.808C349.191 210.847 349.407 208.957 349.638 206.985C349.638 206.985 354.098 209.094 354.36 209.007C354.622 208.92 355.876 218.039 355.876 218.039"
        fill="#B28B67"
      />
      <path
        d="M399.486 204.285C399.468 201.929 398.858 163.098 397.926 154.718C397.228 147.037 394.103 146.165 386.655 144.07C371.759 139.648 363.147 139.067 347.785 136.505C332.19 134.178 331.259 144.885 331.492 146.049C331.725 147.212 343.596 156.289 345.458 156.523C347.319 156.756 384.188 162.326 384.188 162.326C382.691 170.273 387.879 193.211 387.937 203.249C387.954 206.308 399.503 206.641 399.486 204.285Z"
        fill="#17282F"
      />
      <path
        d="M360.257 216.539C361.165 214.284 373.387 176.077 375.481 170.26C379.882 158.038 370.749 157.772 363.5 153.5C357.2 149.787 352.452 147.066 345.459 144.5C337.879 140.699 336.121 137.796 343.364 136.038C343.364 136.038 329.864 132.315 323.812 135.806C316.596 138.831 311.709 147.443 315.2 154.891C320.088 165.831 335.217 167.227 345.459 168.624C349.881 169.323 353.206 170.045 357.628 170.51C352.957 178.498 348.141 210.404 348.141 210.404C347.953 215.231 358.046 220.484 360.257 216.539Z"
        fill="#223A43"
      />
      <path
        d="M316.131 142.319C320.647 131.529 319.796 125.561 318.749 112.47C317.784 100.41 318.35 85.7141 328.179 76.2159C334.733 69.8807 343.523 68.835 350.614 76.7125C353.767 80.2141 353.655 96.6747 358.719 109.479C363.28 121.014 370.593 132.09 363.21 139.193C357.484 144.702 345.935 143.191 343.189 143.192C340.443 143.192 326 142.319 316.131 142.319Z"
        fill="#F8A531"
      />
      <path
        d="M353.432 78.2541C362.975 86.8668 371.47 96.3337 372.098 98.7523C373.029 103.175 373.391 125.565 370.598 130.453L366.874 127.078C367.805 122.888 363.506 106.705 364.267 101.941C364.531 100.285 354.579 94.7665 354.055 94.069C353.531 93.3706 353.664 80.7012 353.664 80.7012L353.432 78.2541Z"
        fill="#B28B67"
      />
      <path
        d="M363.731 132.663C353.171 133.767 321.653 133.496 318.275 129.386C315.275 125.736 317.431 108.106 318.575 100.775C319.584 94.3146 318.736 83.6085 328.527 84.0167C345.132 84.7132 325.67 113.691 328.673 120.556C329.284 121.952 332.165 122.476 334.26 122.476C344.172 122.926 353.337 125.474 363.265 125.68C364.894 125.215 370.596 127.193 371.644 128.008C373.273 129.404 372.459 131.15 372.11 132.896C371.644 135.107 371.295 136.736 368.735 137.085C366.058 137.319 365.243 132.547 363.731 132.663Z"
        fill="#B28B67"
      />
      <path
        d="M325.953 79.0188C329.096 76.4584 326.607 81.1066 331.029 81.4558C333.822 81.805 336.848 82.6195 337.663 85.53C338.477 88.7879 337.779 92.7442 337.314 96.0039C336.266 103.8 333.265 111.945 332.395 114.644C331.525 117.342 316.356 115.638 316.801 111.269C317.244 106.9 318.582 85.7751 325.953 79.0188Z"
        fill="#F8A531"
      />
      <path
        d="M347.844 74.1459C354.535 78.0828 363.7 85.9309 366.491 90.0769C368.155 92.5489 357.647 99.1366 356.37 98.0623C354.434 95.7515 349.688 91.8266 348.524 89.6163"
        fill="#F8A531"
      />
      <path
        d="M328.684 115.924C328.684 115.924 330.855 115.899 332.069 114.869C333.284 113.838 335.241 92.6469 335.241 92.6469"
        stroke="#B0741F"
        stroke-width="0.230337"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M353.489 95.8672C353.489 95.8672 354.342 87.2314 352.854 82.3961"
        stroke="#B0741F"
        stroke-width="0.230337"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4896_54075">
        <rect
          x="282"
          y="86"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CirculationSitting"
};
</script>
