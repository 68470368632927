<template>
  <div style="width: fit-content">
    <vfl-callout :style="{ backgroundColor: color }" size="small"
      ><div class="d-flex align-center">
        <img src="/images/icons/lock.svg" class="mr-2" height="16" />
        <p class="vflTextDark--text mb-0">
          {{ $t("menopauseAssessment.landingPage.confidentiality") }}
        </p>
      </div></vfl-callout
    >
  </div>
</template>

<script>
import VflCallout from "@/components/vitrue-foundation-library/VflCallout.vue";
export default {
  name: "ConfidentialityCallout",
  components: {
    VflCallout
  },
  props: {
    color: {
      type: String,
      default: "#ffdbd2"
    }
  }
};
</script>
