﻿<template>
  <div>
    <incomplete-assessment-dialog
      v-model="showIncompleteAssessmentDialog"
      @input="$emit('input', $event)"
      @startNew="incompleteAssessmentDialogSetOpened"
      :assessmentId="incompleteAssessmentId"
      :assessmentType="assessmentType"
    />

    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog && enableGeneralFeedback"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '480px'"
      content-class="mx-4"
    >
      <feedback-dialog
        v-model="feedbackDialog"
        :assessmentId="assessmentId"
        :urlSuffix="currentTheme"
      />
    </v-dialog>

    <loader
      v-if="loading"
      data-test-id="physical-assessment-loader"
      :trigger="loading"
      @close="loading = saving"
    />

    <div
      class="px-4 pt-4 mx-auto"
      :class="{ 'pb-4': !isReport }"
      v-else-if="currentTheme && activeAsyncRequestsCount === 0"
      :data-test-id="assessmentType"
      ref="container"
      :style="containerStyle"
    >
      <vfl-stepper
        class="mt-md-4"
        v-if="currentStepObj.STEPPER"
        :steps="stepper"
        :currentStep="currentStepperIndex"
        :display-labels="true"
      />

      <start-page
        v-if="currentTheme === $options.steps.Welcome.THEME"
        @next="onNext"
        imagePath="physical-labour-assessment/physical-labour.svg"
        imagePathMobile="physical-labour-assessment/physical-labour-16-9.svg"
        localeRoot="physicalLabourAssessment"
      />

      <pain-introduction-page
        v-if="currentTheme === $options.steps.PainIntro.THEME"
        @next="onNext"
        :results="results"
      />

      <assessment-form
        v-if="currentStepObj.FORM"
        :key="sectionKey"
        :schema="currentSchema"
        v-model="results[currentTheme]"
        :ref="sectionKey"
        :isPainTheme="currentTheme === $options.steps.Pain.THEME"
        :results="results"
        :highlightedTitleText="chosenJobActivityText"
        :assessmentType="assessmentType"
      />
      <physical-labour-report
        v-if="currentTheme === $options.steps.Report.THEME"
        :results="results"
        :assessmentId="assessmentId"
        reportContext="assessment"
      />
      <navigation
        v-if="currentStepObj.NAVIGATION"
        @previous="onPrevious"
        @next="onNext"
        :loading="nextClicked"
      />
    </div>

    <feedback-button
      v-if="enableGeneralFeedback"
      v-model="feedbackDialog"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'top:50%;right: 0%'"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  PhysicalLabourAssessmentSteps,
  getStepByIndex
} from "@/components/assessment/physical-labour/steps.js";
import { assessmentTypes } from "@/constants/constants.js";
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
import Navigation from "@/components/assessment/physical-labour/Navigation.vue";
import AssessmentForm from "@/components/assessment/AssessmentForm.vue";
import PhysicalLabourReport from "@/components/assessment/physical-labour/report/PhysicalLabourReport.vue";
const FeedbackDialog = () =>
  import("@/components/common/Feedback/FeedbackDialog.vue");
import {
  getNextSchemaIndex,
  getPreviousSchemaIndex
} from "@/components/common/assessments/assessment-validation.js";
import VflStepper from "@/components/common/VflStepper.vue";
import _ from "lodash";
import SaveAssessment from "@/components/common/assessments/save-assessment-service.js";
import { getRankedPainAreaDtosOrDefault } from "@/services/assessment/pain/pain-rating-service.js";
import {
  updateWellnessProgrammeWithPain,
  getInProgressAssessment,
  sendAssessmentCompleteNotification,
  addAssessmentRecommendations
} from "@/customApi";
import LoadingService from "@/services/assessment-loading.js";
import { GetAssessmentResults } from "@/components/common/assessments/get-assessment-service.js";
import IncompleteAssessmentDialog from "@/components/common/assessments/IncompleteAssessmentDialog.vue";
import Loader from "@/components/assessment/physical-labour/PhysicalLabourLoader.vue";
import StartPage from "@/components/assessment/StartPage.vue";
import { getOverallScore } from "@/services/assessment/physical-labour/overall-scoring-service.js";
import PainIntroductionPage from "@/components/assessment/physical-labour/PainIntroductionPage.vue";
import { prefill } from "./utils/prefillAssessment.js";
import { getSchema } from "@/services/assessment/get-schema-service.js";
import { getRecommendations } from "@/components/assessment/physical-labour/report/recommendations/report-recommendation-generator.js";

export default {
  name: "PhysicalLabourAssessment",
  components: {
    AssessmentForm,
    Navigation,
    VflStepper,
    FeedbackButton,
    FeedbackDialog,
    IncompleteAssessmentDialog,
    PhysicalLabourReport,
    Loader,
    StartPage,
    PainIntroductionPage
  },
  props: {
    assessmentId: String
  },
  steps: PhysicalLabourAssessmentSteps,
  data() {
    return {
      assessmentType: assessmentTypes.physicalLabourAssessment,
      currentPageIndex: -1,
      currentSchemaIndex: -1,
      stepper: null,
      results: {
        sectors: {},
        pain: {
          areas: {}
        },
        jobNature: {},
        reba: {},
        workStation: {},
        health: {}
      },
      feedbackDialog: false,
      incompleteAssessmentId: null,
      showIncompleteAssessmentDialog: false,
      loading: false,
      saving: false,
      nextClicked: false
    };
  },
  created() {
    if (this.disableAssessments) {
      this.$router.push("/wellness");
    }
    this.schema = getSchema(assessmentTypes.physicalLabourAssessment);
    this.buildSteps();
  },
  mounted() {
    this.startAssessment();
  },
  beforeDestroy() {
    this.configureLayoutForAssessmentReport({
      condition: false,
      pageTitle: null
    });
  },
  computed: {
    ...mapGetters([
      "physicalLabourAssessmentEnabled",
      "activeAsyncRequestsCount",
      "signedIn",
      "prefillPhysicalLabourAssessmentResponses",
      "disableAssessments",
      "signedIn"
    ]),
    currentStepObj() {
      return getStepByIndex(this.currentPageIndex);
    },
    currentTheme() {
      return this.currentStepObj?.THEME || "welcome";
    },
    currentThemeQuestions() {
      if (this.currentTheme === this.$options.steps.Pain.THEME)
        return this.currentSchema;
      const questions = this.schema
        .filter(p => p.theme === this.currentTheme)
        .flatMap(p => p.questions);
      return { theme: this.currentTheme, questions };
    },
    currentSchema() {
      return this.schema[this.currentSchemaIndex];
    },
    currentStepperIndex() {
      return _(this.schema)
        .uniqBy("theme")
        .map(p => p.theme)
        .indexOf(this.currentTheme);
    },
    sectionKey() {
      return `${this.currentTheme}, page ${this.currentPageIndex}`;
    },
    jobActivity() {
      const chosenActivity = this.results.reba.chosenJobActivity;
      const allActivities = this.results.jobNature.jobActivities || [];
      return chosenActivity || allActivities.find(x => x);
    },
    chosenJobActivityText() {
      if (!this.jobActivity)
        return this.$t("physicalLabourAssessment.schema.jobNature.generic");
      const question = this.schema
        .flatMap(p => p.questions)
        .find(q => q.name === "chosenJobActivity");
      const answer = question.options.find(o => o.value === this.jobActivity);
      return this.$t(answer.title);
    },
    score() {
      return this.onLastPage ? getOverallScore(this.results) : null;
    },
    onLastPage() {
      return this.currentPageIndex === this.$options.steps.Report.INDEX - 1;
    },
    isForm() {
      const welcome = this.currentTheme === this.$options.steps.Welcome.THEME;

      return !welcome && !this.isReport;
    },
    isReport() {
      return this.currentTheme === this.$options.steps.Report.THEME;
    },
    containerStyle() {
      return {
        width: this.isForm ? `var(--assessment-form-width)` : "",
        maxWidth: "100%"
      };
    },
    enableGeneralFeedback() {
      return !this.signedIn;
    }
  },
  watch: {
    currentPageIndex() {
      this.$nextTick(() => this.scrollTop());
      this.handlePageLayout();
    },
    assessmentId(val) {
      if (val) {
        this.startAssessment();
      }
    }
  },
  methods: {
    ...mapActions("layout", ["configureLayoutForAssessmentReport"]),
    ...mapMutations(["setTeamMemberGender"]),
    scrollTop() {
      window.scrollTo(0, 0);
    },
    onPrevious() {
      let indexChangeValue = 1;
      if (this.currentStepObj.FORM) {
        const newSchemaIndex = getPreviousSchemaIndex(
          this.schema,
          this.results,
          this.currentSchemaIndex
        );
        indexChangeValue = this.currentSchemaIndex - newSchemaIndex;
        this.currentSchemaIndex = newSchemaIndex;
      }

      this.currentPageIndex -= indexChangeValue;
    },
    async onNext() {
      if (this.nextClicked) return;
      try {
        this.nextClicked = true;
        await this.goToNextPage();
      } catch (e) {
        this.$logger.captureException(e);
      } finally {
        this.nextClicked = false;
      }
    },
    async goToNextPage() {
      if (!this.currentStepObj.VALIDATION || this.isValid()) {
        await this.saveAssessment();
        const nextStep = getStepByIndex(this.currentPageIndex + 1);
        let indexChangeValue = 1;
        if (nextStep.FORM) {
          const newSchemaIndex = getNextSchemaIndex(
            this.schema,
            this.results,
            this.currentSchemaIndex
          );
          indexChangeValue = newSchemaIndex - this.currentSchemaIndex;
          this.currentSchemaIndex = newSchemaIndex;
        }
        this.currentPageIndex += indexChangeValue;
        this.$mixpanel.track(assessmentTypes.physicalLabourAssessment, {
          step: `Go to ${this.sectionKey}`
        });
      }
    },
    isValid() {
      return this.$refs[this.sectionKey].isValid();
    },
    buildSteps() {
      this.stepper = _(this.schema)
        .filter(({ theme }) => theme !== this.$options.steps.Sectors.THEME)
        .uniqBy("theme")
        .map(({ stepperTitle }) => this.$t(stepperTitle))
        .value();
    },
    async startAssessment() {
      const setToOpened = !this.incompleteAssessmentId;
      const getResults = false;

      const assessmentState = await LoadingService.getAssessmentStateAndResults(
        this.assessmentId,
        this.assessmentType,
        setToOpened,
        getResults
      );

      if (assessmentState.redirectObject) {
        this.$router.push(assessmentState.redirectObject);
        return;
      }

      // load schema after retrieving feature flags - required due to schema injection based on feature flags
      this.schema = getSchema(assessmentTypes.physicalLabourAssessment);

      if (assessmentState.assessmentStarted) {
        this.results = await GetAssessmentResults(
          this.assessmentId,
          this.assessmentType,
          this.schema
        );
      } else if (
        import.meta.env.DEV ||
        this.prefillPhysicalLabourAssessmentResponses
      ) {
        this.results = prefill();
      }

      if (assessmentState.assessmentComplete) {
        this.currentPageIndex = this.$options.steps.Report.INDEX;
      } else {
        await this.checkForInProgressAssessment();
      }
    },
    async checkForInProgressAssessment() {
      if (!this.signedIn) {
        return;
      }
      try {
        this.incompleteAssessmentId = await getInProgressAssessment(
          this.assessmentType
        );
      } catch (err) {
      } finally {
        this.showIncompleteAssessmentDialog =
          !!this.incompleteAssessmentId &&
          this.incompleteAssessmentId !== this.assessmentId;
      }
    },
    async incompleteAssessmentDialogSetOpened() {
      await LoadingService.setAssessmentToOpened(
        this.assessmentId,
        this.assessmentType
      );
    },
    async saveAssessment() {
      const results = this.results[this.currentTheme];

      if (!results) {
        return;
      }
      this.saving = true;
      this.loading = this.onLastPage;

      await SaveAssessment(
        this.assessmentId,
        results,
        this.assessmentType,
        this.currentThemeQuestions,
        this.score,
        this.onLastPage
      );

      if (this.onLastPage) {
        const painAreasDto = getRankedPainAreaDtosOrDefault(
          this.results,
          assessmentTypes.physicalLabourAssessment
        );
        await updateWellnessProgrammeWithPain(this.assessmentId, painAreasDto);
        await sendAssessmentCompleteNotification(this.assessmentId);
        await this.saveRecommendations();

        // force set gender as team member info isn't retrieved again
        if (this.results.health.gender) {
          this.setTeamMemberGender(this.results.health.gender);
        }

        this.loading = false;
      }
      this.saving = false;
    },
    async saveRecommendations() {
      const recommendations = getRecommendations(this.results);
      const dto = {
        assessmentId: this.assessmentId,
        recommendations: recommendations.map(x => x.id)
      };

      await addAssessmentRecommendations(dto);
    },
    handlePageLayout() {
      this.configureLayoutForAssessmentReport({
        condition: this.isReport,
        pageTitle: this.$t("pageTitle.physicalLabourAssessmentReport")
      });
    }
  }
};
</script>
