<template>
  <svg viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg" class="blob">
    <path
      class="blob-path"
      d="M220,390.9035681538722C270.9841814216485,393.9758876388272,330.7825166271209,402.93281331946986,365.1265893169164,365.12658931691647C399.02362670123716,327.81246527724403,377.16718885704097,270.375473775061,375.25286437560095,220C373.4620666642575,172.87513479618718,389.74818467250384,116.8293611182855,354.5004483830977,85.49955161690231C319.6531619186246,54.525680341650926,266.5112396687708,78.7763171663118,220,82.00411210822709C178.89590480864146,84.85666111563947,135.92896836227496,78.24828251934244,102.95625822065409,102.95625822065406C65.5425655812009,130.9920673689314,38.04739632444617,173.29946212857308,35.844953581142946,219.99999999999997C33.5578967660542,268.49469241909435,55.78455809137493,315.60513210536755,91.37004811486214,348.6299518851378C125.76072032856854,380.5459308758217,173.1664305983422,388.0813655841628,220,390.9035681538722"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "AnimatedBlob",
  props: {
    color: {
      type: String,
      default: "#FFBEAC"
    }
  }
};
</script>

<style scoped>
.blob {
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

@media (prefers-reduced-motion: no-preference) {
  .blob-path {
    animation: blob-morph 30s linear infinite;
  }
}

@keyframes blob-morph {
  0%,
  100% {
    d: path(
      "M220,390.9035681538722C270.9841814216485,393.9758876388272,330.7825166271209,402.93281331946986,365.1265893169164,365.12658931691647C399.02362670123716,327.81246527724403,377.16718885704097,270.375473775061,375.25286437560095,220C373.4620666642575,172.87513479618718,389.74818467250384,116.8293611182855,354.5004483830977,85.49955161690231C319.6531619186246,54.525680341650926,266.5112396687708,78.7763171663118,220,82.00411210822709C178.89590480864146,84.85666111563947,135.92896836227496,78.24828251934244,102.95625822065409,102.95625822065406C65.5425655812009,130.9920673689314,38.04739632444617,173.29946212857308,35.844953581142946,219.99999999999997C33.5578967660542,268.49469241909435,55.78455809137493,315.60513210536755,91.37004811486214,348.6299518851378C125.76072032856854,380.5459308758217,173.1664305983422,388.0813655841628,220,390.9035681538722"
    );
  }
  25% {
    d: path(
      "M220,438.52602283333374C273.4442621204825,434.521011093681,294.05722584030946,368.63406575502444,324.58263068585916,324.58263068585916C347.1998903139963,291.94349783922,368.6839626612432,259.61473019484134,371.42742355162386,220C374.39967507100295,177.08160359629858,372.2257494982384,128.51951715513368,340.14587884694544,99.85412115305454C308.85470877163124,71.89347906296125,261.60817734707166,79.24074973949016,220,84.69000736114513C185.0094388083164,89.27258209565667,156.311693544562,107.05078433916306,127.67994066286226,127.67994066286224C90.3396436526029,154.58359645301616,38.427569092360166,174.40046266604094,32.19967453494011,219.99999999999997C25.708744473389253,267.5254365572331,67.20965450002409,305.14837864512884,98.47315351660333,341.52684648339664C133.59164133655293,382.3910114000983,166.26941546065154,442.5524910325461,220,438.52602283333374"
    );
  }
  50% {
    d: path(
      "M220,368.9757782242754C263.0153622005721,372.0442084265328,307.7999233803971,368.4463469951868,341.3435622185037,341.34356221850373C378.199715505719,311.56431271427135,408.7748569780482,267.1789986299693,404.3791972506134,220C400.1704623739343,174.8272820706656,350.1095989243342,153.85106209305238,320.659566371897,119.34043362810303C286.21376336844196,78.97557808804709,272.58364265654217,11.064784484520846,220.00000000000006,3.937693051719937C166.23892812115204,-3.3489849996390992,118.8960836056466,43.336214530651404,87.77578942148703,87.775789421487C61.22116739178143,125.69561458135333,63.456471038572,174.0041612886285,68.69516481974107,219.99999999999997C73.19890503105317,259.54293131277495,86.45617908018284,297.261820069485,114.81544865048438,325.1845513495156C142.94871169128257,352.8847550508576,180.61865732686329,366.1665748354895,220,368.9757782242754"
    );
  }
  75% {
    d: path(
      "M220,382.11512836518096C269.3735852230892,382.2556937401799,327.67356691196164,397.42040945822,360.8947694011471,360.89476940114713C393.780994010665,324.7374267127141,376.7516071281394,267.98243233274945,367.4487373134231,220C360.2879762256936,183.06615687565184,342.69980641277976,150.46891776044401,315.13344326065715,124.8665567393429C288.7486395464796,100.36157338577165,255.92984884166833,85.44350011400702,220,83.05644368205C181.17445605268784,80.4770071580594,142.36014147166466,87.85339773252105,111.3263067293991,111.32630672939906C75.24004495658832,138.62069511760208,44.61498728673174,175.11063570535114,38.94516370533145,219.99999999999997C32.60714559794171,270.17962193074675,42.22443639750566,326.35572802809287,79.90520094498731,360.09479905501263C116.51589551952237,392.8757375372616,170.85823251563744,381.9752229695461,220,382.11512836518096"
    );
  }
}
</style>
