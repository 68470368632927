<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4896_54120)">
      <circle cx="599.5" cy="249.5" r="62.5" fill="#8AAFAF" />
      <g clip-path="url(#clip1_4896_54120)">
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="#C6DDDD"
        />
        <path
          d="M591.413 208.114H591.413C591.462 208.141 591.509 208.173 591.554 208.206C592.242 208.587 594.662 209.343 594.662 209.343C594.662 209.343 594.472 211.904 594.418 211.943L587.278 211.633L587.588 210.482C587.601 210.409 587.621 210.337 587.647 210.266C587.725 210.055 587.862 209.863 588.055 209.719L590.099 208.198C590.493 207.905 591.009 207.889 591.413 208.114Z"
          fill="#B28B67"
        />
        <path
          d="M598.283 262.993L597.217 271.39L592.853 271.523L590.032 259.368L598.283 262.993Z"
          fill="#B28B67"
        />
        <path
          d="M571.455 275.362L565.527 281.402L561.971 278.87L567.077 267.485L571.455 275.362Z"
          fill="#B28B67"
        />
        <path
          d="M618.434 147.861C618.495 143.491 620.192 141.666 620.192 141.666C621.039 139.352 634.835 138.175 638.295 147.004C640.516 152.67 646.194 163.683 646.194 163.683C669.402 195.885 660.18 211.771 660.18 211.771L659.188 211.663C659.53 210.607 659.799 209.119 659.45 208.486L658.505 211.639L614.174 202.133C609.922 196.157 618.238 162.168 618.434 147.861Z"
          fill="#A7A6B4"
        />
        <path
          d="M594.13 212.16C594.13 212.16 601.741 211.765 606.37 210.465C615.28 207.959 616.591 206.802 617.901 198.134C619.268 189.092 618.971 180.031 618.582 174.699C618.538 174.07 618.492 173.494 618.445 172.976C618.276 171.04 618.123 169.914 618.123 169.914C618.123 169.914 612.924 171.377 611.954 177.471C610.807 184.688 610.023 198.253 609.241 200.736C608.795 202.152 594.66 209.342 594.66 209.342L594.251 211.513L594.13 212.16Z"
          fill="#DD9128"
        />
        <path
          d="M594.13 212.16C594.13 212.16 601.741 211.765 606.37 210.465C615.28 207.959 616.591 206.802 617.901 198.134C619.268 189.092 618.971 180.031 618.582 174.699C617.199 175.301 615.837 177.292 615.179 182.308C613.567 194.607 616.389 202.267 611.349 205.793C607.146 208.736 598.038 210.806 594.251 211.513L594.13 212.16Z"
          fill="#DD9128"
        />
        <path
          d="M648.482 199.672C649.692 199.323 650.893 199.084 651.975 198.949C644.698 188.778 644.02 188.148 643.951 188.066C643.951 188.066 643.949 188.069 643.948 188.077C643.859 188.42 641.895 195.95 641.895 199.344C641.895 199.946 641.863 200.891 641.992 201.664C643.425 201.017 646.166 200.335 648.482 199.672ZM615.37 179.387C615.455 179.247 618.124 169.913 618.124 169.913L623.889 167.596C623.811 167.72 621.067 172.08 628.082 172.027C630.802 172.008 631.939 169.546 632.719 167.711L641.321 169.743C641.321 169.743 646.074 170.625 647.167 172.43C655.182 185.697 659.913 197.923 660.887 201.804C661.042 202.425 660.716 203.428 660.201 203.811C658.758 204.887 657.671 205.161 653.079 205.996C652.305 206.136 651.559 206.243 650.846 206.322C647.568 206.686 644.957 206.465 643.278 206.193C644.808 211.252 647.06 216.57 647.06 216.57C647.06 216.57 646.377 217.042 644.532 217.535C644.091 217.655 626.397 220.545 625.814 220.66C623.583 221.1 615.775 216.757 615.775 216.757C615.775 216.757 609.387 217.389 607.502 212.5C603.577 202.321 613.433 182.7 615.37 179.387Z"
          fill="#F8A531"
        />
        <path
          d="M634.971 206.566C635.943 210.193 637.991 215.168 641.267 216.779C642.035 217.157 643.198 217.395 644.531 217.535C646.375 217.042 647.059 216.57 647.059 216.57C647.059 216.57 641.52 205.345 641.894 199.344C642.105 195.956 643.857 188.421 643.946 188.077C643.517 187.26 642.938 186.511 642.259 186.541C640.781 186.608 633.998 202.937 634.971 206.566Z"
          fill="#F8A531"
        />
        <path
          d="M647.06 216.573C647.681 217.874 655.878 232.62 642.271 236.551C628.665 240.481 599.237 239.418 599.237 239.418C599.237 239.418 596.116 251.753 592.266 257.175C586.084 265.88 575.064 275.645 575.064 275.645H562.446C562.446 275.645 584.271 228.367 588.706 225.143C591.95 222.783 608.269 218.609 617.29 217.209C619.488 216.867 632.788 216.464 636.347 216.324C641.926 216.105 646.715 215.85 647.06 216.573Z"
          fill="#223A43"
        />
        <path
          d="M617.291 217.209C617.291 217.209 589.52 208.723 584.218 211.77C574.795 217.185 590.921 265.34 590.921 265.34L600.214 263.85C600.214 263.85 601.953 250.827 600.565 242.08C599.759 237.002 596.827 223.895 596.827 223.895C596.827 223.895 603.922 221.014 606.37 220.304C609.445 219.414 617.291 217.209 617.291 217.209Z"
          fill="#17282F"
        />
        <path
          d="M638.291 203.05C638.346 203.041 638.402 203.035 638.458 203.033C639.232 202.893 640.682 202.165 640.682 202.165L641.148 204.704C641.143 204.769 641.448 205.567 641.111 206.021C638.969 208.893 638.188 208.508 638.188 208.508L636.825 207.303C636.788 207.239 636.758 207.171 636.734 207.1C636.661 206.886 636.646 206.651 636.705 206.418L637.326 203.947C637.446 203.471 637.836 203.132 638.291 203.05Z"
          fill="#B28B67"
        />
        <path
          d="M593.634 234.444C594.247 234.359 594.916 234.063 595.562 233.775C596.163 233.509 596.784 233.233 597.313 233.156C604.097 232.167 610.247 230.955 615.672 229.887C617.095 229.607 618.488 229.333 619.855 229.068C632.202 226.692 639.208 225.389 639.277 225.379C639.208 225.387 632.151 226.271 619.787 228.653C618.419 228.917 617.026 229.191 615.602 229.472C610.182 230.538 604.039 231.748 597.265 232.735C596.675 232.822 596.024 233.112 595.395 233.391C594.777 233.665 594.14 233.948 593.59 234.024C591.804 234.271 591.847 234.692 593.634 234.444Z"
          fill="#17282F"
        />
        <path
          d="M604.721 192.819C604.715 192.833 604.708 192.852 604.698 192.881L604.721 192.819Z"
          fill="#333382"
        />
        <path
          d="M572.74 272.369L572.857 272.4C577.038 264.937 581.541 255.929 585.72 246.359C586.359 244.897 587.006 243.388 587.645 241.876C588.349 240.21 589.05 238.516 589.732 236.845C589.915 236.396 590.002 235.853 590.066 235.3V235.298C589.944 235.119 589.825 234.936 589.706 234.752C589.704 234.766 589.704 234.78 589.702 234.794C589.629 235.465 589.556 236.16 589.347 236.673C588.667 238.343 587.966 240.033 587.262 241.698C586.623 243.209 585.976 244.716 585.339 246.178C581.166 255.734 576.915 264.917 572.74 272.369Z"
          fill="#17282F"
        />
        <path
          d="M604.698 192.881L604.721 192.819C604.715 192.833 604.708 192.852 604.698 192.881Z"
          fill="#333382"
        />
        <path
          d="M658.625 198.991C658.625 198.991 654.727 197.697 646.563 200.218C639.938 202.262 640.683 202.166 640.683 202.166L641.271 205.627C641.271 205.627 649.201 208.293 657.793 205.003C657.793 205.003 661.661 204.147 660.788 201.423C659.914 198.696 658.625 198.991 658.625 198.991Z"
          fill="#DD9128"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M567.702 286.921L568.902 282.072C569.03 281.558 568.654 281.054 568.133 280.961C564.673 280.341 560.877 277.64 560.877 277.64C555.868 279.006 549.652 278.563 546.548 278.205C545.519 278.086 544.582 278.876 544.571 279.912L544.563 280.686L558.472 285.145L566.585 287.745L567.702 286.921Z"
          fill="#17282F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M602.407 272.042L599.495 267.984C599.186 267.553 598.559 267.512 598.15 267.848C595.434 270.08 590.916 271.218 590.916 271.218C588.708 275.917 584.339 280.361 582.053 282.491C581.296 283.198 581.289 284.423 582.07 285.103L582.655 285.612L595.069 277.916L602.31 273.427L602.407 272.042Z"
          fill="#17282F"
        />
        <path
          d="M660.105 280.971C659.476 280.971 658.919 280.567 658.723 279.97L649.14 250.663C648.466 248.601 646.542 247.205 644.372 247.205H623.63C621.503 247.205 619.608 248.545 618.899 250.549L608.482 280.002C608.277 280.583 607.728 280.971 607.113 280.971C606.135 280.971 605.441 280.066 605.686 279.144L615.506 242.304H652.671L661.516 279.179C661.735 280.093 661.044 280.971 660.105 280.971Z"
          fill="#63352C"
        />
        <path
          d="M608.276 248.322H655.025C656.178 248.322 657.248 247.714 657.837 246.721L677.871 212.987C677.871 212.987 654.946 191.365 650 189C645.053 186.636 645.765 203.675 650 224C635.924 221.527 620.782 221.575 609.534 227.026C603.429 230.768 604.237 240.739 605.05 245.593C605.313 247.169 606.678 248.322 608.276 248.322Z"
          fill="#A7A6B4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4896_54120">
        <rect
          x="535"
          y="178"
          width="127"
          height="134"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_4896_54120">
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CrossLegged"
};
</script>
