<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="346.5" cy="157.5" r="62.5" fill="#8AAFAF" />
    <g clip-path="url(#clip0_4896_54080)">
      <rect x="282" y="86" width="125" height="125" rx="60.6796" fill="white" />
      <rect
        x="282"
        y="86"
        width="125"
        height="125"
        rx="60.6796"
        fill="#C6DDDD"
      />
      <path
        d="M339.232 172.66H343.882V168.935H339.232V172.66Z"
        fill="#A7A6B4"
      />
      <path
        d="M367.471 155.263V207.312"
        stroke="#A7A6B4"
        stroke-width="2.40065"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M391.721 176.797V228.848"
        stroke="#A7A6B4"
        stroke-width="2.40065"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M342.248 168.843V220.895"
        stroke="#3E79AE"
        stroke-width="2.40065"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M341.87 169.465C338.179 171.862 338.706 174.449 343.04 175.214L385.311 182.667C389.645 183.432 396.21 182.095 399.901 179.698L417.475 168.279C421.166 165.882 420.639 163.295 416.306 162.531L374.033 155.076C369.699 154.312 363.133 155.648 359.442 158.046L341.87 169.465Z"
        fill="#A7A6B4"
      />
      <path
        d="M341.87 165.738C338.179 168.136 338.706 170.724 343.04 171.488L385.311 178.941C389.645 179.705 396.21 178.369 399.901 175.971L417.475 164.553C421.166 162.156 420.639 159.569 416.306 158.804L374.033 151.35C369.699 150.585 363.133 151.922 359.442 154.32L341.87 165.738Z"
        fill="#B9C0D6"
      />
      <path
        d="M316.667 213.405C316.312 216.186 316.036 219.382 314.882 222.527C313.707 225.734 311.05 228.602 314.882 230.292C318.371 231.83 320.29 235.654 324.551 235.973C325.972 236.052 328.672 236.052 329.667 234.543C330.59 233.271 328.175 232.636 327.393 232.079C325.759 230.965 324.197 228.821 323.13 227.072C322.42 225.802 322.563 224.29 322.775 222.862C323.343 219.366 324.89 210.861 325.885 207.524"
        fill="#F57B6D"
      />
      <path
        d="M348.382 225.28C346.609 222.902 347.844 217.805 347.989 214.941C349.999 200.268 354.513 178.016 353.014 174.289C351.335 170.109 350.159 150.479 346.915 143.051L342.811 128.479L316.99 129.134L317.38 130.663C329 147 322.501 135 330.238 156.391C334.096 164.492 338.681 174.876 339.183 178.924C339.756 183.535 337.629 188.102 337.319 194.043C336.774 204.48 341.17 211.275 339.242 220.736C339.238 220.755 339.238 220.773 339.235 220.792C339.088 221.324 338.937 221.851 338.791 222.418C338.152 224.721 336.163 228.617 339.359 229.49C340.851 229.809 342.343 229.809 343.836 230.444C345.399 231 346.811 231.977 348.239 232.907C350.687 234.501 353.417 234.504 354.698 233.633C357.035 232.046 349.016 226.211 348.382 225.28Z"
        fill="#17282F"
      />
      <path
        d="M343.81 128.478L317.99 129.133L314.499 131.999C310.594 144.231 316.999 156 318.896 171.215C319.473 175.841 319.752 178.132 318.989 182.999C317.1 195.044 322.613 206.159 322.037 218.356C321.869 221.952 328.747 223.084 329.444 219.02C331.653 206.174 332.702 193.068 333.999 180C334.309 173.239 335.509 166.075 337.499 157C339.942 145.857 340.999 141.5 343.81 128.478Z"
        fill="#223A43"
      />
      <path
        d="M336.571 73.9373C337.12 81.3889 344.441 89.9369 346.55 95.848C348.011 99.9356 346.341 101.67 345.028 104.773C344.342 106.391 345.203 108.138 346.566 109.247C351.546 113.296 354.443 120.076 355.5 127C356.474 133.38 355.841 141.979 349 144.499C339.5 147.999 336.571 147 311.31 143.206C311.127 134.687 314.31 124.298 315.619 118.62C318.24 107.259 315.385 87.3505 318.315 81.489C319.416 79.2884 323.781 81.0576 321.377 73.8493C321.272 73.5796 322.03 73.5652 322.123 73.7645C323.393 76.4796 322.934 78.6706 325.403 81.1032C329.686 85.3236 337.336 83.0758 336.042 73.6981C336.087 73.1867 336.592 73.5772 336.571 73.9373Z"
        fill="#F8A531"
      />
      <path
        d="M321.686 77.3947C322.241 84.8149 318.54 109.242 318.223 111.374C317.907 113.505 320.777 119.266 322.705 126.448C324.434 132.895 325.909 136.378 327.898 141.788C328.82 143.863 331.43 145.987 333.213 147.049C334.003 147.646 335.093 149.439 333.164 149.053C332.338 148.942 330.968 147.618 330.216 147.17C329.995 148.182 329.624 149.232 329.551 150.206C329.536 151.087 329.857 152.041 329.41 152.792C328.459 154.386 325.646 153.346 324.41 152.863C321.971 152.046 322.115 150.097 322.765 147.942C323.191 146.797 324.514 144.153 323.535 142.807C321.482 139.423 319.261 135.163 317.552 131.92C315.425 128.022 313.536 124.064 311.408 120.167C310.449 118.382 309.847 116.005 309.363 114.095C307.203 106.879 308.289 95.879 309.461 88.2838C309.742 86.4651 310.669 83.5301 311.158 81.7038C312.286 77.4857 316.874 73.8427 321.521 73.9068"
        fill="#B28B67"
      />
    </g>
    <defs>
      <clipPath id="clip0_4896_54080">
        <rect
          x="282"
          y="86"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CirculationStanding"
};
</script>
