import { Theme } from "@/components/assessment/desk/steps.js";
import QuestionSchema from "./../question-schema.json";

/*
Transform question schema used by the form to one which provides easier look-ups for the result page
It will look similar to the following:
{
  health: {
    questionName: questionObject
    ...
  },
  pain: {
    questionName: questionObject
    ...
  },
  setup: {
    workhabits: {
      questionName: questionObject
      ...
    },
    seating: {
      questionName: questionObject
      ...
    },
    equipment: {
      questionName: questionObject
      ...
    }
  }
}
*/

export const questionsByTheme = Object.entries(QuestionSchema).reduce(
  (acc, [theme, item]) => {
    acc[theme] = {};

    switch (theme) {
      case Theme.HEALTH:
        item.pages.forEach(page => {
          page.questions.forEach(question => {
            acc[theme][question.name] = question;
          });
        });
        break;

      case Theme.PAIN:
        item.pages.forEach(page => {
          Object.values(page.questions).forEach(bodyPart => {
            bodyPart.forEach(question => {
              acc[theme][question.name] = question;
            });
          });
        });
        break;

      case Theme.SETUP:
        item.pages.forEach(page => {
          acc[theme][page.name] = {};
          page.questions.forEach(question => {
            acc[theme][page.name][question.name] = question;
          });
        });
        break;
    }
    return acc;
  },
  {}
);
