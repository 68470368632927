import BaseScoreHelper from "./base-score-helper";
import SchemaService from "@/services/question-schema/schema-service.js";
import { withErrorHandling } from "@/components/assessment/desk/results-helpers/error-handling.js";
import EnvironmentScoreHelper from "@/components/assessment/desk/results-helpers/environment-score-helper.js";

export default class HealthScoreHelper extends BaseScoreHelper {
  static maxHealthScore = 5;

  static calculateHealthScore(results) {
    return HealthScoreHelper.getHealthScoreFromResult(
      this.questionsByTheme.health,
      results.health
    );
  }

  static getHealthScoreFromResult(healthQuestions, resultsObject) {
    //if questions not answered give full marks
    if (!resultsObject?.smoker || resultsObject.exercise == null) {
      return 0;
    }

    return (
      SchemaService.getScoreFromResult(
        healthQuestions.smoker,
        resultsObject.smoker
      ) + HealthScoreHelper.getExerciseScore(resultsObject.exercise)
    );
  }

  static getExerciseScore = withErrorHandling(function (result) {
    if (result === 0) return 3;
    if (result <= 2) return 1;
    return 0;
  }, 0);

  static getHealthPercentage(results) {
    return this.getPercentageScore(
      this.calculateHealthScore(results),
      this.maxHealthScore
    );
  }
}

HealthScoreHelper.calculateHealthScore = withErrorHandling(
  HealthScoreHelper.calculateHealthScore,
  0
);

HealthScoreHelper.getHealthScoreFromResult = withErrorHandling(
  HealthScoreHelper.getHealthScoreFromResult,
  0
);

HealthScoreHelper.getExerciseScore = withErrorHandling(
  HealthScoreHelper.getExerciseScore,
  0
);

HealthScoreHelper.getHealthPercentage = withErrorHandling(
  HealthScoreHelper.getHealthPercentage,
  0
);
