<template>
  <report-section
    :section-number="sectionNumber"
    :background-color="backgroundColor"
  >
    <template #title>{{
      $t("pregnancyAssessment.report.content.postnatal.title")
    }}</template>
    <template #content>
      <div class="vfl-card-light pa-8 pb-12 align-start d-md-flex">
        <img
          src="/images/pregnancy-assessment/report/postnatal-recovery.svg"
          alt=""
        />
        <div>
          <p>
            {{ $t("pregnancyAssessment.report.content.postnatal.text.intro") }}
          </p>
          <ul>
            <li
              v-for="(text, index) in $t(
                'pregnancyAssessment.report.content.postnatal.text.bulletPoints'
              )"
              :key="`postnatal-bulletpoint-${index}`"
              v-html="text"
              class="mb-4"
            ></li>
          </ul>
          <did-not-know-that-fact-button
            class="mt-8"
            :fact="$t(`pregnancyAssessment.report.content.postnatal.title`)"
            :assessmentType="$options.assessmentTypes.pregnancyAssessment"
          />
        </div>
      </div>
    </template>
  </report-section>
</template>

<script>
import ReportSection from "@/components/assessment/common/report/ReportSection.vue";
import DidNotKnowThatFactButton from "@/components/assessment/common/report/DidNotKnowThatFactButton.vue";
import { assessmentTypes } from "@/constants/constants.js";

export default {
  name: "PregnancyReportPostnatal",
  components: {
    DidNotKnowThatFactButton,
    ReportSection
  },
  data() {
    return {};
  },
  assessmentTypes: assessmentTypes,
  props: {
    results: {
      type: Object,
      required: true
    },
    assessmentId: String,
    sectionNumber: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.vfl-card-light {
  background: white;
  gap: 1rem;
}
</style>
