<template>
  <v-main :class="{ 'pb-0': isFullscreen }">
    <page-header :title="pageTitle" v-if="!showTopBar && !isStandalonePage" />
    <div
      class="page-wrapper"
      :class="[
        { 'is-fullscreen': isFullscreen },
        { 'is-full-width-page': isStandalonePage || isAssessmentPage },
        paddingClass
      ]"
    >
      <router-view />
    </div>
  </v-main>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "MainContent",
  components: { PageHeader },
  props: {
    showTopBar: Boolean
  },
  computed: {
    ...mapGetters("layout", ["pageTitle", "isFullscreen"]),
    isAssessmentPage() {
      return this.$route.meta?.assessmentRoute;
    },
    isStandalonePage() {
      return this.$route.meta?.standalonePage;
    },
    paddingClass() {
      if (this.isStandalonePage) return "";
      return this.$vuetify.breakpoint.xs ? "px-4" : "px-8";
    }
  }
};
</script>

<style scoped lang="scss">
.v-main {
  transition: none !important;
}

.page-wrapper {
  container-type: inline-size;
  container-name: page-container;
  margin: 0 auto;
  max-width: 1600px;
  min-height: var(--app-content-height);
  position: relative;
  width: 100%;

  &.is-full-width-page {
    max-width: 100%;
  }

  &.is-fullscreen {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 100%;
    min-height: calc(100vh - var(--topbar-height));
  }

  @-moz-document url-prefix() {
    container-type: initial;
  }
}
</style>
