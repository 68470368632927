<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4896_54110)">
      <circle cx="459.5" cy="443.5" r="62.5" fill="#8AAFAF" />
      <g clip-path="url(#clip1_4896_54110)">
        <rect
          x="395"
          y="372"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
        <rect
          x="395"
          y="372"
          width="125"
          height="125"
          rx="60.6796"
          fill="#C6DDDD"
        />
        <path
          d="M469.804 395.09C469.804 395.177 469.812 395.267 469.812 395.354C469.812 395.566 469.804 395.78 469.794 395.992L469.792 395.997C468.72 397.478 466.863 398.293 465.033 398.301C463.403 398.311 461.81 397.73 460.454 396.817C460.441 396.047 460.436 395.279 460.436 394.509C460.436 393.352 460.449 392.193 460.414 391.034C460.411 390.949 460.409 390.865 460.407 390.777C460.197 390.199 459.955 389.633 459.684 389.082C459.748 389.025 459.816 388.968 459.886 388.913C461.032 387.993 462.573 387.34 464.068 387.527C464.527 387.584 464.966 387.729 465.395 387.896C465.818 388.063 466.242 388.24 466.636 388.466C467.309 388.85 467.868 389.399 468.311 390.029C469.333 391.475 469.842 395.075 469.804 395.09Z"
          fill="#BA5A49"
        />
        <path
          d="M469.792 395.998C469.729 397.117 469.52 398.231 469.298 399.333C468.219 404.743 467.608 409.808 467.543 415.3C467.411 415.352 467.112 415.462 466.79 415.569C466.459 415.681 466.107 415.791 465.893 415.836C465.846 415.848 465.808 415.853 465.776 415.858C465.477 415.895 465.175 415.93 464.873 415.92C464.735 415.915 464.444 415.918 464.077 415.922C463.424 415.928 462.53 415.934 461.822 415.89C461.298 415.858 460.88 415.801 460.73 415.696C460.621 415.454 460.513 415.21 460.411 414.963C460.354 414.831 460.299 414.696 460.244 414.564C460.306 414.507 460.364 414.447 460.421 414.385L460.464 414.362C460.745 414.896 461.194 415.34 461.732 415.609L461.822 415.596C461.092 410.09 460.65 404.543 460.496 398.992C460.491 398.745 460.483 398.501 460.478 398.254V398.251C460.468 397.775 460.459 397.297 460.454 396.818C461.81 397.73 463.403 398.311 465.033 398.301C466.863 398.294 468.72 397.479 469.792 395.998Z"
          fill="#BA5A49"
        />
        <path
          d="M460.094 389.986C460.028 389.831 459.961 389.677 459.891 389.524C459.891 389.524 459.891 389.523 459.891 389.523C459.961 389.676 460.029 389.831 460.094 389.986C460.094 389.986 460.094 389.986 460.094 389.986Z"
          fill="#BE9196"
        />
        <path
          d="M450.447 402.379C450.916 405.375 451.457 408.454 450.597 411.346C450.47 411.091 450.338 410.837 450.201 410.585C449.906 410.042 449.587 409.494 449.498 408.883C449.408 408.287 449.545 407.681 449.67 407.093C450.001 405.535 450.26 403.962 450.447 402.379Z"
          fill="#BA5A49"
        />
        <path
          d="M460.412 391.035C459.557 392.131 458.403 392.989 457.109 393.5C456.645 393.687 456.162 393.827 455.668 393.916C453.766 394.265 451.739 393.874 450.102 392.844C450.179 392.346 450.279 391.85 450.403 391.356C450.822 389.683 451.56 388.013 452.899 386.921C454.235 385.83 456.271 385.471 457.705 386.433C458.42 386.914 458.916 387.659 459.332 388.415C459.455 388.634 459.572 388.858 459.681 389.083C459.953 389.634 460.195 390.199 460.404 390.778C460.407 390.865 460.409 390.95 460.412 391.035Z"
          fill="#CF6F58"
        />
        <path
          d="M450.104 392.844C449.645 395.746 449.897 398.747 450.338 401.664C450.363 401.823 450.386 401.983 450.413 402.143C449.545 399.735 448.962 397.222 448.678 394.677C448.404 392.204 448.446 389.561 449.712 387.42C450.622 385.879 452.143 384.71 453.865 384.227C455.543 383.756 457.512 383.992 458.754 385.211C459.935 386.371 460.247 388.14 460.357 389.791C460.379 390.12 460.394 390.449 460.406 390.778C460.11 389.963 459.754 389.172 459.334 388.415C458.918 387.659 458.422 386.914 457.707 386.433C456.274 385.471 454.237 385.83 452.901 386.921C451.562 388.013 450.824 389.683 450.405 391.356C450.281 391.85 450.181 392.346 450.104 392.844Z"
          fill="#BA5A49"
        />
        <path
          d="M476.452 481.665C478.18 487.683 479.695 493.713 479.598 499.91H455.61C454.899 495.206 455.455 490.413 455.889 485.634C455.921 485.295 455.951 484.956 455.981 484.619C461.381 481.361 470.3 480.279 476.452 481.665Z"
          fill="#CF6F58"
        />
        <path
          d="M456.143 482.49C456.152 482.363 456.16 482.236 456.167 482.109C461.19 479.04 469.569 477.826 475.382 478.761L475.591 478.699C475.88 479.688 476.17 480.675 476.451 481.665C470.299 480.279 461.38 481.361 455.98 484.619C456.04 483.909 456.098 483.198 456.143 482.49Z"
          fill="#D5959D"
        />
        <path
          d="M475.591 478.699L475.382 478.762C469.568 477.827 461.19 479.041 456.167 482.109C456.344 479.235 456.351 476.373 455.89 473.547L455.91 473.544C455.267 469.593 456.404 465.345 455.92 461.292C455.484 457.655 453.759 454.3 453.023 450.712C452.348 447.414 452.525 444.009 452.704 440.649C452.906 436.85 453.108 433.048 453.313 429.249C453.395 427.666 453.48 426.086 453.564 424.503C453.662 422.666 453.761 420.821 453.604 418.989C453.642 418.956 453.682 418.919 453.719 418.882C454.694 417.909 455.155 416.523 455.564 415.192L455.579 415.197C455.805 415.374 456.1 415.434 456.384 415.491C456.673 415.549 456.965 415.606 457.261 415.629C458.43 415.721 459.629 415.25 460.42 414.385L460.462 414.362C460.744 414.896 461.192 415.339 461.731 415.609L461.821 415.596C461.833 415.696 461.846 415.793 461.858 415.893C461.88 416.057 461.903 416.224 461.928 416.389C461.998 416.917 462.072 417.446 462.15 417.972C462.157 418.012 462.162 418.049 462.167 418.084C462.671 420.647 463.177 423.207 463.673 425.769C463.673 425.772 463.723 426.016 463.792 426.368C463.907 426.961 464.027 427.552 464.151 428.145C464.236 428.561 464.298 428.863 464.301 428.863C466.098 437.381 468.404 445.921 469.538 454.516C470.146 459.123 470.645 463.752 471.6 468.299C472.333 471.792 474.581 475.247 475.591 478.699Z"
          fill="#C69D78"
        />
        <path
          d="M465.858 419.303L465.87 419.306C465.374 421.303 464.434 426.809 464.302 428.863C464.3 428.863 464.238 428.562 464.153 428.145C464.043 427.607 463.896 426.877 463.794 426.368C463.724 426.016 463.674 425.772 463.674 425.77C463.178 423.207 462.672 420.647 462.168 418.084C462.164 418.049 462.159 418.012 462.151 417.972C462.074 417.446 461.999 416.918 461.929 416.389C461.904 416.225 461.882 416.058 461.859 415.893C462.964 415.958 464.494 415.906 464.873 415.921C465.175 415.931 465.476 415.896 465.776 415.858C465.808 415.853 465.845 415.848 465.893 415.836C466.107 415.791 466.459 415.681 466.79 415.569C466.471 416.788 465.973 418.797 465.858 419.303Z"
          fill="#B28B67"
        />
        <path
          d="M461.822 415.595L461.732 415.608C461.194 415.339 460.745 414.895 460.463 414.361L460.421 414.384C460.364 414.446 460.306 414.506 460.244 414.563C459.459 415.314 458.347 415.712 457.263 415.628C456.966 415.605 456.674 415.548 456.385 415.491C456.101 415.433 455.807 415.373 455.58 415.196L455.565 415.191C455.156 416.523 454.695 417.909 453.72 418.881C453.593 418.038 453.247 417.233 452.903 416.448C452.633 415.835 452.367 415.221 452.097 414.611C451.619 413.511 451.138 412.414 450.597 411.345C451.457 408.453 450.916 405.375 450.447 402.378C450.452 402.344 450.457 402.309 450.46 402.274C450.442 402.231 450.425 402.186 450.412 402.142C450.385 401.982 450.362 401.823 450.337 401.663C449.896 398.746 449.644 395.745 450.103 392.843C451.741 393.873 453.768 394.264 455.67 393.915C456.163 393.826 456.647 393.686 457.11 393.499C458.404 392.988 459.558 392.13 460.413 391.034C460.448 392.193 460.436 393.352 460.436 394.509C460.436 395.279 460.441 396.047 460.453 396.817C460.458 397.296 460.468 397.774 460.478 398.25V398.253C460.483 398.5 460.491 398.744 460.496 398.991C460.65 404.542 461.091 410.089 461.822 415.595Z"
          fill="#CF6F58"
        />
        <path
          d="M456.17 482.109C456.162 482.236 456.155 482.363 456.145 482.49C452.211 479.97 444.915 479.26 440.438 480.598C442.09 475.907 445.211 471.28 445.887 466.369C446.473 462.109 446.131 457.779 446.378 453.484C446.852 445.15 450.511 437.131 453.315 429.249C453.111 433.048 452.909 436.849 452.707 440.648C452.528 444.009 452.351 447.414 453.026 450.712C453.762 454.299 455.487 457.654 455.923 461.291C456.406 465.345 455.27 469.592 455.913 473.543L455.893 473.546C456.354 476.373 456.347 479.235 456.17 482.109Z"
          fill="#B28B67"
        />
        <path
          d="M455.982 484.619C455.952 484.955 455.922 485.294 455.89 485.633L455.8 485.626C454.539 484.437 452.881 483.734 451.188 483.362C449.496 482.991 445.507 482.928 443.775 482.881C442.394 482.841 441.008 482.809 439.637 482.938C439.893 482.156 440.163 481.375 440.437 480.598C444.914 479.259 452.21 479.969 456.144 482.49C456.099 483.198 456.042 483.908 455.982 484.619Z"
          fill="#D5959D"
        />
        <path
          d="M455.8 485.626L455.889 485.634C455.456 490.413 454.9 495.206 455.61 499.91H437.006C436.929 495.024 437.562 490.123 438.881 485.417C439.115 484.587 439.369 483.762 439.636 482.939C441.007 482.809 442.393 482.842 443.774 482.882C445.507 482.929 449.495 482.991 451.188 483.363C452.881 483.734 454.538 484.437 455.8 485.626Z"
          fill="#BA5A49"
        />
        <path
          d="M459.325 464.783C459.153 464.783 458.981 464.604 459.096 464.412C460.097 462.734 461.984 461.716 463.912 461.716C464.261 461.716 464.611 461.749 464.958 461.818C465.254 461.877 465.153 462.305 464.88 462.305C464.863 462.305 464.844 462.303 464.826 462.299C464.519 462.238 464.209 462.209 463.899 462.209C462.152 462.209 460.43 463.15 459.526 464.664C459.475 464.748 459.4 464.783 459.325 464.783Z"
          fill="#CC8E94"
        />
        <path
          d="M458.395 392.85C458.403 392.829 458.411 392.808 458.419 392.788C458.466 392.671 458.569 392.621 458.669 392.621C458.69 392.621 458.71 392.624 458.73 392.627C458.62 392.704 458.508 392.778 458.395 392.85Z"
          fill="#BE9196"
        />
        <path
          d="M450.15 392.555C450.174 392.419 450.199 392.282 450.226 392.147C450.228 392.218 450.231 392.289 450.236 392.36C450.241 392.45 450.205 392.516 450.15 392.555Z"
          fill="#BE9196"
        />
      </g>
      <path
        d="M407.994 432.454L420.115 434.832L417.599 439.917L433.602 440.756L422.662 438.177L425.372 432.764L409.776 429.048L407.994 432.454Z"
        fill="#F2575D"
      />
      <path
        d="M427.098 417.372L433.621 424.554L429.652 426.579L439.343 434.611L433.658 427.856L437.904 425.716L429.818 416.061L427.098 417.372Z"
        fill="#F2575D"
      />
      <path
        d="M414.158 452.045L422.461 450.225L422.266 454.124L432.598 450.27L424.985 451.639L425.213 447.48L414.35 449.407L414.158 452.045Z"
        fill="#F2575D"
      />
      <path
        d="M508.317 431.454L496.196 433.832L498.711 438.917L482.709 439.756L493.649 437.177L490.939 431.764L506.534 428.048L508.317 431.454Z"
        fill="#F2575D"
      />
      <path
        d="M489.213 416.372L482.689 423.554L486.658 425.579L476.967 433.611L482.653 426.856L478.407 424.716L486.493 415.061L489.213 416.372Z"
        fill="#F2575D"
      />
      <path
        d="M502.153 451.045L493.85 449.225L494.044 453.124L483.713 449.27L491.326 450.639L491.098 446.48L501.961 448.407L502.153 451.045Z"
        fill="#F2575D"
      />
    </g>
    <defs>
      <clipPath id="clip0_4896_54110">
        <rect
          x="395"
          y="372"
          width="127"
          height="134"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_4896_54110">
        <rect
          x="395"
          y="372"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SignificantSwelling"
};
</script>
