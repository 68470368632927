import cards from "@/assets/json/DeskAssessment/RecommendationCards.json";
import becsmaCards from "@/assets/json/DeskAssessment/CustomisedRecommendations/RecommendationCards_beCsMA.json";
import DeskAssessmentResultsUtil from "@/services/deskassessment/desk-assessment-results-util.js";
import {
  DeskSetupScoreHelper,
  EquipmentScoreHelper,
  PainHelper,
  WorkHabitsScoreHelper,
  EnvironmentScoreHelper
} from "@/components/assessment/desk/results-helpers/results-helper.js";
import i18n from "@/plugins/i18n.js";
import SummaryService from "@/services/deskassessment/desk-assessment-summary.js";
import router from "@/router";
import { viewingOwnResults } from "@/services/privacy-service.js";
import { LinkTypes } from "@/services/recommendations/link-types.js";
import * as WebcamValues from "@/services/deskassessment/webcam-values.js";
import { WorkSpacePriority } from "@/constants/constants.js";
import {
  ElevationGeometryMapper,
  getDimensionsForWorkArea
} from "@/services/deskassessment/elevation-planner-geometry.js";
import { routes } from "@/router/route-constants.js";
import store from "@/store";
import conditionAnswers from "@/components/assessment/pre-existing-conditions/answers.json";

const isNotUserDashboard = route => route.name !== routes.WELLNESS;
const isAssessmentRoute = route => {
  return (
    route.name === routes.DESK_ASSESSMENT ||
    route.name === routes.BURNOUT_ASSESSMENT
  );
};

const isSmoker = results => results.health.smoker === 1;
const isHighScreenAngle = results => results.webcam.screenHeightAngle > 5;
const hasBeanBag = results =>
  !!results.setup.deskItems?.find(x => x.id === "beanBag");
const atDesk = results =>
  results.setup.workarea === 1 || results.setup.workarea === 4;
const feetNotSupported = results => results.setup.feetsupported === 2;
const noPlants = results => {
  const noPlants = results.setup.plants === 2;
  if (store.getters.nDiNbS) {
    return noPlants && !atOffice(results);
  }
  return noPlants;
};
const noBreaks = results => results.setup.breaks === 2;
const noActiveBreaks = results =>
  results.setup.breaks === 1 && results.setup.breakActivity === 2;
const noLunchAwayFromDesk = results => results.setup.lunch >= 2;
const noWaterBottle = results => results.setup.water === 2;
const noNaturalLight = results =>
  results.setup.naturallight === 2 || results.setup.brightnesslevel < 0;
const noBreaksLongHours = results =>
  results.setup.breaks === 2 && results.setup.hours >= 9;
const hasClutter = results => results.setup.clutter === 1;
const hasPoorSetup = results => {
  const utilityService = getUtilityService("equipment");

  return (
    utilityService.getVisualDeskPlannerRating(results.setup).rating ===
    i18n.t("deskAssessment.setup.sims.rating.needsImprovement")
  );
};
const hasKneePain = results => {
  return (
    results.pain.areas.leftknee !== undefined ||
    results.pain.areas.rightknee !== undefined
  );
};

const notPerfectScreenDistance = results => {
  const utilityService = getUtilityService("setup");

  return utilityService.getScreenDistanceScore(results) != 0;
};
const isPregnant = results => results.health.pregnancy === 1;
const pregnantAndCanViewResults = results => {
  return results.health.pregnancy === 1 && viewingOwnResults(router.app._route);
};

function showChairCard(results) {
  return (
    !hasChair(results) &&
    atHomeOrOffice(results) &&
    !hasSpecialisedChair(results)
  );
}

function showFootSupport(results) {
  return (
    feetNotSupported(results) &&
    hasChair(results) &&
    atDesk(results) &&
    !hasSpecialisedChair(results)
  );
}

function showStandingDesk(results) {
  const hasMobilityIssue = hasNonPainCondition(
    results,
    conditionAnswers.setup.nonPainCondition.mobilityImpairment
  );
  const hasWheelchair = hasItem(results, "wheelchair");
  if (hasMobilityIssue || hasWheelchair) {
    return false;
  }

  if (store.getters.disableStandingDesk) {
    return false;
  }

  if (store.getters.generousStandingDeskLogic) {
    return generousStandingDeskLogic(results);
  }

  if (store.getters.officeOnlyStandingDeskLogic) {
    return strictStandingDeskLogic(results) && atOffice(results);
  }

  return strictStandingDeskLogic(results);
}

function generousStandingDeskLogic(results) {
  var hasLongHours = results.setup.hours >= 9;
  var noStandingDesk = results.setup.standingdesk === 2;
  var takesABreak = results.setup.breaks === 1;
  var noBreakActivity = results.setup.breakActivity === 2;
  var painAreas = ["lowerback", "midback", "gluteals"];
  var relevantPainArea = hasPainAreas(results, painAreas);
  var noBreaksOrBreaksWithNoActivity =
    !takesABreak || (takesABreak && noBreakActivity);

  return (
    noStandingDesk &&
    hasLongHours &&
    noBreaksOrBreaksWithNoActivity &&
    relevantPainArea
  );
}

function strictStandingDeskLogic(results) {
  var hasLongHours = results.setup.hours >= 9;
  var noStandingDesk = results.setup.standingdesk === 2;
  var takesABreak = results.setup.breaks === 1;
  var activeInBreaks = results.setup.breakActivity === 1;
  var painAreas = ["lowerback", "midback", "gluteals"];
  var relevantPainArea = hasPainAreas(results, painAreas);
  var painGettingWorse = painHasGotWorse(results, painAreas);
  var chronicPain = durationLongerThan3Months(results, painAreas);
  var wellSetUp = !results.webcam?.pictureTaken || hasGoodWebcamResult(results);

  return (
    noStandingDesk &&
    hasLongHours &&
    takesABreak &&
    activeInBreaks &&
    relevantPainArea &&
    painGettingWorse &&
    chronicPain &&
    wellSetUp
  );
}

function needsWristRest(results) {
  var hasWristRest = results.setup.wristrest === 1;
  var hasWristPain = hasPainAreas(results, ["wrist"]);
  var hasRegularMouseAndKeyboard =
    hasRegularMouse(results) && hasRegularKeyboard(results);
  var hasErgonomicEquipment =
    hasErgonomicMouse(results) || hasErgonomicKeyboard(results);

  return (
    hasWristPain &&
    !hasWristRest &&
    hasRegularMouseAndKeyboard &&
    !hasErgonomicEquipment
  );
}

const hasPainAreas = (results, areas) => {
  var resultAreas = Object.keys(results.pain.areas);
  return resultAreas.some(x => areas.some(a => x.includes(a)));
};

const getPainAreas = (results, areas) => {
  return Object.entries(results.pain.areas)
    .filter(x => areas.some(a => x[0].includes(a)))
    .map(x => x[1]);
};

function durationLongerThan3Months(results, areas = null) {
  return Object.keys(results.pain.areas)
    .filter(x => {
      if (areas === null) {
        return true;
      }
      return areas.includes(x);
    })
    .some(x => {
      return results.pain.areas[x].duration === 4;
    });
}

function painHasNotImproved(results) {
  return Object.values(results.pain.areas).some(x => {
    return x.improvement !== 1;
  });
}

function painHasGotWorse(results, areas) {
  return Object.keys(results.pain.areas)
    .filter(x => areas.includes(x))
    .some(x => {
      return results.pain.areas[x].improvement === 3;
    });
}

function painLevelGreaterThan(results, level) {
  return Object.values(results.pain.areas).some(x => {
    return x.level > level;
  });
}

function hasRedFlag(results) {
  const utilityService = getUtilityService("pain");

  return Object.keys(results.pain.areas).some(x => {
    var painAreaResults = results.pain.areas[x];
    return utilityService.hasRedFlags(x, painAreaResults);
  });
}

function showInPersonVisitation(results) {
  var hasHighPain = painLevelGreaterThan(results, 6);
  var painGettingWorseDurationMoreThan1Month = Object.values(
    results.pain.areas
  ).some(x => {
    return x.improvement === 3 && x.duration > 2;
  });
  return hasHighPain && painGettingWorseDurationMoreThan1Month;
}

function goodTableHeight(results) {
  return results.setup.deskHeight === 0.48;
}

function needsErgonomicEquipment(results) {
  var areas = getPainAreas(results, ["wrist", "elbow"]);
  var hasRelevantPain = results.setup.workpain !== 4;
  var painNotImproving = areas.some(x => x.improvement !== 1);
  var chronicPain = areas.some(x => x.duration === 4);
  var wristsStraight = results.setup.relaxedarms === 1;
  var spaceForWrists = results.setup.wristspace === 1;
  var correctPainCondition = hasRelevantPain && painNotImproving && chronicPain;
  var correctSetup =
    wristsStraight &&
    spaceForWrists &&
    hasMouse(results) &&
    hasKeyboard(results);
  return correctPainCondition && correctSetup;
}

function needsErgonomicKeyboard(results) {
  var needsErgoEquipment = needsErgonomicEquipment(results);
  var hasErgoKeyboard = hasErgonomicKeyboard(results);
  return needsErgoEquipment && !hasErgoKeyboard;
}

function needsErgonomicMouse(results) {
  var needsErgoEquipment = needsErgonomicEquipment(results);
  var hasErgoMouse = hasErgonomicMouse(results);
  return needsErgoEquipment && !hasErgoMouse;
}

function hasKeyboard(results) {
  return hasRegularKeyboard(results) || hasErgonomicKeyboard(results);
}

function hasMouse(results) {
  return (
    hasRegularMouse(results) ||
    hasErgonomicMouse(results) ||
    hasAlternativeMouse(results)
  );
}

function hasErgonomicMouse(results) {
  return results.setup.deskItems.some(x => x.id === "ergonomicMouse");
}

function hasAlternativeMouse(results) {
  return results.setup.deskItems.some(
    x => x.id === "rollerMouse" || x.id === "trackpad"
  );
}

function hasErgonomicKeyboard(results) {
  return results.setup.deskItems.some(x => x.id === "ergonomicKeyboard");
}

function hasRegularMouse(results) {
  return results.setup.deskItems.some(x => x.id === "mouse");
}
function hasRegularKeyboard(results) {
  return results.setup.deskItems.some(x => x.id === "keyboard");
}

function getPregnantExplanation(results, card) {
  var text = pregnantAndCanViewResults(results)
    ? card.explanation.pregnancy
    : card.explanation.general;
  return i18n.t(text);
}

function showHeadphones(results) {
  var noHeadSet = results.setup.headset === 2;
  var hasPain = hasPainAreas(results, ["neck", "shoulder"]);
  return noHeadSet && hasPain;
}

function showWorkZones(results) {
  var easyToReach = results.setup.easytoreach === 1;
  if (!easyToReach) {
    return true;
  }

  var hasMonitorOrLaptop =
    hasItem(results, "laptopWithStand") ||
    hasItem(results, "laptop") ||
    hasItemType(results, "computerScreen");

  return hasKeyboard(results) && hasMouse(results) && hasMonitorOrLaptop;
}

function hasGoodWebcamResult(results) {
  if (results.webcam.screenHeightAngle === null) {
    return false;
  }

  var heightRange = WebcamValues.getScreenHeightRange(
    results.webcam.screenHeightAngle
  );
  var distanceRange = WebcamValues.getScreenDistanceRange(
    results.webcam.distanceFromScreen
  );
  var shoulderHeight = WebcamValues.getShoulderHeightRange(
    results.webcam.shoulderHeightDifference
  );
  var goodHeight = heightRange === "good";
  var goodDistance = distanceRange === "good";
  var goodShoulderHeight = shoulderHeight === "good";

  return goodHeight && goodDistance && goodShoulderHeight;
}

function getBreaksExplanation(results, card) {
  var inActiveBreaks = results.setup.breakActivity === 2;
  var text = inActiveBreaks
    ? card.explanation.inactiveBreaks
    : card.explanation.noBreaks;
  return i18n.t(text);
}

function getBasicKeyboardMouseContact(results) {
  switch (results.setup.workingfromhome) {
    case 2: // Office
      return results.setup.officeLocation === 1 //If in HQ
        ? "deskAssessment.results.recommendationsContent.emailDialog.action.itCoordinator"
        : "deskAssessment.results.recommendationsContent.emailDialog.action.officeManager";
    case 1: // Home
    case 3: // Not at usual workspace
    default:
      return ""; // Will not be shown because this should come out of their wellness budget.
  }
}

function getBasicKeyboardMouseLinkType(results, defaultType) {
  switch (results.setup.workingfromhome) {
    case 2: // Office
      return LinkTypes.EMAILREQUEST_TTQWEL;
    case 1: // Home
    case 3: // Not at usual workspace
    default:
      return defaultType ?? LinkTypes.PURCHASE;
  }
}

function getLinkTypeFromLocationAndEquipmentType(
  results,
  equipmentType,
  defaultType
) {
  switch (results.setup.workingfromhome) {
    case 2:
      return equipmentType === "basic"
        ? LinkTypes.EMAILREQUEST_TTQWEL
        : LinkTypes.REQUEST;
    case 1:
    case 3:
    default:
      return defaultType ?? LinkTypes.PURCHASE;
  }
}

function showLowNoiseRecommendation(results) {
  const hasHearingLoss = hasNonPainCondition(
    results,
    conditionAnswers.setup.nonPainCondition.hearingLoss
  );
  return !hasHearingLoss && results.setup.noise === 1;
}

function loudNoise(results) {
  return results.setup.noise > 2;
}

function showLoudNoiseCard(results) {
  const hasHearingLoss = hasNonPainCondition(
    results,
    conditionAnswers.setup.nonPainCondition.hearingLoss
  );
  return loudNoise(results) && !hasHearingLoss;
}

function tooColdAtHome(results) {
  var tooCold = results.setup.temperature === -2;
  var atHome = results.setup.workingfromhome === 1;
  return tooCold && atHome;
}

function tooHotAtHome(results) {
  var tooHot = results.setup.temperature === 2;
  var atHome = results.setup.workingfromhome === 1;
  return tooHot && atHome;
}

// if they have added a chair in sims we need to ensure they have added a "regular" chair i.e. not stool, beanbag or gym ball
// if they haven't added a chair in sims we need to rely on that they didn't select option 3 for seatAdjustable
function hasChair(results) {
  var chairInSims = results.setup.chair === 1;
  var specifiedSeatAdjustability =
    results.setup.seatadjustable === 1 || results.setup.seatadjustable === 2;

  if (chairInSims) {
    var regularChair = hasItemType(results, "chair");
    return specifiedSeatAdjustability && regularChair;
  }

  return specifiedSeatAdjustability;
}

function hasSpecialisedChair(results) {
  return (
    hasItem(results, "wheelchair") ||
    hasItem(results, "saddleChair") ||
    hasItem(results, "kneelingChair")
  );
}

function inHQ(results) {
  return results.setup.officeLocation === 1;
}

function atHome(results) {
  return results.setup.workingfromhome === 1;
}

function atOffice(results) {
  return results.setup.workingfromhome === 2;
}

function inResidentOffice(results) {
  return results.setup.officeLocation === 2;
}

function hasAdjustableChair(results) {
  return results.setup.seatadjustable === 1;
}

function needsLumbarSupport(results) {
  var chair = hasChair(results);
  var hasNoSupport = results.setup.backsupport === 2;
  return chair && hasNoSupport && !hasSpecialisedChair(results);
}

function needsLumbarSupportUnderstanding(results) {
  var chair = hasChair(results);
  var unsureOfSupport = results.setup.backsupport === 3;
  return chair && unsureOfSupport && !hasSpecialisedChair(results);
}

function hasAdjustableChairRiskFactors(results) {
  var wristsRelaxed = results.setup.relaxedarms === 1;
  var hasRelevantPain = hasPainAreas(results, [
    "midback",
    "lowerback",
    "gluteals"
  ]);
  var kneesPerpendicular = results.setup.kneeangle === 1;
  var armRestsNotCloserToDesk = results.setup.armrestsproximity === 2;
  return (
    !wristsRelaxed ||
    hasRelevantPain ||
    !kneesPerpendicular ||
    armRestsNotCloserToDesk
  );
}

function needsSeatWedge(results) {
  let chair = hasChair(results);
  let goodWebcam = hasGoodWebcamResult(results);
  let painNotImproving = painHasNotImproved(results);
  let chronicPain = durationLongerThan3Months(results);
  let keyboard = hasKeyboard(results);
  let mouse = hasMouse(results);
  return (
    chair &&
    goodWebcam &&
    painNotImproving &&
    chronicPain &&
    keyboard &&
    mouse &&
    !hasSpecialisedChair(results)
  );
}

function atHomeOrRemoteOffice(results) {
  return (
    results.setup.workingfromhome === 1 || results.setup.officeLocation === 2
  );
}

function atHomeOrOffice(results) {
  return results.setup.workingfromhome < 3;
}

function hasItem(results, item) {
  if (!results.setup.deskItems) {
    return false;
  }
  return results.setup.deskItems.some(x => x.id === item);
}

function hasItemType(results, itemType) {
  if (!results.setup.deskItems) {
    return false;
  }
  return results.setup.deskItems.some(x => x.id.includes(itemType));
}

function needsLatopStand(results) {
  if (visuallyImpairedUsingGlasses(results)) {
    return false;
  }
  var hasLaptopWithStand = hasItem(results, "laptopWithStand");
  var hasLaptop = hasItem(results, "laptop");
  return hasLaptop && !hasLaptopWithStand;
}

function raiseMonitor(results) {
  if (visuallyImpairedUsingGlasses(results)) {
    return false;
  }
  var hasLaptopWithStand = hasItem(results, "laptopWithStand");
  var hasLaptop = hasItem(results, "laptop");
  var hasMonitor = hasItemType(results, "computerScreen");
  var highAngle = isHighScreenAngle(results);
  return !hasLaptop && !hasLaptopWithStand && hasMonitor && highAngle;
}

function raiseLaptopStand(results) {
  if (visuallyImpairedUsingGlasses(results)) {
    return false;
  }
  var hasLaptopWithStand = hasItem(results, "laptopWithStand");
  var highAngle = isHighScreenAngle(results);
  return hasLaptopWithStand && highAngle;
}

function changeYourWristRest(results) {
  var hasWristRest = results.setup.wristrest === 1;
  var hasWristPain = hasPainAreas(results, ["wrist"]);
  return hasWristRest && hasWristPain;
}

function showMultipleScreenSetup(results) {
  var hasMultipleScreens =
    results.setup?.deskItems?.filter(
      i =>
        i.id === "laptopWithStand" ||
        i.id === "laptop" ||
        i.id.includes("computerScreen")
    ).length > 1;
  if (!hasMultipleScreens) {
    return false;
  }

  var mapper = new ElevationGeometryMapper(
    getDimensionsForWorkArea(results.setup)
  );
  var screensAtCorrectHeight = mapper.isUsersScreenHeightOptimal(
    results.setup.deskItems,
    results.setup.elevationEyeLevel
  );

  const utilityService = getUtilityService("setup");

  var screenAtCorrectDistance = results.webcam?.pictureTaken
    ? utilityService.getScreenDistanceScore(results) === 0
    : true;

  return !screenAtCorrectDistance || !screensAtCorrectHeight;
}

function showStandingBreaks(results) {
  const hasMobilityIssue = hasNonPainCondition(
    results,
    conditionAnswers.setup.nonPainCondition.mobilityImpairment
  );
  const hasWheelchair = hasItem(results, "wheelchair");
  return (
    !hasMobilityIssue &&
    !hasWheelchair &&
    (noBreaks(results) || noActiveBreaks(results))
  );
}

function showBreakIdeas(results) {
  const hasMobilityIssue = hasNonPainCondition(
    results,
    conditionAnswers.setup.nonPainCondition.mobilityImpairment
  );
  const hasWheelchair = hasItem(results, "wheelchair");
  return !hasMobilityIssue && !hasWheelchair && noActiveBreaks(results);
}

function hasNonPainCondition(results, condition) {
  if (!results.setup.nonPainCondition) return false;
  return results.setup.nonPainCondition.includes(condition);
}

function visuallyImpairedUsingGlasses(results) {
  const isVisuallyImpaired = hasNonPainCondition(
    results,
    conditionAnswers.setup.nonPainCondition.visionImpairment
  );
  return (
    isVisuallyImpaired &&
    results.setup.glasses === conditionAnswers.setup.glasses.yes
  );
}

const DeskAssessmentRecommendations = [
  {
    id: "pain_coach",
    json: cards.setup.painCoachCard,
    showOnPage: isNotUserDashboard,
    explanation: results => {
      var painArea = SummaryService.GetPriorityPainAreaOrDefault(results);
      var hasPainAreas = Object.keys(results.pain.areas).length !== 0;
      var customText = hasPainAreas
        ? i18n.t(`reportText.pain.painAreaNames.${painArea}.text`)
        : i18n.t(
            `deskAssessmentRecommendationCards.painCoach.noPainCustomText`
          );
      return i18n.t(cards.setup.painCoachCard.explanation, {
        customText: customText
      });
    },
    linkType: () => LinkTypes.INTERNAL,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 10
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "ergonomic_keyboard_upgrade",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "ergonomic_keyboard_budget",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardBudget,
    linkType: () => LinkTypes.PURCHASE,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "regular_keyboard_upgrade",
    showAnswer: results => !hasKeyboard(results),
    json: cards.setup.regularKeyboardUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_keyboard_budget",
    showAnswer(results) {
      return hasKeyboard(results) === false;
    },
    json: cards.setup.regularKeyboardBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_mouse_budget",
    showAnswer(results) {
      return hasMouse(results) === false;
    },
    json: cards.setup.regularMouseBudget,
    linkType: () => LinkTypes.PURCHASE,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_mouse_upgrade",
    showAnswer: results => !hasMouse(results),
    json: cards.setup.regularMouseUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "mouse_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "ergonomic_mouse",
    showAnswer: results => needsErgonomicMouse(results),
    json: cards.setup.ergonomicMouse,
    linkType: () => LinkTypes.REGULAR,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "smoking",
    showAnswer: isSmoker,
    json: cards.setup.smoking,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 1,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "raise_screen_upgrade",
    showAnswer: needsLatopStand,
    json: cards.setup.raiseYourScreenCardUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "laptop_stand_upgrade",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "raise_screen_budget",
    showAnswer: needsLatopStand,
    json: cards.setup.raiseYourScreenCardBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "laptop_stand_budget",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "beanBag",
    showAnswer(results) {
      return hasBeanBag(results) && !showChairCard(results);
    },
    json: cards.setup.beanBag,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "chair",
    showAnswer(results) {
      return showChairCard(results);
    },
    json: cards.setup.chair,
    linkType: () => LinkTypes.REGULAR,
    type: "chair",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "feet_supported_upgrade",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: cards.setup.feetsupportedUpgrade,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedUpgrade),

    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "feet_supported_budget",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: cards.setup.feetsupportedBudget,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedBudget),

    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "back_support_upgrade",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportUpgrade,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportUpgrade),

    linkType: () => LinkTypes.PURCHASE,
    type: "lumbarSupport_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_budget",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportBudget,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportBudget),

    linkType: () => LinkTypes.REGULAR,
    type: "lumbarSupport_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_understanding",
    showAnswer: needsLumbarSupportUnderstanding,
    json: cards.setup.backsupportUnderstanding,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_upgrade",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "wristrest_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_budget",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "wristrest_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "hours_upgrade",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursUpgrade,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursUpgrade),

    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "hours_budget",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursBudget,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursBudget),

    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "greenery",
    showAnswer: noPlants,
    json: cards.setup.addSomeGreenery,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 1
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breaks",
    showAnswer: noBreaks,
    json: cards.setup.breaks,
    explanation: results => getPregnantExplanation(results, cards.setup.breaks),
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "lunch",
    showAnswer: noLunchAwayFromDesk,
    json: cards.setup.lunch,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "water_bottle",
    showAnswer: noWaterBottle,
    json: cards.setup.getAWaterBottle,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.getAWaterBottle),

    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "natural_light",
    showAnswer: noNaturalLight,
    json: cards.setup.getSomeNaturalLight,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "meditation",
    showAnswer: noBreaksLongHours,
    json: cards.setup.meditation,
    explanation: results =>
      getBreaksExplanation(results, cards.setup.meditation),
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "clutter",
    showAnswer: hasClutter,
    json: cards.setup.clutter,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breakIdeas",
    showAnswer: results => showBreakIdeas(results),
    json: cards.setup.breakIdeas,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "fresh_air",
    showAnswer: () => true,
    json: cards.setup.getSomeFreshAir,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "adjust_chair",
    showAnswer(results) {
      return (
        atHomeOrOffice(results) &&
        hasChair(results) &&
        !hasSpecialisedChair(results) &&
        hasAdjustableChair(results) &&
        hasAdjustableChairRiskFactors(results)
      );
    },
    json: cards.setup.adjustChair,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "raise_your_monitor_card",
    showAnswer: results => raiseMonitor(results),
    json: cards.setup.raiseYourMonitor,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "raise_your_laptop_stand_card",
    showAnswer: results => raiseLaptopStand(results),
    json: cards.setup.raiseYourLaptopStand,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "low_noise_budget",
    showAnswer: results => showLowNoiseRecommendation(results),
    json: cards.setup.lowNoiseBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "noise_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "low_noise_upgrade",
    showAnswer: results => showLowNoiseRecommendation(results),
    json: cards.setup.lowNoiseUpgrade,
    linkType: () => LinkTypes.REGULAR,
    type: "noise_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_noise_budget",
    showAnswer: results => showLoudNoiseCard(results),
    json: cards.setup.highNoiseBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "noise_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_noise_upgrade",
    showAnswer: results => showLoudNoiseCard(results),
    json: cards.setup.highNoiseUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "noise_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "headphones_budget",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesBudget,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "headphones_upgrade",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_budget",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return !hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_upgrade",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return !hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "seat_wedge_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_hip_budget",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackHipBudget,
    linkType: () => LinkTypes.REGULAR,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_hip_upgrade",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackHipUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "seat_wedge_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_hip_budget",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && !lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeHipBudget,
    linkType: () => LinkTypes.PURCHASE,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_hip_upgrade",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && !lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeHipUpgrade,
    linkType: () => LinkTypes.PURCHASE,
    type: "seat_wedge_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "multiple_screen_setup",
    showAnswer: showMultipleScreenSetup,
    json: cards.setup.multipleScreenSetup,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "poor_setup_ykavx",
    showAnswer: hasPoorSetup,
    json: cards.setup.poorSetup_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "custom_knee_pain_ykavx",
    showAnswer: hasKneePain,
    json: cards.setup.customKneePain_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "monitor_distance_ykavx",
    showAnswer: notPerfectScreenDistance,
    json: cards.setup.monitorDistance_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "smoking_ykavx",
    showAnswer: isSmoker,
    json: cards.setup.smoking_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 1,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "raise_screen_upgrade_ykavx",
    showAnswer: needsLatopStand,
    json: cards.setup.raiseYourScreenCardUpgrade_ykavx,
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    type: "laptop_stand_upgrade",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "feet_supported_upgrade_ykavx",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: cards.setup.feetsupportedUpgrade_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedUpgrade_ykavx),

    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "feet_supported_budget_ykavx",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: cards.setup.feetsupportedBudget_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedBudget_ykavx),

    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "back_support_upgrade_ykavx",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportUpgrade_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportUpgrade_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "lumbarSupport_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_budget_ykavx",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportBudget_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportBudget_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "lumbarSupport_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_upgrade_ykavx",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsUpgrade_ykavx,
    linkType: () => LinkTypes.REGULAR,
    type: "wristrest_upgrade",
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "hours_upgrade_ykavx",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursUpgrade_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursUpgrade_ykavx),
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "hours_budget_ykavx",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursBudget_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursBudget_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "lunch_ykavx",
    showAnswer: noLunchAwayFromDesk,
    json: cards.setup.lunch_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "water_bottle_ykavx",
    showAnswer: noWaterBottle,
    json: cards.setup.getAWaterBottle_ykavx,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.getAWaterBottle_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "natural_light_ykavx",
    showAnswer: noNaturalLight,
    json: cards.setup.getSomeNaturalLight_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "meditation_ykavx",
    showAnswer: noBreaksLongHours,
    json: cards.setup.meditation_ykavx,
    explanation: results =>
      getBreaksExplanation(results, cards.setup.meditation_ykavx),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "clutter_ykavx",
    showAnswer: hasClutter,
    json: cards.setup.clutter_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "fresh_air_ykavx",
    showAnswer: () => true,
    json: cards.setup.getSomeFreshAir_ykavx,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "raise_screen_etKJa",
    showAnswer: needsLatopStand,
    json: cards.setup.raiseYourScreenCard_etKJa,
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    type: "laptop_stand_upgrade",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "poor_setup_etKJa",
    showAnswer: hasPoorSetup,
    json: cards.setup.poorSetup_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "feet_supported_upgrade_etKJa",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: cards.setup.feetsupportedUpgrade_etKJa,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedUpgrade_etKJa),
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "feet_supported_budget_etKJa",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: cards.setup.feetsupportedBudget_etKJa,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedBudget_etKJa),
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "back_support_upgrade_etKJa",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportUpgrade_etKJa,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportUpgrade_etKJa),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "lumbarSupport_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_budget_etKJa",
    showAnswer: needsLumbarSupport,
    json: cards.setup.backsupportBudget_etKJa,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportBudget_etKJa),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "lumbarSupport_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_budget_etKJa",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmBudget_etKJa,
    linkType: () => LinkTypes.REGULAR,
    type: "wristrest_budget",
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_upgrade_etKJa",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsUpgrade_etKJa,
    linkType: () => LinkTypes.REGULAR,
    type: "wristrest_upgrade",
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "hours_upgrade_etKJa",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursUpgrade_etKJa,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursUpgrade_etKJa),
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "hours_budget_etKJa",
    showAnswer: results => showStandingDesk(results),
    json: cards.setup.hoursBudget_etKJa,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.hoursBudget_etKJa),
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "greenery_etKJa",
    showAnswer: noPlants,
    json: cards.setup.addSomeGreenery_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 1
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "monitor_distance_etKJa",
    showAnswer: notPerfectScreenDistance,
    json: cards.setup.monitorDistance_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "beanBag_etKJa",
    showAnswer(results) {
      return hasBeanBag(results) && !showChairCard(results);
    },
    json: cards.setup.beanBag_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breaks_etKJa",
    showAnswer: noBreaks,
    json: cards.setup.breaks_etKJa,
    explanation: results => getPregnantExplanation(results, cards.setup.breaks),
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "lunch_etKJa",
    showAnswer: noLunchAwayFromDesk,
    json: cards.setup.lunch_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "water_bottle_etKJa",
    showAnswer: noWaterBottle,
    json: cards.setup.getAWaterBottle_etKJa,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.getAWaterBottle_etKJa),

    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "natural_light_etKJa",
    showAnswer: noNaturalLight,
    json: cards.setup.getSomeNaturalLight_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "fresh_air_etKJa",
    showAnswer: () => true,
    json: cards.setup.getSomeFreshAir_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "meditation_etKJa",
    showAnswer: noBreaksLongHours,
    json: cards.setup.meditation_etKJa,
    explanation: results =>
      getBreaksExplanation(results, cards.setup.meditation_etKJa),
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "clutter_etKJa",
    showAnswer: hasClutter,
    json: cards.setup.clutter_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "smoking_etKJa",
    showAnswer: isSmoker,
    json: cards.setup.smoking_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 1,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breakIdeas_etKJa",
    showAnswer: results => showBreakIdeas(results),
    json: cards.setup.breakIdeas_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "high_noise_etKJa",
    showAnswer: results => showLoudNoiseCard(results),
    json: cards.setup.highNoise_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "low_noise_etKJa",
    showAnswer: results => showLowNoiseRecommendation(results),
    json: cards.setup.lowNoise_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "standingBreaks_etKJa",
    showAnswer: results => showStandingBreaks(results),
    json: cards.setup.standingBreaks_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "headphones_budget_etKJa",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesBudget_etKJa,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "headphones_upgrade_etKJa",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesUpgrade_etKJa,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "regular_mouse_budget_etKJa",
    showAnswer(results) {
      return hasMouse(results) === false;
    },
    json: cards.setup.regularMouseBudget_etKJa,
    custom: true,
    linkType: () => LinkTypes.PURCHASE,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_mouse_upgrade_etKJa",
    showAnswer: results => !hasMouse(results),
    json: cards.setup.regularMouseUpgrade_etKJa,
    custom: true,
    linkType: () => LinkTypes.PURCHASE,
    type: "mouse_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_keyboard_budget_etKJa",
    showAnswer(results) {
      return hasKeyboard(results) === false;
    },
    json: cards.setup.regularKeyboardBudget_etKJa,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_keyboard_upgrade_etKJa",
    showAnswer: results => !hasKeyboard(results),
    json: cards.setup.regularKeyboardUpgrade_etKJa,
    custom: true,
    linkType: () => LinkTypes.PURCHASE,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "ergonomic_mouse_etKJa",
    showAnswer: results => needsErgonomicMouse(results),
    json: cards.setup.ergonomicMouse_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "ergonomic_keyboard_budget_etKJa",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardBudget_etKJa,
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "ergonomic_keyboard_upgrade_etKJa",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardUpgrade_etKJa,
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "seat_wedge_lower_back_etKJa",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return !hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBack_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_hip_etKJa",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeLowerBackHip_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_hip_etKJa",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && !lowerBackPain && needsSeatWedge(results);
    },
    json: cards.setup.seatWedgeHip_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "chair_etKJa",
    showAnswer(results) {
      return (
        !hasChair(results) &&
        !hasSpecialisedChair(results) &&
        atHomeOrRemoteOffice(results)
      );
    },
    json: cards.setup.chair_etKJa,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "chair",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "low_temperature_etKJa",
    showAnswer: results => tooColdAtHome(results),
    json: cards.setup.lowTemperature_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_temperature_etKJa",
    showAnswer: results => tooHotAtHome(results),
    json: cards.setup.highTemperature_etKJa,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "physical_activity_classes_cMwjk",
    showAnswer: results => durationLongerThan3Months(results),
    json: cards.setup.physicalActivityClasses_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8.9,
      easeOfUse: 9.5,
      painImpact: 9.3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wellbeing_programme_cMwjk",
    showAnswer: results =>
      durationLongerThan3Months(results) ||
      painHasNotImproved(results) ||
      painLevelGreaterThan(results, 5),
    json: cards.setup.wellbeingProgramme_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 4,
      easeOfUse: 7,
      painImpact: 8.8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "msk_programme_cMwjk",
    showAnswer: results => painHasNotImproved(results),
    json: cards.setup.mskProgramme_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 10,
      painImpact: 9.2
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "self_care_pack_cMwjk",
    showAnswer: results => painHasNotImproved(results),
    json: cards.setup.selfCarePack_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 6,
      painImpact: 8.8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "your_support_cMwjk",
    showAnswer: results => painHasNotImproved(results),
    json: cards.setup.yourSupport_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 7.1
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "occupational_health_cMwjk",
    showAnswer: results => hasRedFlag(results),
    json: cards.setup.occupationalHealth_cMwjk,
    linkType: () => LinkTypes.EMAIL,
    custom: true,
    type: "occupational_health_cMwjk",
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9.5,
      painImpact: 10
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "occupational_health_cMwjk",
    showAnswer: results =>
      painLevelGreaterThan(results, 5) && hasRedFlag(results) === false,
    json: cards.setup.occupationalHealth_cMwjk,
    linkType: () => LinkTypes.EMAIL,
    type: "occupational_health_cMwjk",
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9.5,
      painImpact: 10
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "react_cMwjk",
    showAnswer: results => painLevelGreaterThan(results, 5),
    json: cards.setup.react_cMwjk,
    linkType: () => LinkTypes.EMAIL,
    custom: true,
    scores: {
      easeOfAccess: 8.9,
      easeOfUse: 6.1,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "peer_support_cMwjk",
    showAnswer: results => hasRedFlag(results),
    json: cards.setup.peerSupport_cMwjk,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9.6,
      easeOfUse: 9.8,
      painImpact: 9.8
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "book_session_cMwjk",
    showAnswer: results => hasRedFlag(results),
    json: cards.setup.bookSession_cMwjk,
    linkType: () => LinkTypes.EMAIL,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9.5,
      painImpact: 10
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "cycle_to_work_kItGM",
    showAnswer: () => true,
    json: cards.setup.cycleToWork_kItGM,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "in_person_visit_ttqWel",
    showAnswer: results => showInPersonVisitation(results),
    json: cards.setup.inPersonVisit_ttqWel,
    linkType: () => LinkTypes.APPOINTMENT,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_keyboard_upgrade_ttqWel",
    showAnswer: results => hasKeyboard(results) === false,
    json: cards.setup.regularKeyboardUpgrade,
    linkType: results => getBasicKeyboardMouseLinkType(results),
    custom: true,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX,
    action: results => getBasicKeyboardMouseContact(results)
  },
  {
    id: "regular_keyboard_budget_ttqWel",
    showAnswer: results => hasKeyboard(results) === false,
    json: cards.setup.regularKeyboardBudget,
    linkType: results =>
      getBasicKeyboardMouseLinkType(results, LinkTypes.REGULAR),
    custom: true,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX,
    action: results => getBasicKeyboardMouseContact(results)
  },
  {
    id: "ergonomic_keyboard_upgrade_ttqWel",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardUpgrade,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    custom: true,
    type: "keyboard_upgrade",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "ergonomic_keyboard_budget_ttqWel",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: cards.setup.ergonomicKeyboardBudget,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    custom: true,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "regular_mouse_budget_ttqWel",
    showAnswer: results => hasMouse(results) === false,
    json: cards.setup.regularMouseBudget,
    linkType: results => getBasicKeyboardMouseLinkType(results),
    custom: true,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX,
    action: results => getBasicKeyboardMouseContact(results)
  },
  {
    id: "regular_mouse_upgrade_ttqWel",
    showAnswer: results => hasMouse(results) === false,
    json: cards.setup.regularMouseUpgrade,
    linkType: results => getBasicKeyboardMouseLinkType(results),
    custom: true,
    type: "mouse_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX,
    action: results => getBasicKeyboardMouseContact(results)
  },
  {
    id: "ergonomic_mouse_upgrade_ttqWel",
    showAnswer: results => needsErgonomicMouse(results),
    json: cards.setup.ergonomicMouseUpgrade_ttqWel,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(
        results,
        "specialised",
        LinkTypes.REGULAR
      ),
    custom: true,
    type: "mouse_upgrade_ttqWel",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "ergonomic_mouse_budget_ttqWel",
    showAnswer: results => needsErgonomicMouse(results),
    json: cards.setup.ergonomicMouseBudget_ttqWel,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(
        results,
        "specialised",
        LinkTypes.REGULAR
      ),
    custom: true,
    type: "mouse_budget_ttqWel",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "standingBreaks",
    showAnswer: results => showStandingBreaks(results),
    json: cards.setup.standingBreaks,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "headphones_budget_ttqWel",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesBudget,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "headphones_upgrade_ttqWel",
    showAnswer: results => showHeadphones(results),
    json: cards.setup.headphonesUpgrade,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_noise_budget_ttqWel",
    showAnswer: results => showLoudNoiseCard(results),
    json: cards.setup.highNoiseBudget,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    type: "noise_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_noise_upgrade_ttqWel",
    showAnswer: results => showLoudNoiseCard(results),
    json: cards.setup.highNoiseUpgrade,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "specialised"),
    type: "noise_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "low_temperature",
    showAnswer: results => tooColdAtHome(results),
    json: cards.setup.lowTemperature,
    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "high_temperature",
    showAnswer: results => tooHotAtHome(results),
    json: cards.setup.highTemperature,
    linkType: () => LinkTypes.PURCHASE,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "chair_ttqWel",
    showAnswer(results) {
      return (
        !hasChair(results) &&
        !hasSpecialisedChair(results) &&
        atHomeOrRemoteOffice(results)
      );
    },
    json: cards.setup.chair,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "chair",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "building_guide_ttqWel",
    showAnswer: results => inHQ(results),
    json: cards.setup.buildingGuide_ttqWel,
    custom: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "back_support_upgrade_ttqWel",
    showAnswer(results) {
      return needsLumbarSupport(results) && !isPregnant(results);
    },
    json: cards.setup.backsupportUpgrade_ttqWel,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "lumbarSupport_upgrade",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_upgrade_pregnant_ttqWel",
    showAnswer(results) {
      return needsLumbarSupport(results) && isPregnant(results);
    },
    json: cards.setup.backsupportUpgradePregnant_ttqWel,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "lumbarSupport_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_budget_ttqWel",
    showAnswer: results => needsLumbarSupport(results),
    json: cards.setup.backsupportBudget,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.backsupportBudget),
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "adjust_chair_home_ttqWel",
    showAnswer(results) {
      return (
        atHome(results) &&
        hasChair(results) &&
        !hasSpecialisedChair(results) &&
        hasAdjustableChair(results) &&
        hasAdjustableChairRiskFactors(results)
      );
    },
    json: cards.setup.adjustChair,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "adjust_chair_resident_office_ttqWel",
    showAnswer(results) {
      return (
        inResidentOffice(results) &&
        hasChair(results) &&
        !hasSpecialisedChair(results) &&
        hasAdjustableChair(results) &&
        hasAdjustableChairRiskFactors(results)
      );
    },
    json: cards.setup.adjustChair,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "adjust_chair_hq_ttqWel",
    showAnswer(results) {
      return (
        inHQ(results) &&
        hasAdjustableChairRiskFactors(results) &&
        !hasSpecialisedChair(results)
      );
    },
    json: cards.setup.adjustChairHQ_ttqWel,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "chair_office_iHRsdr",
    showAnswer(results) {
      return atOffice(results);
    },
    json: cards.setup.chairOffice_iHRsdr,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "chair_home_iHRsdr",
    showAnswer(results) {
      return showChairCard(results) && !atOffice(results);
    },
    json: cards.setup.chair,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "chair",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "adjust_chair_home_iHRsdr",
    showAnswer(results) {
      return (
        !atOffice(results) &&
        hasChair(results) &&
        !hasSpecialisedChair(results) &&
        hasAdjustableChair(results) &&
        hasAdjustableChairRiskFactors(results)
      );
    },
    json: cards.setup.adjustChair,
    custom: true,
    linkType: () => LinkTypes.REGULAR,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "raise_your_screen_card_budget_ttqWel",
    showAnswer: results => needsLatopStand(results),
    json: cards.setup.raiseYourScreenCardBudget,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "laptop_stand_budget",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "raise_your_screen_card_upgrade_ttqWel",
    showAnswer: results => needsLatopStand(results),
    json: cards.setup.raiseYourScreenCardUpgrade_ttqWel,
    custom: true,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "laptop_stand_upgrade",
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "change_your_wrist_rest_card",
    showAnswer: results => changeYourWristRest(results),
    json: cards.setup.changeYourWristRest,
    linkType: () => LinkTypes.REGULAR,
    type: "wristrest_budget",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_upgrade_ttqWel",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsUpgrade_ttqWel,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "wristrest_upgrade",
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_budget_ttqWel",
    showAnswer: needsWristRest,
    json: cards.setup.relaxedArmsBudget_ttqWel,
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    type: "wristrest_budget",
    custom: true,
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "feet_supported_upgrade_ttqWel",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: cards.setup.feetsupportedUpgrade_ttqWel,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedUpgrade_ttqWel),
    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "feet_supported_budget_ttqWel",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: cards.setup.homeWorkingGuidelines_ttqWel,
    explanation: results =>
      getPregnantExplanation(results, cards.setup.feetsupportedBudget_ttqWel),

    linkType: results =>
      getLinkTypeFromLocationAndEquipmentType(results, "basic"),
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "home_working_guidelines_ttqWel",
    showAnswer: results => atHome(results),
    json: cards.setup.homeWorkingGuidelines_ttqWel,
    custom: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "be_me_app_ykavx",
    json: cards.fixed.beMeApp_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "desk_stretches_ykavx",
    json: cards.fixed.deskStretches_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "aches_and_pains_ykavx",
    json: cards.fixed.achesAndPains_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "back_tension_ykavx",
    json: cards.fixed.backTension_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "personal_energy_ykavx",
    json: cards.fixed.personalEnergy_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "health_and_wellbeing_hub_ykavx",
    json: cards.fixed.healthAndWellbeingHub_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "anytime_health_line_ykavx",
    json: cards.fixed.anytimeHealthline_ykavx,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "desk_stretches_etKJa",
    json: cards.fixed.deskStretches_etKJa,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "aches_and_pains_etKJa",
    json: cards.fixed.achesAndPains_etKJa,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "back_tension_etKJa",
    json: cards.fixed.backTension_etKJa,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "personal_energy_etKJa",
    json: cards.fixed.personalEnergy_etKJa,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "health_and_wellbeing_hub_etKJa",
    json: cards.fixed.healthAndWellbeingHub_etKJa,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "anytime_health_line_etKJa",
    json: cards.fixed.anytimeHealthline_etKJa,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "employee_services_wltNL",
    showAnswer: () => true,
    json: cards.setup.employeeServices_wltNL,
    custom: true,
    linkType: () => LinkTypes.EMAIL
  },
  {
    id: "eyecare_vouchers_eSRiAd",
    showAnswer: () => true,
    json: cards.fixed.eyecareVouchers_eSRiAd,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "my_benefits_eSRiAd",
    showAnswer: () => true,
    json: cards.fixed.myBenefits_eSRiAd,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "multiple_screen_setup_eSRiAd",
    showAnswer: showMultipleScreenSetup,
    json: cards.setup.multipleScreenSetup_eSRiAd,
    linkType: () => LinkTypes.REGULAR,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    custom: true,
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "order_it_equipment_eSRiAd",
    showAnswer: () => true,
    json: cards.fixed.orderITEquipment_eSRiAd,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "order_furniture_eSRiAd",
    showAnswer: () => true,
    json: cards.fixed.orderFurniture_eSRiAd,
    fixed: true,
    linkType: () => LinkTypes.REGULAR
  },
  {
    id: "raise_screen_beCsMA",
    showAnswer: needsLatopStand,
    json: becsmaCards.raiseYourScreenCard,
    linkType: () => LinkTypes.REGULAR,
    type: "laptop_stand_budget",
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "raise_your_laptop_stand_beCsMA",
    showAnswer: results => raiseLaptopStand(results),
    json: becsmaCards.raiseYourLaptopStand,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "raise_your_monitor_beCsMA",
    showAnswer: results => raiseMonitor(results),
    json: becsmaCards.raiseYourMonitor,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "feet_supported_beCsMA",
    showAnswer(results) {
      return showFootSupport(results);
    },
    json: becsmaCards.feetSupported,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 7,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "back_support_beCsMA",
    showAnswer: needsLumbarSupport,
    json: becsmaCards.backsupport,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "lumbarSupport_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "back_support_understanding_beCsMA",
    showAnswer: needsLumbarSupportUnderstanding,
    json: becsmaCards.backsupportUnderstanding,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "change_your_wrist_rest_beCsMA",
    showAnswer: results => changeYourWristRest(results),
    json: becsmaCards.changeYourWristRest,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "wristrest_budget",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "wrist_rest_beCsMA",
    showAnswer: needsWristRest,
    json: becsmaCards.relaxedArms,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "wristrest_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 6,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "hours_beCsMA",
    showAnswer: results => showStandingDesk(results),
    json: becsmaCards.hours,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 5,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "greenery_beCsMA",
    showAnswer: noPlants,
    json: becsmaCards.addSomeGreenery,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 1
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "multiple_screen_setup_beCsMA",
    showAnswer: showMultipleScreenSetup,
    json: becsmaCards.multipleScreenSetup,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "chair_beCsMA",
    showAnswer(results) {
      return showChairCard(results);
    },
    json: becsmaCards.chair,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "chair",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 9,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "seat_wedge_lower_back_beCsMA",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return !hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: becsmaCards.seatWedgeLowerBack,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_lower_back_hip_beCsMA",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && lowerBackPain && needsSeatWedge(results);
    },
    json: becsmaCards.seatWedgeLowerBackHip,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "seat_wedge_hip_beCsMA",
    showAnswer: results => {
      var hipPain = hasPainAreas(results, ["hip"]);
      var lowerBackPain = hasPainAreas(results, ["lowerback"]);
      return hipPain && !lowerBackPain && needsSeatWedge(results);
    },
    json: becsmaCards.seatWedgeHip,
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    type: "seat_wedge_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 7,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "breaks_beCsMA",
    showAnswer: noBreaks,
    json: becsmaCards.breaks,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "lunch_beCsMA",
    showAnswer: noLunchAwayFromDesk,
    json: becsmaCards.lunch,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "water_bottle_beCsMA",
    showAnswer: noWaterBottle,
    json: becsmaCards.getAWaterBottle,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "natural_light_beCsMA",
    showAnswer: noNaturalLight,
    json: becsmaCards.getSomeNaturalLight,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "fresh_air_beCsMA",
    showAnswer: () => true,
    json: becsmaCards.getSomeFreshAir,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "meditation_beCsMA",
    showAnswer: noBreaksLongHours,
    json: becsmaCards.meditation,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 6,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "clutter_beCsMA",
    showAnswer: hasClutter,
    json: becsmaCards.clutter,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "smoking_beCsMA",
    showAnswer: isSmoker,
    json: becsmaCards.smoking,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 1,
      painImpact: 6
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "breakIdeas_beCsMA",
    showAnswer: results => showBreakIdeas(results),
    json: becsmaCards.breakIdeas,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "sleep_hygiene_beCsMA",
    showAnswer: () => true,
    json: becsmaCards.sleepHygiene,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "high_noise_beCsMA",
    showAnswer: results => showLoudNoiseCard(results),
    json: becsmaCards.highNoise,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "noise_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 5,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "low_noise_beCsMA",
    showAnswer: results => showLowNoiseRecommendation(results),
    json: becsmaCards.lowNoise,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "noise_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "standingBreaks_beCsMA",
    showAnswer: results => showStandingBreaks(results),
    json: becsmaCards.standingBreaks,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "headphones_beCsMA",
    showAnswer: results => showHeadphones(results),
    json: becsmaCards.headphones,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 7,
      easeOfUse: 8,
      painImpact: 3
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "regular_mouse_beCsMA",
    showAnswer(results) {
      return hasMouse(results) === false;
    },
    json: becsmaCards.regularMouse,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 9.4,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "regular_keyboard_beCsMA",
    showAnswer: results => !hasKeyboard(results),
    json: becsmaCards.regularKeyboard,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 9
    },
    workSpacePriority: WorkSpacePriority.MAX
  },
  {
    id: "ergonomic_mouse_beCsMA",
    showAnswer: results => needsErgonomicMouse(results),
    json: becsmaCards.ergonomicMouse,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "mouse_budget",
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "ergonomic_keyboard_beCsMA",
    showAnswer: results => needsErgonomicKeyboard(results),
    json: becsmaCards.ergonomicKeyboard,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "keyboard_budget",
    scores: {
      easeOfAccess: 6,
      easeOfUse: 8,
      painImpact: 7
    },
    workSpacePriority: WorkSpacePriority.HIGH
  },
  {
    id: "high_temperature_beCsMA",
    showAnswer: results => tooHotAtHome(results),
    json: becsmaCards.highTemperature,
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "low_temperature_beCsMA",
    showAnswer: results => tooColdAtHome(results),
    json: becsmaCards.lowTemperature,
    linkType: () => LinkTypes.PURCHASE,
    custom: true,
    scores: {
      easeOfAccess: 5,
      easeOfUse: 8,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "work_zones_beCsMA",
    showAnswer: results => showWorkZones(results),
    json: becsmaCards.workZones,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "adjust_chair_beCsMA",
    showAnswer(results) {
      return (
        atHomeOrOffice(results) &&
        hasChair(results) &&
        !hasSpecialisedChair(results) &&
        hasAdjustableChair(results) &&
        hasAdjustableChairRiskFactors(results)
      );
    },
    json: becsmaCards.adjustChair,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    type: "adjust_chair",
    scores: {
      easeOfAccess: 8,
      easeOfUse: 8,
      painImpact: 8
    },
    workSpacePriority: WorkSpacePriority.MEDIUM
  },
  {
    id: "beanBag_beCsMA",
    showAnswer(results) {
      return hasBeanBag(results) && !showChairCard(results);
    },
    json: becsmaCards.beanBag,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    scores: {
      easeOfAccess: 9,
      easeOfUse: 9,
      painImpact: 4
    },
    workSpacePriority: WorkSpacePriority.LOW
  },
  {
    id: "occupational_health_KcaRDa",
    showOnPage: isAssessmentRoute,
    json: cards.setup.occupationalHealth_KcaRDa,
    linkType: () => LinkTypes.REPORT_DOWNLOAD,
    custom: true
  },
  {
    id: "eye_test_AwEATt",
    json: cards.setup.eyeTest_AwEATt,
    linkType: () => LinkTypes.REGULAR,
    custom: true
  },
  {
    id: "ergonomicReferral_UjeUTz",
    json: cards.setup.ergonomicReferral_UjeUTz,
    linkType: () => LinkTypes.REGULAR,
    custom: true,
    showAnswer: results => userIsHighRisk(results)
  }
];

function DefaultExerciseRecommendations(jsonFile) {
  return {
    warmUpCard: {
      id: "exercise_warm_up",
      showAnswer: results => {
        if (results.health.pregnancy === 1) {
          return shouldShowGeneralExerciseCardIfPregnant(results);
        }
        return true;
      },
      json: jsonFile.pain.warmUpCard,
      linkType: () => LinkTypes.VIDEO
    },
    neckExerciseCard: {
      id: "exercise_neck",
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "neck");
      },
      json: jsonFile.pain.neckExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    midBackExerciseCard: {
      id: "exercise_mid_back",
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "midback");
      },
      json: jsonFile.pain.midBackExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    lowerBackExerciseCard: {
      id: "exercise_lower_back",
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "lowerback");
      },
      json: jsonFile.pain.lowerBackExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    wristExerciseCard: {
      id: "exercise_wrist",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftwrist") ||
          shouldShowPainAreaExerciseCard(results, "rightwrist")
        );
      },
      json: jsonFile.pain.wristExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    hipExerciseCard: {
      id: "exercise_hip",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "lefthip") ||
          shouldShowPainAreaExerciseCard(results, "righthip")
        );
      },
      json: jsonFile.pain.hipExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    elbowExerciseCard: {
      id: "exercise_elbow",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftelbow") ||
          shouldShowPainAreaExerciseCard(results, "rightelbow")
        );
      },
      json: jsonFile.pain.elbowExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    shoulderExerciseCard: {
      id: "exercise_shoulder",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftshoulder") ||
          shouldShowPainAreaExerciseCard(results, "rightshoulder")
        );
      },
      json: jsonFile.pain.shoulderExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    ankleExerciseCard: {
      id: "exercise_ankle",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftankle") ||
          shouldShowPainAreaExerciseCard(results, "rightankle")
        );
      },
      json: jsonFile.pain.ankleExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    footExerciseCard: {
      id: "exercise_foot",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftankle") ||
          shouldShowPainAreaExerciseCard(results, "rightankle")
        );
      },
      json: jsonFile.pain.footExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    kneeExerciseCard: {
      id: "exercise_knee",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftknee") ||
          shouldShowPainAreaExerciseCard(results, "rightknee")
        );
      },
      json: jsonFile.pain.kneeExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    pectoralExerciseCard: {
      id: "exercise_pectoral",
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "chest");
      },
      json: jsonFile.pain.pectoralExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    glutealExerciseCard: {
      id: "exercise_gluteal",
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "gluteals");
      },
      json: jsonFile.pain.glutealExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    calfExerciseCard: {
      id: "exercise_calf",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "leftcalf") ||
          shouldShowPainAreaExerciseCard(results, "rightcalf")
        );
      },
      json: jsonFile.pain.calfExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    hamstringExerciseCard: {
      id: "exercise_hamstring",
      showAnswer: results => {
        return (
          shouldShowPainAreaExerciseCard(results, "lefthamstring") ||
          shouldShowPainAreaExerciseCard(results, "righthamstring")
        );
      },
      json: jsonFile.pain.hamstringExerciseCard,
      linkType: () => LinkTypes.VIDEO
    },
    abdominalExerciseCard: {
      id: "exercise_abs",
      showAnswer: results => {
        return shouldShowPainAreaExerciseCard(results, "abs");
      },
      json: jsonFile.pain.abdominalExerciseCard,
      linkType: () => LinkTypes.VIDEO
    }
  };
}

function shouldShowGeneralExerciseCardIfPregnant(results) {
  // if first trimester always show general exercise
  if (results.health.pregnancyTrimester === 1) {
    return true;
  }

  // if not first trimester only show general exercise as long as pain level is between 1-3
  // if there is abs pain do not show general exercise regardless
  let painAreas = Object.values(results.pain.areas);
  let allAreasThreeOrLess = painAreas.every(function (area) {
    return area.level <= 3;
  });
  return allAreasThreeOrLess;
}

function shouldShowPainAreaExerciseCard(results, area) {
  let painAreaInResults = results.pain.areas[area];
  if (painAreaInResults === undefined) {
    return false;
  }

  // if not pregnant then always show exercise card if displaying pain
  let userNotPregnant = results.health.pregnancy !== 1;
  if (userNotPregnant) {
    return true;
  }

  // if second or third trimester and area is ab pain - do not show recommendations
  let lateStagePregnancy =
    results.health.pregnancyTrimester === 2 ||
    results.health.pregnancyTrimester === 3;
  if (lateStagePregnancy && area == "abs") {
    return false;
  }

  // if pregnant and area is between 1-3 then shown the exercise card
  return painAreaInResults.level <= 3;
}

function userIsHighRisk(results) {
  if (!results?.pain?.areas || Object.keys(results.pain.areas).length === 0) {
    return false;
  }

  // For each body area, check if it meets all high risk criteria
  return Object.values(results.pain.areas).some(area => {
    // 1. Pain level must be 7 or higher
    const hasSeverePain = area.level >= 7;

    // 2. Duration must be longer than 1 month
    // Duration values: 1 = less than week, 2 = week to month, 3 = 1-3 months, 4 = more than 3 months
    const hasLongDuration = area.duration >= 3;

    // 3. Pain is getting worse
    // Improvement values: 1 = improved, 2 = same, 3 = worse
    const isGettingWorse = area.improvement === 3;

    // Return true if all three conditions are met
    return hasSeverePain && hasLongDuration && isGettingWorse;
  });
}

function getUtilityService(type = "setup") {
  const isV2 = store.getters["deskAssessmentSchemaStore/schemaVersion"];
  if (!isV2) return DeskAssessmentResultsUtil;

  switch (type) {
    case "pain":
      return PainHelper;
    case "setup":
      return DeskSetupScoreHelper;
    case "equipment":
      return EquipmentScoreHelper;
    case "habits":
      return WorkHabitsScoreHelper;
    case "environment":
      return EnvironmentScoreHelper;
    default:
      return DeskAssessmentResultsUtil;
  }
}

export { DefaultExerciseRecommendations, DeskAssessmentRecommendations };
