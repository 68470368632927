import BaseScoreHelper from "./base-score-helper";
import * as WebcamValues from "@/services/deskassessment/webcam-values.js";
import { withErrorHandling } from "@/components/assessment/desk/results-helpers/error-handling.js";

export default class DeskSetupScoreHelper extends BaseScoreHelper {
  static calculateDeskSetupScore(results) {
    if (!results?.webcam?.pictureTaken) {
      return 0;
    }

    return (
      DeskSetupScoreHelper.getScreenDistanceScore(results) +
      DeskSetupScoreHelper.getScreenHeightScore(results) +
      DeskSetupScoreHelper.getShoulderHeightScore(results) +
      DeskSetupScoreHelper.getShoulderToEarScore(results)
    );
  }

  static getMaxWebcamScore(results) {
    return results?.webcam?.shoulderToEarDifference === null ? 6 : 8;
  }

  static getScreenDistanceScore(results) {
    const range = WebcamValues.getScreenDistanceRange(
      results?.webcam?.distanceFromScreen
    );

    switch (range) {
      case WebcamValues.RangeConstants.screenDistance.tooFar:
      case WebcamValues.RangeConstants.screenDistance.tooClose:
        return 2;
      case WebcamValues.RangeConstants.screenDistance.far:
      case WebcamValues.RangeConstants.screenDistance.close:
        return 1;
      default:
        return 0;
    }
  }

  static getScreenHeightScore(results) {
    const range = WebcamValues.getScreenHeightRange(
      results?.webcam?.screenHeightAngle
    );

    switch (range) {
      case WebcamValues.RangeConstants.screenHeight.tooHigh:
      case WebcamValues.RangeConstants.screenHeight.tooLow:
        return 2;
      case WebcamValues.RangeConstants.screenHeight.high:
      case WebcamValues.RangeConstants.screenHeight.low:
        return 1;
      default:
        return 0;
    }
  }

  static getShoulderHeightScore(results) {
    const range = WebcamValues.getShoulderHeightRange(
      results?.webcam?.shoulderHeightDifference
    );

    switch (range) {
      case WebcamValues.RangeConstants.generic.bad:
        return 2;
      case WebcamValues.RangeConstants.generic.average:
        return 1;
      default:
        return 0;
    }
  }

  static getShoulderToEarScore(results) {
    const range = WebcamValues.getShoulderToEarRange(
      results?.webcam?.shoulderToEarDifference
    );

    switch (range) {
      case WebcamValues.RangeConstants.generic.bad:
        return 2;
      case WebcamValues.RangeConstants.generic.average:
        return 1;
      default:
        return 0;
    }
  }

  static getDeskSetupPercentage(results) {
    return this.getPercentageScore(
      this.calculateDeskSetupScore(results),
      this.getMaxWebcamScore(results)
    );
  }

  static getDeskSetupWeight(results) {
    return results?.webcam?.pictureTaken ? 0.35 : 0;
  }
}

DeskSetupScoreHelper.calculateDeskSetupScore = withErrorHandling(
  DeskSetupScoreHelper.calculateDeskSetupScore,
  0
);
DeskSetupScoreHelper.getScreenDistanceScore = withErrorHandling(
  DeskSetupScoreHelper.getScreenDistanceScore,
  0
);
DeskSetupScoreHelper.getScreenHeightScore = withErrorHandling(
  DeskSetupScoreHelper.getScreenHeightScore,
  0
);
DeskSetupScoreHelper.getShoulderHeightScore = withErrorHandling(
  DeskSetupScoreHelper.getShoulderHeightScore,
  0
);
DeskSetupScoreHelper.getShoulderToEarScore = withErrorHandling(
  DeskSetupScoreHelper.getShoulderToEarScore,
  0
);
DeskSetupScoreHelper.getDeskSetupPercentage = withErrorHandling(
  DeskSetupScoreHelper.getDeskSetupPercentage,
  0
);
DeskSetupScoreHelper.getDeskSetupWeight = withErrorHandling(
  DeskSetupScoreHelper.getDeskSetupWeight,
  0
);
