const calculateDeskAssessmentResultsV2 = (
  results,
  assessmentDataPointValue,
  schema
) => {
  Object.entries(schema).forEach(([themeKey, themeData]) => {
    themeData.pages.forEach(page => {
      if (themeKey === "pain") {
        handlePainQuestionsV2(results, assessmentDataPointValue, page);
      } else if (
        themeKey === "health" ||
        themeKey === "setup" ||
        page.name === "webcamInstructions"
      ) {
        calculateGeneralSchemaResultsV2(
          results,
          assessmentDataPointValue,
          page,
          themeKey
        );
      }
    });
  });
};

const handlePainQuestionsV2 = (results, assessmentDataPointValue, page) => {
  if (!page.questions) return;

  Object.values(page.questions).forEach(questions => {
    questions.forEach(question => {
      if (assessmentDataPointValue.name === question.name) {
        if (!results.pain.areas[assessmentDataPointValue.group]) {
          results.pain.areas[assessmentDataPointValue.group] = {};
        }

        results.pain.areas[assessmentDataPointValue.group][
          assessmentDataPointValue.name
        ] = assessmentDataPointValue.intValue;
      }
    });
  });
};

const calculateGeneralSchemaResultsV2 = (
  results,
  assessmentDataPointValue,
  page,
  themeKey
) => {
  if (!page.questions) return;

  const questions = Array.isArray(page.questions)
    ? page.questions
    : Object.values(page.questions).flat();

  questions.forEach(question => {
    if (assessmentDataPointValue.name === question.name) {
      if (!results[themeKey]) {
        results[themeKey] = {};
      }

      results[themeKey][assessmentDataPointValue.name] =
        assessmentDataPointValue.floatValue ||
        assessmentDataPointValue.intValue;
    }
  });
};

const calculateCheckBoxListQuestionResultsV2 = (
  results,
  assessmentDataPointCheckBoxListQuestionValue,
  schema
) => {
  Object.entries(schema).forEach(([themeKey, themeData]) => {
    themeData.pages.forEach(page => {
      if (themeKey === "pain") {
        handlePainCheckboxListV2(
          results,
          assessmentDataPointCheckBoxListQuestionValue,
          page
        );
      } else if (themeKey === "health" || themeKey === "setup") {
        handleGeneralCheckboxListV2(
          results,
          assessmentDataPointCheckBoxListQuestionValue,
          page,
          themeKey
        );
      }
    });
  });
};

const handlePainCheckboxListV2 = (results, checkboxValue, page) => {
  if (!page.questions) return;

  Object.values(page.questions).forEach(questions => {
    questions.forEach(question => {
      if (checkboxValue.name === question.name && checkboxValue.values) {
        if (!results.pain.areas[checkboxValue.group]) {
          results.pain.areas[checkboxValue.group] = {};
        }

        results.pain.areas[checkboxValue.group][checkboxValue.name] =
          checkboxValue.values.map(a => a.intValue);
      }
    });
  });
};

const handleGeneralCheckboxListV2 = (
  results,
  checkboxValue,
  page,
  themeKey
) => {
  if (!page.questions) return;

  const questions = Array.isArray(page.questions)
    ? page.questions
    : Object.values(page.questions).flat();

  questions.forEach(question => {
    if (checkboxValue.name === question.name && checkboxValue.values) {
      if (!results[themeKey]) {
        results[themeKey] = {};
      }

      results[themeKey][checkboxValue.name] = checkboxValue.values.map(
        a => a.intValue
      );
    }
  });
};

export {
  calculateDeskAssessmentResultsV2,
  calculateCheckBoxListQuestionResultsV2,
  calculateGeneralSchemaResultsV2
};
