<template>
  <div class="dialog-container">
    <button class="close-button" @click="$emit('closeDialog')">
      <span class="d-sr-only">{{ $t("buttons.close") }}</span>
      <v-icon color="vflNeutralDark2" size="20">mdi-close</v-icon>
    </button>
    <div class="dialog-content">
      <div class="image-column">
        <img :src="imageSource" width="90%" />
      </div>

      <div class="content-column">
        <h5 class="title text-h5">
          {{ $t(`addFamily.dialog.${sent ? "sent" : "form"}.title`) }}
        </h5>

        <p class="text-body-1 text-body-dark">
          {{
            $t(`addFamily.dialog.${sent ? "sent" : "form"}.text`, {
              name: name || $t("addFamily.dialog.sent.they")
            })
          }}
        </p>

        <div v-if="!sent" class="mt-4">
          <v-form ref="form" v-model="valid" @submit.prevent>
            <v-text-field
              outlined
              v-model="name"
              dense
              :label="$t('addFamily.dialog.form.nameField')"
            />
            <v-text-field
              outlined
              v-model="email"
              dense
              :label="$t('addFamily.dialog.form.emailField')"
              :rules="[rules.emailRequired, rules.emailValid]"
            />
          </v-form>
        </div>
        <div v-else>
          <button class="vfl-button-link" @click="resetDialog">
            {{ $t("addFamily.dialog.sent.sendAnother") }}
          </button>
        </div>

        <div class="button-container">
          <vfl-button v-if="sent" @click="$emit('closeDialog')">
            {{ $t("addFamily.dialog.sent.button") }}
          </vfl-button>

          <vfl-button v-else @click="handleSubmit">
            {{ $t("addFamily.dialog.form.button") }}
          </vfl-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VflButton from "@/components/vitrue-foundation-library/VflButton.vue";
import Rules from "@/utils/stringValidation";

export default {
  name: "AddFamilyDialog",
  components: {
    VflButton
  },
  data() {
    return {
      name: "",
      email: "",
      valid: false,
      sent: false,
      rules: Rules
    };
  },
  computed: {
    imageSource() {
      return this.sent
        ? "/images/add-family-dialog-sent.svg"
        : "/images/add-family-dialog-form.svg";
    }
  },
  methods: {
    resetDialog() {
      this.name = "";
      this.email = "";
      this.sent = false;
    },
    handleSubmit() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.sent = true;
    }
  }
};
</script>

<style lang="scss" scoped>
$breakpoint: 710px;

.dialog-container {
  background: white;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
}

.close-button {
  position: absolute;
  right: 26px;
  top: 16px;
}

.dialog-content {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 500px;

  @media (min-width: $breakpoint) {
    grid-template-columns: 1fr 1fr;
  }
}

.image-column {
  background: var(--v-vitruePaleGrey-base);
  display: flex;
  align-content: center;
  justify-content: center;

  @media (max-width: $breakpoint) {
    img {
      max-height: 200px;
    }
  }
}

.content-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3rem 2rem;
}

.title {
  margin-bottom: 16px;
}

.vfl-button-link {
  align-self: start;
}

.button-container {
  display: flex;
  justify-content: start;
  margin-top: auto;
}

p::first-letter {
  text-transform: uppercase;
}
</style>
