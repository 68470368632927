<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="143"
      height="100"
      transform="translate(307 123)"
      fill="white"
    />
    <path
      d="M441.836 130.156C442.388 130.156 442.836 130.604 442.836 131.156V214.844C442.836 215.396 442.388 215.844 441.836 215.844H315.164C314.612 215.844 314.164 215.396 314.164 214.844V131.156C314.164 130.604 314.612 130.156 315.164 130.156H441.836ZM307 222C307 222.552 307.448 223 308 223H449C449.552 223 450 222.552 450 222V124C450 123.448 449.552 123 449 123L308 123C307.448 123 307 123.448 307 124V222Z"
      fill="#934B2A"
    />
    <g opacity="0.6">
      <path d="M423 206.908H333V138.999H423V206.908Z" fill="white" />
      <path
        d="M424.636 199.363C424.636 199.916 424.189 200.363 423.636 200.363H334C333.448 200.363 333 199.916 333 199.363V144.909C333 144.356 333.448 143.909 334 143.909H423.636C424.189 143.909 424.636 144.356 424.636 144.909V199.363Z"
        fill="#2E2838"
      />
      <path
        d="M343.637 185.83L362.091 151.273C362.091 151.273 379.292 158.026 398.284 151.538L416.918 185.565C416.918 185.565 381.442 202.116 343.637 185.83Z"
        fill="#4B4259"
      />
      <path
        d="M402.099 164.175C401.951 163.695 401.769 163.226 401.539 162.776C400.693 161.116 399.174 159.857 397.21 159.458C392.854 158.574 389.242 160.832 385.219 161.772C383.624 162.145 381.92 162.246 380.344 161.811C378.924 161.419 377.686 160.62 376.348 160.033C372.732 158.45 368.29 158.642 364.562 159.828C361.396 160.835 358.69 163.151 357.568 166.083C356.649 168.485 356.741 171.138 357.361 173.621C359.389 181.747 366.635 188.519 375.965 188.635C380.939 188.696 385.776 187.232 390.219 185.277C394.172 183.538 398.204 181.385 400.553 177.85C402.785 174.49 403.151 170.317 402.579 166.413C402.469 165.659 402.324 164.905 402.099 164.175Z"
        fill="#2E2838"
      />
      <path
        d="M387.001 173.433C387.001 175.619 385.403 175.169 384.268 174.799C383.784 174.641 383.708 174.129 383.206 174.041C382.652 173.943 381.84 173.282 381.84 174.496C381.84 175.71 380.777 175.558 380.625 175.255C380.474 174.951 379.411 172.366 379.411 174.799C379.411 176.317 379.411 177.126 378.804 177.531C375.616 179.656 374.097 176.621 373.945 175.862C373.794 175.103 373.945 170.398 378.348 169.487C382.75 168.577 387.001 170.702 387.001 173.433Z"
        fill="#787185"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M150.238 392L189.8 528.325L208.407 630.68H227.084L208.441 392H150.238Z"
      fill="#997659"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M132.46 392C135.221 462.82 134.132 501.447 133.514 507.882C132.896 514.316 129.389 557.409 105.488 632.84H124.913C156.566 560.477 167.616 517.384 171.246 507.882C174.876 498.38 185.598 459.752 201.252 392H132.46Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M149.391 392C159.681 438.91 177.479 513.79 202.785 616.64H230.102C232.119 511.074 223.536 441.594 208.673 392H149.391Z"
      fill="#17282F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M132.326 392C135.03 462.82 128.023 532.591 106.32 617.743H135.466C167.176 546.459 189.904 477.032 205.437 392L136 372.5C136 372.5 133.735 377.869 133 381.5C132.185 385.527 132.326 392 132.326 392Z"
      fill="#223A43"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M102.719 646.879L103.707 630.949C103.75 630.257 104.422 629.784 105.1 629.928C114.46 631.917 127.371 627.439 127.371 627.439C140.364 636.643 159.096 640.867 165.691 642.132C166.968 642.376 167.786 643.664 167.432 644.914L165.956 650.119H127.371H104.862L102.719 646.879Z"
      fill="#17282F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M204.238 646.879L205.226 630.949C205.269 630.257 205.942 629.784 206.619 629.928C215.98 631.917 228.89 627.439 228.89 627.439C241.883 636.643 260.615 640.867 267.211 642.132C268.487 642.376 269.306 643.664 268.951 644.914L267.475 650.119H228.89H206.382L204.238 646.879Z"
      fill="#17282F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M170.975 230.091C179.589 235.283 188.053 237.791 192.026 236.767C201.988 234.199 202.904 197.754 195.196 183.386C187.488 169.019 147.86 162.756 145.863 191.223C145.17 201.104 149.318 209.921 155.448 217.116L144.457 268.342H176.382L170.975 230.091Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M198.211 189.839C197.895 187.976 197.122 185.908 196.498 184.146C195.695 181.875 194.478 179.827 193.139 177.897C190.618 174.263 187.594 170.981 184.255 168.258C178.156 163.283 170.393 160.414 162.759 161.345C158.904 161.816 155.162 163.286 151.977 165.733C149.1 167.945 146.332 171.2 142.606 171.433C138.552 171.688 134.836 168.109 131.584 165.899C127.918 163.408 124.019 161.592 119.734 160.887C112.556 159.705 105.813 162.13 100.866 168.019C95.6024 174.286 91.7758 183.476 95.2328 191.779C95.8753 193.323 96.702 194.624 97.8884 195.702C98.9765 196.691 100.638 197.744 101.079 199.324C101.548 201.004 100.148 203.171 99.6054 204.686C98.8278 206.858 98.1894 209.137 98.4163 211.497C98.7893 215.373 101.225 219.074 103.797 221.621C106.415 224.214 109.636 225.7 113.077 226.386C115.375 226.843 117.723 227.096 120.058 226.924C121.217 226.839 122.24 226.517 123.363 226.261C124.455 226.012 125.066 226.302 125.992 226.901C130.297 229.684 134.925 230.76 139.896 230.346C144.091 229.997 148.926 228.98 152.348 226.106C156.148 222.914 156.073 218.512 155.178 213.809C156.126 214.279 158.747 214.569 157.181 212.698C156.56 211.956 155.388 211.655 154.592 211.219C153.665 210.711 152.723 210.055 152.003 209.227C149.097 205.883 152.033 199.079 155.686 197.958C161.114 196.292 162.329 203.968 166.204 205.954C168.432 207.095 170.762 205.535 172.431 203.942C174.667 201.807 176.191 198.979 177.507 196.102C178.591 193.732 179.599 191.329 180.704 188.971C181.219 187.87 183.005 184.757 182.108 183.512C185.996 182.736 190.326 184.352 193.675 186.471C195.059 187.347 196.114 188.312 196.852 189.858C197.01 190.19 197.354 191.175 197.787 191.215C198.591 191.29 198.291 190.307 198.211 189.839Z"
      fill="#070707"
    />
    <path
      d="M129.286 290.242C127.433 281.869 129.942 272.652 135.784 266.373C137.271 264.774 139.439 263.258 141.483 264.028C143.529 264.8 144.156 267.385 144.164 269.572C144.196 278.675 138.135 287.495 129.626 290.727"
      fill="#E7E8E5"
    />
    <path
      d="M136.773 272.692C136.729 272.662 136.44 272.994 135.955 273.625C135.462 274.25 134.809 275.199 134.034 276.394C132.554 278.801 130.543 282.305 129.709 286.655C129.299 288.821 129.283 290.951 129.55 292.842C129.81 294.74 130.411 296.367 130.977 297.682C131.59 298.98 132.161 299.991 132.639 300.632C133.096 301.287 133.368 301.632 133.414 301.602C133.548 301.518 132.543 300.061 131.492 297.466C131.007 296.158 130.481 294.572 130.271 292.744C130.055 290.921 130.09 288.888 130.485 286.802C131.285 282.614 133.168 279.137 134.514 276.68C135.916 274.234 136.878 272.765 136.773 272.692Z"
      fill="#DADBD8"
    />
    <path
      d="M178.87 251.447C179.126 251.759 192.227 269.606 192.227 269.606C192.227 269.606 212.004 295.084 213.858 303.444C216.6 315.808 210.993 321.599 210.993 321.599C210.993 321.599 207.208 356.215 208.501 365.999C209.793 375.784 209.414 379.103 210 387.499C210.545 395.311 211.399 407.504 211.399 407.504C211.399 407.504 134.895 394.99 128.434 386.313L137.887 361.659L127.549 290.584C127.549 290.584 124.381 262.186 133.195 254.959C138.942 250.248 147.494 245.999 147.494 245.999L178.87 251.447Z"
      fill="#F8A531"
    />
    <path
      d="M176.13 328.292C176.132 328.318 176.561 328.322 177.333 328.243C178.105 328.166 179.217 327.969 180.547 327.562C183.191 326.771 186.748 324.909 189.499 321.615C192.246 318.317 193.44 314.484 193.744 311.74C193.908 310.359 193.9 309.23 193.838 308.457C193.777 307.684 193.696 307.263 193.67 307.266C193.57 307.269 193.82 308.983 193.417 311.695C193.035 314.384 191.818 318.102 189.14 321.317C186.46 324.525 183.02 326.391 180.444 327.248C177.848 328.131 176.116 328.192 176.13 328.292Z"
      fill="#C68427"
      stroke="#C68427"
    />
    <path
      d="M177.813 331.52C180.62 331.52 183.509 330.438 185.696 328.63C189.172 325.757 191.138 321.447 192.285 317.072C192.257 317.136 192.229 317.201 192.2 317.265C191.837 318.272 191.243 319.299 190.499 320.292C190.191 320.74 189.857 321.183 189.496 321.616C186.745 324.909 183.189 326.771 180.544 327.563C179.82 327.785 179.161 327.944 178.586 328.055C175.529 329.113 172.599 330.008 172.599 330.008C174.152 331.05 175.964 331.52 177.813 331.52Z"
      fill="#DB8E24"
    />
    <path
      d="M178.588 328.053C179.164 327.942 179.822 327.783 180.547 327.561C183.191 326.769 186.747 324.907 189.499 321.614C189.859 321.181 190.193 320.738 190.502 320.29C188.482 322.988 185.362 325.427 182.798 326.487C181.577 326.991 180.067 327.541 178.588 328.053ZM192.202 317.263C192.231 317.199 192.26 317.134 192.287 317.069C192.33 316.905 192.373 316.74 192.414 316.576C192.357 316.803 192.285 317.033 192.202 317.263Z"
      fill="#0F191D"
    />
    <path
      d="M192.053 404.141C195.595 404.87 199.21 405.058 202.722 404.462L203.239 401.23C203.176 400.685 203.03 400.148 202.787 399.63C202.062 398.086 200.916 396.785 199.758 395.533C184.107 378.609 163.842 366.794 143.899 355.233C143.637 355.08 143.359 354.924 143.068 354.847C141.6 356.667 139.964 358.699 138.275 360.809C138.098 361.383 137.986 361.976 137.964 362.589C137.89 364.619 138.814 366.55 139.885 368.276C145.175 376.796 154.151 382.168 162.383 387.898C168.485 392.145 174.373 396.769 180.993 400.152C184.441 401.915 188.204 403.348 192.053 404.141Z"
      fill="#DB8E24"
    />
    <path
      d="M124.41 286.011C124.663 279.958 125.92 267.062 132.477 259.748C140.361 250.998 149.65 251.867 155.581 262.044C161.629 272.42 158.81 283.194 159.251 288.349C159.795 294.706 156.741 317.418 156.228 322.786L155.755 341.879C155.755 341.879 196.811 384.007 205.848 388.518L196.811 403.072C186.264 402.026 138.237 370.086 128.161 350.669C124.001 342.654 124.158 292.063 124.41 286.011Z"
      fill="#B28B67"
    />
    <path
      d="M159.859 307.264L120.999 299.446C120.999 299.446 124.194 275.147 128.477 263.341C131.808 254.157 142.153 250.698 142.153 250.698L159.236 261.018C159.236 261.018 162.158 274.842 161.929 287.488C161.698 300.136 159.859 307.264 159.859 307.264Z"
      fill="#F8A531"
    />
    <path
      d="M138.239 256.067C138.237 256.083 138.465 256.118 138.899 256.171C139.378 256.227 140.009 256.301 140.797 256.392C142.439 256.601 144.839 256.856 147.688 257.639C150.504 258.411 153.871 259.881 156.382 262.879C158.892 265.856 160.333 270.062 160.818 274.521C161.367 278.999 160.83 283.326 160.701 287.242C160.514 291.152 160.344 294.678 160.202 297.658C160.078 300.585 159.977 302.973 159.905 304.684C159.881 305.477 159.862 306.113 159.848 306.595C159.839 307.032 159.843 307.263 159.859 307.264C159.875 307.265 159.903 307.036 159.942 306.6C159.978 306.119 160.026 305.485 160.086 304.694C160.194 302.985 160.345 300.599 160.531 297.674C160.703 294.693 160.905 291.168 161.131 287.257C161.283 283.342 161.837 279.02 161.282 274.468C160.791 269.952 159.313 265.651 156.71 262.601C155.415 261.086 153.884 259.922 152.334 259.082C150.783 258.23 149.214 257.708 147.775 257.321C144.88 256.569 142.467 256.365 140.816 256.211C139.988 256.141 139.344 256.099 138.907 256.078C138.47 256.055 138.24 256.051 138.239 256.067Z"
      fill="#C68427"
      stroke="#C68427"
    />
    <path
      d="M197.95 385.943C197.95 385.943 207.574 380.412 210.663 380.151C213.753 379.891 218.192 383.803 218.192 383.803C218.192 383.803 218.212 385.022 217.832 386.292C217.832 386.292 221.292 392.095 217.66 395.548C217.66 395.548 219.623 398.147 216.393 401.549C216.393 401.549 215.686 403.948 212.901 405.799C212.901 405.799 212.727 410.029 209.758 411.225C206.789 412.42 204.549 411.892 204.549 411.892C204.549 411.892 194.853 404.416 191.5 400.5L197.95 385.943Z"
      fill="#B28B67"
    />
    <path
      d="M205.876 363.457C206.97 363.445 208.059 363.195 209.032 362.697C209.029 362.635 209.025 362.57 209.022 362.507L208.917 362.497C208.948 362.41 208.979 362.323 209.009 362.236C208.607 354.017 208.051 341.625 207.955 334.321C207.487 333.882 206.936 333.554 206.286 333.392C205.975 333.314 205.668 333.279 205.365 333.282C203.272 333.304 201.388 335.125 199.934 336.797C197.903 339.132 195.871 341.468 193.839 343.805C192.85 344.943 191.813 346.185 191.668 347.686C191.492 349.511 192.671 351.16 193.791 352.612C195.534 354.871 197.276 357.132 199.018 359.392C199.727 360.311 200.445 361.24 201.356 361.961C202.628 362.966 204.257 363.473 205.876 363.457Z"
      fill="#DB8E24"
    />
    <path
      d="M215.39 340.239C215.482 339.908 215.926 336.578 214.162 334.781C212.396 332.983 206.393 331.306 206.393 331.306L200.152 335.074C200.152 335.074 199.701 337.568 203.302 338.325C203.302 338.325 194.982 340.43 194.37 342.642C193.759 344.854 194.86 346.467 194.86 346.467C194.86 346.467 191.145 346.988 191.278 349.524C191.409 352.061 193.713 352.341 193.713 352.341C193.713 352.341 191.881 355.524 195.843 356.262C199.806 357 206.216 356.63 206.216 356.63C206.216 356.63 205.955 358.868 203.689 358.456C201.422 358.042 199.423 358.8 199.423 358.8C199.423 358.8 198.109 361.413 200.684 362.006C203.258 362.597 205.457 363.682 208.258 363.027C211.057 362.373 213.361 362.653 213.782 360.269C214.203 357.886 213.993 357.352 213.993 357.352C213.993 357.352 216.478 354.943 215.814 352.975C215.152 351.006 215.152 351.006 215.152 351.006C215.152 351.006 217.907 348.791 217.1 346.544C216.292 344.297 215.104 341.707 215.104 341.707L215.39 340.239Z"
      fill="#B28B67"
    />
    <path
      d="M194.867 346.347C194.88 346.414 195.799 346.277 197.28 346.044C198.761 345.812 200.806 345.494 203.071 345.189C205.336 344.886 207.392 344.655 208.883 344.489C210.372 344.324 211.294 344.214 211.29 344.147C211.287 344.085 210.356 344.079 208.855 344.161C207.354 344.243 205.283 344.422 203.008 344.726C200.734 345.032 198.69 345.403 197.22 345.719C195.75 346.036 194.854 346.287 194.867 346.347Z"
      fill="#B77A23"
    />
    <path
      d="M193.195 352.208C193.208 352.335 196.952 352.05 201.556 351.571C206.16 351.091 209.882 350.598 209.868 350.47C209.855 350.342 206.112 350.627 201.507 351.107C196.903 351.587 193.181 352.08 193.195 352.208Z"
      fill="#B77A23"
    />
    <path
      d="M199.435 338.842C199.45 338.971 201.461 338.846 203.927 338.565C206.395 338.284 208.383 337.952 208.368 337.825C208.353 337.696 206.342 337.82 203.875 338.102C201.408 338.383 199.42 338.715 199.435 338.842Z"
      fill="#B77A23"
    />
  </svg>
</template>

<script>
export default {
  name: "FirstTrimester"
};
</script>
