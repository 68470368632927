<template>
  <div ref="el" class="pain-person-placeholder"></div>
</template>

<script>
const DEFAULT_TOP = 104;

export default {
  name: "PainPersonPlaceholder",
  mounted() {
    this.setPosition();
  },
  methods: {
    setPosition() {
      const el = this.$refs.el;

      const top = el
        ? el.getBoundingClientRect().top + window.scrollY
        : DEFAULT_TOP;

      document.documentElement.style.setProperty(
        "--pain-person-placeholder-top",
        `${top}px`
      );
    }
  }
};
</script>
<style lang="scss" scoped>
div {
  height: 1px;
}
</style>
