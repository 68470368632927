export const REPORT_SECTION_CONFIG = [
  {
    id: "header",
    component: "pregnancy-report-header",
    isVisible: () => true
  },
  {
    id: "pregnancyIntroduction",
    component: "pregnancy-report-introduction",
    isVisible: () => true,
    skipSectionNumber: true
  },
  {
    id: "pain",
    component: "pregnancy-report-pain",
    isVisible: vm => vm.hasPain
  },
  {
    id: "noPain",
    component: "pregnancy-report-no-pain",
    isVisible: vm => !vm.hasPain
  },
  {
    id: "balance",
    component: "pregnancy-report-balance",
    isVisible: () => true
  },
  {
    id: "circulation",
    component: "pregnancy-report-circulation",
    isVisible: () => true
  },
  {
    id: "postnatalRecovery",
    component: "pregnancy-report-postnatal",
    isVisible: () => true
  }
];
