<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4896_54130)">
      <circle cx="599.5" cy="249.5" r="62.5" fill="#8AAFAF" />
      <g clip-path="url(#clip1_4896_54130)">
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="#C6DDDD"
        />
        <path
          d="M556.738 205.317L554.586 219.879L569.11 217.688L564.221 212.798L560.536 209.113L556.738 205.317Z"
          fill="#8AAFAF"
        />
        <path
          d="M562.991 223.304L562.373 223.32V223.32L562.991 223.304Z"
          fill="#616E7D"
        />
        <path
          d="M565.662 233.293C562.975 230.603 561.462 227.404 561.159 223.778L557.42 224.196L557.369 224.202L557.319 224.203C557.027 224.213 556.643 224.228 556.263 224.275C556.182 224.285 556.101 224.296 556.02 224.308C556.496 229.082 558.606 233.548 562.006 236.948C565.937 240.88 571.164 243.045 576.724 243.045C581.966 243.045 586.912 241.118 590.755 237.602L587.093 233.941C584.235 236.478 580.592 237.868 576.734 237.868C572.545 237.868 568.613 236.244 565.662 233.293Z"
          fill="#A7A6B4"
        />
        <path
          d="M576.564 201.577C571.004 201.577 565.778 203.742 561.847 207.673C561.384 208.135 560.948 208.616 560.535 209.114L564.22 212.799C564.619 212.286 565.039 211.791 565.502 211.328C568.453 208.379 572.378 206.754 576.554 206.753C580.743 206.754 584.676 208.379 587.627 211.328C590.476 214.179 592.088 217.947 592.191 221.977C593.903 221.695 595.619 221.42 597.335 221.145C597.034 216.053 594.9 211.292 591.282 207.673C587.351 203.742 582.125 201.577 576.566 201.577H576.564Z"
          fill="#A7A6B4"
        />
        <path
          d="M587.093 233.942L590.754 237.603L594.503 241.352L596.694 226.827L582.131 228.979L587.093 233.942Z"
          fill="#8AAFAF"
        />
      </g>
      <path
        d="M660.105 280.971C659.476 280.971 658.919 280.567 658.723 279.97L649.14 250.663C648.466 248.601 646.542 247.205 644.372 247.205H623.63C621.503 247.205 619.608 248.545 618.899 250.549L608.482 280.002C608.277 280.583 607.728 280.971 607.113 280.971C606.135 280.971 605.441 280.066 605.686 279.144L615.506 242.304H652.671L661.516 279.179C661.735 280.093 661.044 280.971 660.105 280.971Z"
        fill="#63352C"
      />
      <path
        d="M608.276 248.322H655.025C656.178 248.322 657.248 247.714 657.837 246.721L677.871 212.987C677.871 212.987 654.946 191.365 650 189C645.053 186.636 645.765 203.675 650 224C635.924 221.527 620.782 221.575 609.534 227.026C603.429 230.768 604.237 240.739 605.05 245.593C605.313 247.169 606.678 248.322 608.276 248.322Z"
        fill="#A7A6B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_4896_54130">
        <rect
          x="535"
          y="178"
          width="127"
          height="134"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_4896_54130">
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ChangingLegPosition"
};
</script>
