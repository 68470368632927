<template>
  <auth-layout :is-report-page-sign-up="reportPageSignUp">
    <div class="signup-container">
      <v-row no-gutters>
        <v-col v-if="!loading">
          <login-options
            v-show="!confirm"
            signUp
            :errorMsg="errorMessage"
            :reportPageSignUp="reportPageSignUp"
            :enterpriseSSOLoginAvailable="showEnterpriseSSOLogin"
            :ssoIdpName="teamIdpName"
            :simpleUserSignup="simpleUserSignup"
            :codeConfirmSignIn="codeConfirmed"
            :assessmentId="assessmentId"
            :isReturningToPartialAssessment="isReturningToPartialAssessment"
            @setInitialSignupStep="firstSignUpStep = $event"
            @confirmSignUp="confirmSignUp($event)"
            @regularSignInComplete="$emit('regularSignInComplete')"
            :autoJoinTeamId="autoJoinTeamId"
            :autoJoinTeamInfo="autoJoinTeamInfo"
            @error="confirm = false"
            @user-exists="$emit('user-exists', $event)"
          />
          <confirm-code
            v-show="confirm"
            signUp
            :email="email"
            :password="password"
            :reportPageSignUp="reportPageSignUp"
            :assessmentId="assessmentId"
            @confirmed="confirm = false"
          />
        </v-col>

        <v-col
          v-if="showJoinCreateTeamSwitch"
          cols="12"
          align-self="end"
          class="text-center text-body-1 mt-4"
          >{{
            simpleUserSignup
              ? $t("authentication.signUp.simpleUser.text")
              : $t("authentication.signUp.regularUser.text")
          }}

          <button
            class="vfl-link"
            @click="simpleUserSignup = !simpleUserSignup"
          >
            {{
              " " +
              (simpleUserSignup
                ? $t("authentication.signUp.simpleUser.link")
                : $t("authentication.signUp.regularUser.link"))
            }}
          </button>
        </v-col>
      </v-row>
    </div>
  </auth-layout>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import LoginOptions from "./LoginOptions.vue";
import ConfirmCode from "./ConfirmCode.vue";
import { getJoiningTeamsInfo } from "@/customApi";
import { getSSOCustomisation } from "@/services/authorization/sso-customisation-service.js";
import { sanitizeGuid } from "@/utils/auth-validation.js";
import AuthLayout from "@/components/authentication/AuthLayout.vue";

export default {
  name: "SignUp",
  props: {
    reportPageSignUp: Boolean,
    errorMsg: String,
    assessmentId: String,
    isReturningToPartialAssessment: Boolean
  },
  data() {
    return {
      email: "",
      password: "",
      code: "",
      confirm: false,
      simpleUserSignup: false,
      firstSignUpStep: true,
      userExists: false,
      errorMessage: this.errorMsg,
      codeConfirmed: false,
      autoJoinTeamId: undefined,
      autoJoinTeamInfo: null,
      loading: true
    };
  },
  components: {
    AuthLayout,
    LoginOptions,
    ConfirmCode
  },
  async mounted() {
    await this.loadTeamInfo();

    if (this.$route.query.error) {
      this.errorMessage = this.$route.query.error;
    }

    if (this.signedIn) {
      this.$router.push("/", () => {});
    }
  },
  computed: {
    ...mapGetters(["teamIdpName", "teamId", "userEmail", "signedIn"]),
    showJoinCreateTeamSwitch() {
      return !this.autoJoinTeamId && this.firstStepOfMainPageSignUp;
    },
    highZoom() {
      return window.devicePixelRatio > 1.25;
    },
    showEnterpriseSSOLogin() {
      return getSSOCustomisation(this.teamIdpName) != null;
    },
    firstStepOfMainPageSignUp() {
      return !this.reportPageSignUp && !this.confirm && this.firstSignUpStep;
    }
  },
  methods: {
    ...mapMutations(["setTeamIdpName", "setTeamId"]),
    confirmSignUp(user) {
      this.email = user.email;
      this.password = user.password;
      this.confirm = true;
    },
    async loadTeamInfo() {
      try {
        this.loading = true;
        if (this.$route.query.autojointeamid) {
          this.autoJoinTeamId = sanitizeGuid(this.$route.query.autojointeamid);
          if (!this.autoJoinTeamId) {
            this.$logger.captureMessage(
              `Invalid guid for team ${this.$route.query.autojointeamid} when trying to load team info`,
              "error"
            );
            this.errorMessage = this.$t("authentication.invalidTeamId");
            return;
          }

          this.simpleUserSignup = !!this.autoJoinTeamId;
          this.autoJoinTeamInfo = await getJoiningTeamsInfo(
            this.autoJoinTeamId
          );
          if (this.autoJoinTeamInfo.idpName) {
            this.setTeamIdpName(this.autoJoinTeamInfo.idpName);
            this.setTeamId(this.autoJoinTeamInfo.teamId);
          }
        }
      } catch (ex) {
        this.$logger.captureException(ex);
        this.autoJoinTeamId = null;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.signup-container {
  margin: 0 auto;
  max-width: 550px;
}
</style>
