<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M371.53 449.572C371.368 451.116 371.04 459.173 371.53 461C372.172 463.398 373.653 464.397 374.5 465.5C375.713 467.079 376.363 469.042 376 471C375.517 473.606 375 474.336 371.126 475.688C366.059 477.456 364.058 487.995 358.5 492.5C356.791 493.886 354.153 494.016 351.739 494.016C349.284 494.016 346.722 493.902 345.877 492.744C345.031 491.586 344.711 489.909 345.324 488.568C346.364 486.291 350.876 483.761 353.919 480.743C358.085 476.612 358.875 464.681 358.875 464.681L356.59 450.084L371.53 449.572Z"
      fill="#33535F"
    />
    <path
      d="M334.294 278.748C333.759 280.087 322.783 310.872 331.082 339.782C339.38 368.693 345.002 379.668 344.199 390.375C343.396 401.083 356.049 460.791 356.049 460.791L373.132 458.474L363.876 333.893L368.023 327.86L363.876 283.835L334.294 278.748Z"
      fill="#17282F"
    />
    <path
      d="M328.357 324.846C328.292 324.189 328.12 322.39 328.061 321.16C328.075 321.165 328.09 321.168 328.105 321.172C328.165 322.389 328.248 323.614 328.357 324.846Z"
      fill="#A7A9A8"
    />
    <path
      d="M346.842 329.924C340.206 329.924 328.385 325.095 328.385 325.095C328.385 325.095 328.375 325.003 328.359 324.844C328.25 323.612 328.167 322.387 328.107 321.17C334.238 322.835 343.315 325.095 347.419 325.095C348.449 325.095 349.165 324.953 349.444 324.623C351.118 322.64 353.377 298.127 354.124 285.728C354.346 285.75 354.568 285.771 354.791 285.792C355.308 292.527 355.908 302.404 355.477 307.599C354.781 315.961 354.332 325.159 350.562 328.886C349.814 329.627 348.488 329.924 346.842 329.924Z"
      fill="#0F191D"
    />
    <path
      d="M376.321 269.113C374.983 269.648 361.062 285.442 361.062 285.442L368.023 327.86C369.88 329.739 380.475 331.119 384.998 331.852L375.07 332.738L377.905 348.94C377.905 348.94 426.542 359.779 437.785 351.748C449.028 343.718 444.829 329.645 441.081 325.094C437.334 320.543 414.869 303.109 390.509 296.417L376.321 269.113Z"
      fill="#17282F"
    />
    <path
      d="M372.331 332.657C372.331 332.657 366.485 330.496 362.737 331.513C358.989 332.529 359.624 334.181 359.497 337.23C359.37 340.28 363.276 346.316 363.53 351.246C363.785 356.176 363.343 369.633 365.55 372.174C365.55 372.174 368.424 372.746 368.091 370.871C367.757 368.997 369.496 364.396 370.494 361.327C371.239 359.031 373.316 349.452 377.906 348.615L375.07 332.738L372.331 332.657Z"
      fill="#33535F"
    />
    <path
      d="M429.896 343.61C429.857 343.646 429.255 342.975 428.049 341.899C426.855 340.814 424.979 339.379 422.396 338.087C419.812 336.803 416.535 335.687 412.788 334.924C409.046 334.134 404.858 333.603 400.47 333.062C396.077 332.554 391.869 332.246 388.045 331.91C384.219 331.585 380.777 331.218 377.901 330.799C375.024 330.382 372.714 329.925 371.134 329.549C370.34 329.371 369.735 329.191 369.321 329.082C368.909 328.968 368.695 328.9 368.699 328.885C368.703 328.869 368.925 328.905 369.344 328.989C369.809 329.085 370.416 329.211 371.173 329.367C372.762 329.687 375.073 330.092 377.947 330.466C380.82 330.841 384.257 331.172 388.082 331.473C391.904 331.784 396.117 332.079 400.525 332.589C404.923 333.132 409.114 333.675 412.88 334.495C416.648 335.286 419.955 336.448 422.549 337.787C425.144 339.134 427.011 340.628 428.174 341.762C428.791 342.296 429.179 342.803 429.478 343.109C429.764 343.427 429.908 343.599 429.896 343.61Z"
      fill="#33535F"
    />
    <path
      d="M380.441 268.078C380.448 267.984 380.453 267.886 380.46 267.783C380.461 267.784 380.462 267.785 380.464 267.786L380.441 268.078Z"
      fill="#A7A9A8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M361.456 177.145C367.278 180.654 372.999 182.35 375.684 181.657C382.418 179.922 383.037 155.287 377.827 145.576C372.617 135.864 345.831 131.631 344.481 150.873C344.013 157.551 346.817 163.511 350.96 168.374L343.531 203H365.11L361.456 177.145Z"
      fill="#B28B67"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M379.863 149.938C379.649 148.679 379.127 147.281 378.705 146.09C378.162 144.555 377.34 143.171 376.435 141.866C374.731 139.41 372.687 137.192 370.43 135.351C366.308 131.988 361.06 130.049 355.9 130.679C353.294 130.997 350.765 131.99 348.612 133.645C346.668 135.139 344.797 137.339 342.278 137.497C339.538 137.669 337.026 135.251 334.828 133.757C332.35 132.073 329.714 130.846 326.818 130.369C321.967 129.57 317.409 131.209 314.065 135.189C310.507 139.425 307.921 145.637 310.257 151.25C310.692 152.293 311.25 153.172 312.052 153.901C312.788 154.57 313.911 155.281 314.209 156.349C314.526 157.485 313.58 158.949 313.213 159.974C312.687 161.442 312.256 162.982 312.409 164.578C312.661 167.198 314.308 169.699 316.046 171.421C317.815 173.173 319.993 174.178 322.319 174.641C323.872 174.95 325.459 175.121 327.037 175.005C327.821 174.947 328.512 174.73 329.271 174.557C330.009 174.388 330.422 174.584 331.048 174.989C333.958 176.87 337.087 177.598 340.446 177.318C343.282 177.082 346.55 176.395 348.863 174.452C351.432 172.294 351.381 169.319 350.776 166.14C351.416 166.458 353.188 166.654 352.129 165.389C351.71 164.888 350.918 164.684 350.379 164.389C349.753 164.046 349.117 163.603 348.63 163.043C346.665 160.783 348.65 156.184 351.119 155.426C354.788 154.3 355.609 159.488 358.228 160.831C359.734 161.602 361.31 160.547 362.438 159.47C363.949 158.028 364.979 156.116 365.868 154.171C366.601 152.569 367.283 150.945 368.029 149.351C368.378 148.607 369.585 146.503 368.979 145.661C371.607 145.137 374.533 146.229 376.797 147.661C377.732 148.253 378.445 148.906 378.944 149.951C379.051 150.175 379.284 150.841 379.576 150.868C380.12 150.919 379.917 150.254 379.863 149.938Z"
      fill="#070707"
    />
    <path
      d="M333.277 217.804C332.025 212.144 333.721 205.913 337.67 201.67C338.675 200.589 340.14 199.564 341.522 200.084C342.905 200.607 343.328 202.354 343.334 203.832C343.356 209.985 339.259 215.946 333.508 218.131"
      fill="#E7E8E5"
    />
    <path
      d="M338.338 205.941C338.308 205.92 338.113 206.145 337.785 206.571C337.452 206.994 337.011 207.635 336.487 208.442C335.486 210.07 334.127 212.438 333.564 215.378C333.286 216.842 333.275 218.282 333.456 219.56C333.632 220.843 334.038 221.943 334.421 222.832C334.835 223.709 335.221 224.392 335.544 224.826C335.853 225.268 336.037 225.502 336.067 225.481C336.158 225.425 335.479 224.44 334.768 222.686C334.441 221.802 334.085 220.729 333.943 219.494C333.797 218.262 333.821 216.888 334.088 215.478C334.628 212.647 335.901 210.297 336.811 208.636C337.759 206.983 338.409 205.99 338.338 205.941Z"
      fill="#DADBD8"
    />
    <path
      d="M366.791 191.58C366.964 191.791 375.82 203.854 375.82 203.854C375.82 203.854 389.187 221.075 390.441 226.726C392.294 235.083 388.504 238.997 388.504 238.997C388.504 238.997 385.946 262.395 386.82 269.008C387.693 275.622 387.437 277.865 387.833 283.541C388.202 288.821 388.778 297.062 388.778 297.062C388.778 297.062 337.068 288.604 332.7 282.739L339.09 266.075L332.102 218.033C332.102 218.033 329.961 198.839 335.918 193.954C339.803 190.769 345.583 187.897 345.583 187.897L366.791 191.58Z"
      fill="#F8A531"
    />
    <path
      d="M364.941 243.522C364.942 243.54 365.232 243.542 365.754 243.489C366.276 243.437 367.027 243.304 367.926 243.029C369.714 242.494 372.117 241.235 373.977 239.009C375.834 236.78 376.641 234.189 376.847 232.334C376.957 231.4 376.952 230.637 376.91 230.115C376.868 229.592 376.814 229.308 376.796 229.31C376.729 229.312 376.897 230.471 376.625 232.304C376.367 234.121 375.544 236.634 373.735 238.807C371.923 240.976 369.598 242.237 367.856 242.817C366.102 243.413 364.931 243.455 364.941 243.522Z"
      fill="#C68427"
      stroke="#C68427"
      stroke-width="0.675926"
    />
    <path
      d="M366.077 245.703C367.974 245.703 369.927 244.972 371.406 243.75C373.755 241.808 375.084 238.895 375.859 235.937C375.841 235.981 375.821 236.025 375.802 236.068C375.556 236.749 375.155 237.443 374.652 238.114C374.444 238.417 374.218 238.716 373.974 239.009C372.115 241.235 369.711 242.494 367.924 243.029C367.434 243.179 366.989 243.286 366.6 243.361C364.533 244.076 362.553 244.682 362.553 244.682C363.603 245.386 364.828 245.703 366.077 245.703Z"
      fill="#DB8E24"
    />
    <path
      d="M366.6 243.359C366.989 243.284 367.435 243.177 367.924 243.027C369.712 242.492 372.116 241.233 373.975 239.007C374.219 238.714 374.445 238.415 374.653 238.113C373.288 239.936 371.179 241.585 369.446 242.301C368.621 242.642 367.6 243.013 366.6 243.359ZM375.803 236.066C375.822 236.023 375.841 235.979 375.86 235.936C375.889 235.825 375.918 235.713 375.946 235.602C375.907 235.756 375.858 235.911 375.803 236.066Z"
      fill="#0F191D"
    />
    <path
      d="M375.703 294.789C378.097 295.282 380.541 295.409 382.915 295.006L383.264 292.821C383.222 292.453 383.123 292.09 382.958 291.74C382.469 290.697 381.694 289.817 380.911 288.971C370.332 277.531 356.634 269.545 343.155 261.731C342.977 261.628 342.789 261.522 342.593 261.47C341.601 262.7 340.495 264.074 339.353 265.5C339.233 265.888 339.158 266.289 339.143 266.703C339.093 268.075 339.717 269.381 340.441 270.547C344.017 276.306 350.084 279.937 355.648 283.81C359.773 286.681 363.752 289.806 368.227 292.093C370.558 293.285 373.101 294.253 375.703 294.789Z"
      fill="#DB8E24"
    />
    <path
      d="M329.982 214.942C330.152 210.85 331.002 202.133 335.434 197.19C340.764 191.276 347.042 191.863 351.051 198.741C355.139 205.755 353.234 213.038 353.532 216.522C353.899 220.819 351.835 236.17 351.488 239.799L351.169 252.704C351.169 252.704 378.92 281.18 385.027 284.229L378.92 294.066C371.79 293.359 339.327 271.77 332.517 258.646C329.705 253.228 329.811 219.032 329.982 214.942Z"
      fill="#B28B67"
    />
    <path
      d="M353.942 229.309L327.675 224.024C327.675 224.024 329.835 207.6 332.73 199.62C334.982 193.413 341.974 191.074 341.974 191.074L353.521 198.05C353.521 198.05 355.496 207.394 355.341 215.942C355.185 224.491 353.942 229.309 353.942 229.309Z"
      fill="#F8A531"
    />
    <path
      d="M339.328 194.703C339.326 194.714 339.48 194.738 339.774 194.774C340.098 194.812 340.524 194.861 341.057 194.923C342.167 195.064 343.789 195.237 345.715 195.766C347.618 196.288 349.894 197.282 351.591 199.308C353.288 201.32 354.261 204.163 354.59 207.177C354.961 210.204 354.598 213.128 354.51 215.776C354.384 218.418 354.269 220.801 354.173 222.816C354.089 224.794 354.021 226.409 353.972 227.565C353.956 228.101 353.943 228.531 353.934 228.857C353.928 229.152 353.931 229.308 353.942 229.309C353.952 229.309 353.971 229.155 353.997 228.86C354.021 228.535 354.054 228.106 354.095 227.572C354.168 226.416 354.27 224.804 354.395 222.826C354.512 220.812 354.649 218.429 354.801 215.785C354.904 213.139 355.278 210.218 354.903 207.141C354.571 204.089 353.572 201.181 351.813 199.12C350.937 198.096 349.903 197.309 348.855 196.741C347.806 196.165 346.746 195.813 345.774 195.551C343.816 195.043 342.186 194.905 341.07 194.801C340.51 194.754 340.075 194.725 339.779 194.711C339.484 194.695 339.328 194.692 339.328 194.703Z"
      fill="#C68427"
      stroke="#C68427"
      stroke-width="0.675926"
    />
    <path
      d="M379.689 282.49C379.689 282.49 386.194 278.751 388.282 278.575C390.37 278.399 393.371 281.043 393.371 281.043C393.371 281.043 393.384 281.867 393.128 282.726C393.128 282.726 395.466 286.648 393.011 288.982C393.011 288.982 394.338 290.739 392.155 293.038C392.155 293.038 391.677 294.66 389.794 295.911C389.794 295.911 389.677 298.77 387.67 299.579C385.663 300.387 384.149 300.03 384.149 300.03C384.149 300.03 377.596 294.976 375.329 292.329L379.689 282.49Z"
      fill="#B28B67"
    />
    <path
      d="M385.046 267.29C385.785 267.282 386.521 267.113 387.179 266.776C387.177 266.734 387.174 266.69 387.172 266.648L387.101 266.641C387.122 266.582 387.143 266.524 387.163 266.465C386.892 260.909 386.515 252.533 386.451 247.596C386.134 247.3 385.762 247.078 385.323 246.968C385.113 246.916 384.905 246.892 384.7 246.894C383.285 246.909 382.012 248.139 381.029 249.27C379.656 250.848 378.283 252.427 376.909 254.007C376.241 254.776 375.54 255.616 375.442 256.63C375.323 257.864 376.12 258.978 376.877 259.96C378.055 261.486 379.232 263.015 380.41 264.542C380.889 265.164 381.375 265.792 381.991 266.279C382.85 266.958 383.951 267.301 385.046 267.29Z"
      fill="#DB8E24"
    />
    <path
      d="M391.477 251.598C391.538 251.373 391.839 249.123 390.646 247.908C389.453 246.693 385.395 245.559 385.395 245.559L381.177 248.106C381.177 248.106 380.872 249.792 383.305 250.304C383.305 250.304 377.682 251.726 377.268 253.221C376.855 254.717 377.6 255.807 377.6 255.807C377.6 255.807 375.088 256.159 375.178 257.873C375.267 259.588 376.824 259.777 376.824 259.777C376.824 259.777 375.586 261.929 378.264 262.428C380.942 262.927 385.276 262.677 385.276 262.677C385.276 262.677 385.099 264.189 383.567 263.91C382.035 263.631 380.684 264.143 380.684 264.143C380.684 264.143 379.796 265.91 381.536 266.31C383.276 266.71 384.762 267.443 386.655 267.001C388.548 266.558 390.104 266.748 390.389 265.136C390.674 263.525 390.532 263.164 390.532 263.164C390.532 263.164 392.211 261.536 391.763 260.206C391.315 258.875 391.315 258.875 391.315 258.875C391.315 258.875 393.178 257.378 392.632 255.859C392.086 254.34 391.283 252.59 391.283 252.59L391.477 251.598Z"
      fill="#B28B67"
    />
    <path
      d="M377.607 255.726C377.616 255.771 378.237 255.678 379.238 255.521C380.239 255.364 381.621 255.149 383.152 254.943C384.683 254.738 386.073 254.582 387.08 254.47C388.087 254.358 388.71 254.284 388.708 254.239C388.706 254.197 388.076 254.193 387.061 254.248C386.047 254.303 384.647 254.425 383.11 254.63C381.573 254.837 380.191 255.088 379.197 255.302C378.204 255.515 377.598 255.685 377.607 255.726Z"
      fill="#B77A23"
    />
    <path
      d="M376.475 259.686C376.483 259.773 379.014 259.58 382.126 259.256C385.238 258.931 387.754 258.598 387.745 258.512C387.736 258.425 385.205 258.618 382.093 258.942C378.981 259.266 376.465 259.6 376.475 259.686Z"
      fill="#B77A23"
    />
    <path
      d="M380.693 250.652C380.703 250.739 382.063 250.655 383.73 250.465C385.398 250.275 386.741 250.05 386.731 249.964C386.721 249.877 385.362 249.961 383.694 250.152C382.027 250.341 380.683 250.566 380.693 250.652Z"
      fill="#B77A23"
    />
  </svg>
</template>

<script>
export default {
  name: "FirstTrimesterBalance"
};
</script>
