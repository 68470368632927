// The functions here support
// Boolean step.STEPPER
// String step.STEPPER_GROUP
// and
// String step.theme (used in desk assessment step.js)

/**
 * Gets unique stepper groups from steps
 */
function getUniqueStepperGroups(stepperSteps) {
  const allGroups = stepperSteps
    .map(([_, step]) => step.STEPPER_GROUP || step.theme)
    .filter(group => group);

  return [...new Set(allGroups)];
}

/**
 * Gets the main step for a specific group
 */
function getMainStepForGroup(stepperSteps, group) {
  return stepperSteps.find(
    ([_, step]) => (step.STEPPER_GROUP || step.theme) === group
  )[1];
}

/**
 * Counts how many steps are in a group
 */
function countStepsInGroup(stepperSteps, group) {
  return stepperSteps.filter(
    ([_, step]) => (step.STEPPER_GROUP || step.theme) === group
  ).length;
}

/**
 * Creates step structure from steps and groups
 */
function buildStepStructure(stepperSteps, stepperGroups) {
  return stepperGroups.map(group => {
    const mainStep = getMainStepForGroup(stepperSteps, group);
    const stepsInGroup = countStepsInGroup(stepperSteps, group);

    return {
      mainStep: mainStep.THEME || mainStep.theme,
      hasSubstep: stepsInGroup > 1
    };
  });
}

/**
 * Builds complete stepper structure
 */
export function buildStepperStructure(steps) {
  // Filter steps that should show in stepper
  const stepperSteps = Object.entries(steps).filter(
    ([_, step]) => step.STEPPER || step.navigation?.showStepper
  );

  const stepperGroups = getUniqueStepperGroups(stepperSteps);

  return buildStepStructure(stepperSteps, stepperGroups);
}

/**
 * Gets current stepper index
 */
export function getCurrentStepperIndex(currentTheme, stepStructure, steps) {
  const currentStep = Object.values(steps).find(
    step => (step.THEME || step.theme) === currentTheme
  );

  // Handle both step formats
  const stepGroup = currentStep?.STEPPER_GROUP || currentStep?.theme;
  if (!stepGroup) return 1;

  const mainStepForGroup = stepStructure.find(
    step => step.mainStep === stepGroup
  )?.mainStep;

  const groupIndex = stepStructure.findIndex(
    step => step.mainStep === mainStepForGroup
  );

  return groupIndex + 1;
}
