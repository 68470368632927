import store from "@/store";
import DeskAssessmentResults from "@/services/deskassessment/desk-assessment-results-util.js";
import { EquipmentScoreHelper } from "@/components/assessment/desk/results-helpers/results-helper.js";
import { _ } from "lodash";

export default {
  areCriteriaMet(results, criteria) {
    const isAndCondition = !Array.isArray(criteria);
    let result = isAndCondition;
    // Array = OR operator, Object = AND operator
    let operator = isAndCondition
      ? (a, b) => {
          return a && b;
        }
      : (a, b) => {
          return a || b;
        };

    let conditions = isAndCondition ? Object.values(criteria) : criteria;

    conditions.forEach(criterion => {
      if (isAndCondition && !result) {
        return;
      }
      // If there is another set of criteria found, find result of that array / object
      // before continuing with primary loop
      if (!this.containsOnlyPrimitiveData(criterion)) {
        result = operator(result, this.areCriteriaMet(results, criterion));
        return;
      }

      if (criterion.featureFlag) {
        const currentFeatures = store.getters.featureFlags;
        if (currentFeatures[criterion.featureFlag] === undefined) {
          result = operator(result, false);
          return;
        }
        result = operator(
          result,
          currentFeatures[criterion.featureFlag] === criterion.condition
        );
        return;
      }

      let answer = results[criterion.question];
      if (criterion.subQuestion) {
        answer = _.get(answer, criterion.subQuestion);
      }

      let valueToCompare = criterion.valueFromResult
        ? results[criterion.valueFromResult]
        : criterion.value;

      valueToCompare =
        valueToCompare === "undefined" ? undefined : valueToCompare;

      if (criterion.simsItem) {
        answer = results;
        valueToCompare = criterion.simsItem;
      }

      if (
        answer === undefined &&
        valueToCompare != undefined &&
        criterion.condition !== "notEqual"
      ) {
        result = operator(result, false);
        return;
      }

      switch (criterion.condition) {
        case "any":
          if (answer === undefined) {
            result = operator(result, false);
            break;
          }
          result = operator(
            result,
            answer > 0 || Object.keys(answer).length > 0 || answer.length > 0
          );
          break;
        case "greaterThan":
          result = operator(result, answer > valueToCompare);
          break;
        case "lessThan":
          result = operator(result, answer < valueToCompare);
          break;
        case "equal":
          result = operator(result, answer === valueToCompare);
          break;
        case "contains":
          if (criterion.simsItem) {
            let val = this.simsObjectChecker(answer, valueToCompare, true);
            result = operator(result, val);
          } else {
            result = operator(result, answer.includes(valueToCompare));
          }
          break;
        case "doesNotContain":
          if (criterion.simsItem) {
            let val = this.simsObjectChecker(answer, valueToCompare, false);
            result = operator(result, val);
          } else {
            result = operator(result, !answer.includes(valueToCompare));
          }

          break;
        case "arrayCountGreaterThan":
          result = operator(result, answer.length > valueToCompare);
          break;
        case "notEqual":
          result = operator(result, answer !== valueToCompare);
          break;
        case "isInArray":
          if (Array.isArray(criterion.value)) {
            result = operator(result, criterion.value.includes(answer));
          }
          break;
      }
    });
    return result;
  },
  containsOnlyPrimitiveData(object) {
    if (Array.isArray(object)) {
      return false;
    }
    return Object.keys(object).includes("condition");
  },
  simsObjectChecker(answer, valueToCompare, contains) {
    if (!answer.deskItems) {
      return !contains;
    }

    const utilityService = getUtilityService();

    if (
      valueToCompare === "beanBag" ||
      valueToCompare === "stool" ||
      valueToCompare === "gymBall"
    ) {
      const isMainSeatingOption = utilityService.isMainSeatingOption(
        answer.deskItems,
        valueToCompare,
        answer.workarea
      );
      return contains ? isMainSeatingOption : !isMainSeatingOption;
    }

    return contains
      ? !!answer.deskItems.find(i => i.id === valueToCompare)
      : !answer.deskItems.find(i => i.id === valueToCompare);
  }
};

function getUtilityService() {
  const isV2 = store.getters["deskAssessmentSchemaStore/schemaVersion"];
  return isV2 ? EquipmentScoreHelper : DeskAssessmentResults;
}
