import { assessmentTypes } from "@/constants/constants.js";
import { MenopauseImageService } from "@/components/assessment/menopause/menopause-image-service.js";
import { MenstruationImageService } from "@/components/assessment/menstruation/menstruation-image-service.js";
import { PregnancyImageService } from "@/components/assessment/pregnancy/pregnancy-image-service.js";

export async function getImageService(assessmentType) {
  switch (assessmentType) {
    case assessmentTypes.menopauseAssessment: {
      return new MenopauseImageService();
    }
    case assessmentTypes.menstruationAssessment: {
      return new MenstruationImageService();
    }
    case assessmentTypes.pregnancyAssessment: {
      return new PregnancyImageService();
    }
    default:
      throw new Error(`Unknown assessment type: ${assessmentType}`);
  }
}
