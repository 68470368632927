import Answers from "@/components/assessment/pregnancy/answers.json";
import i18n from "@/plugins/i18n";

export class PregnanyReportIntroductionService {
  constructor(results) {
    this.results = results;
  }

  getIntroductionText() {
    const textArray = [];

    const feelingText = this.getCurrentFeelingText();
    if (feelingText) {
      textArray.push(feelingText);
    }

    const lifestyleText = this.getLifestyleImpactText();
    if (lifestyleText) {
      textArray.push(lifestyleText);
    }

    const outro = i18n.t(
      "pregnancyAssessment.report.content.introduction.outro"
    );
    textArray.push(outro);

    return textArray;
  }

  getCurrentFeelingText() {
    const currentFeeling = this.results?.trimester?.pregnancyCurrentFeeling;
    const feelingTranslations = {
      [Answers.trimester.pregnancyCurrentFeeling.good]:
        "pregnancyAssessment.report.content.introduction.currentFeeling.good",
      [Answers.trimester.pregnancyCurrentFeeling.okay]:
        "pregnancyAssessment.report.content.introduction.currentFeeling.okay",
      [Answers.trimester.pregnancyCurrentFeeling.struggling]:
        "pregnancyAssessment.report.content.introduction.currentFeeling.struggling"
    };

    return i18n.t(
      feelingTranslations[currentFeeling] ||
        feelingTranslations[Answers.trimester.pregnancyCurrentFeeling.okay]
    );
  }

  getLifestyleImpactText() {
    const impactReason =
      this.results?.lifestyle?.pregnancyImpactsActivityReason;
    const impactTranslations = {
      [Answers.lifestyle.pregnancyImpactsActivityReason.bodyChanges]:
        "pregnancyAssessment.report.content.introduction.pregnancyImpactsActivity.bodyChanges",
      [Answers.lifestyle.pregnancyImpactsActivityReason.noTime]:
        "pregnancyAssessment.report.content.introduction.pregnancyImpactsActivity.time",
      [Answers.lifestyle.pregnancyImpactsActivityReason.safety]:
        "pregnancyAssessment.report.content.introduction.pregnancyImpactsActivity.safety"
    };

    return impactTranslations[impactReason]
      ? i18n.t(impactTranslations[impactReason])
      : null;
  }

  getIllustration() {
    const trimester = this.results?.trimester?.pregnancyTrimester;
    const illustrations = {
      [Answers.trimester.pregnancyTrimester.first]:
        "/images/pregnancy-assessment/report/first-trimester-introduction.svg",
      [Answers.trimester.pregnancyTrimester.second]:
        "/images/pregnancy-assessment/report/second-trimester-introduction.svg",
      [Answers.trimester.pregnancyTrimester.third]:
        "/images/pregnancy-assessment/report/third-trimester-introduction.svg"
    };

    return (
      illustrations[trimester] ||
      illustrations[Answers.trimester.pregnancyTrimester.first]
    );
  }
}
