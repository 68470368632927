<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4896_54125)">
      <circle cx="599.5" cy="249.5" r="62.5" fill="#8AAFAF" />
      <g clip-path="url(#clip1_4896_54125)">
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="#C6DDDD"
        />
        <path
          d="M623.149 226.364L619.404 240.258L603.246 240.848L570.55 259.714L568.577 260.853L560.381 265.582C557.599 263.236 552.747 261.256 556.501 260.477C558.405 260.083 561.653 257.137 564.461 255.66C575.369 249.916 590.919 237.809 594.163 233.507C596.168 230.846 599.588 229.642 602.889 229.183L623.149 226.364Z"
          fill="#B28B67"
        />
        <path
          d="M623.149 226.365L619.405 240.259L603.246 240.849L570.55 259.715L568.577 260.854L565.5 263C563.841 259.425 561.316 258.34 560.5 256.5C569.539 248.339 591.5 235 596 230.5C600.5 228 599.589 229.643 602.889 229.184L623.149 226.365Z"
          fill="#17282F"
        />
        <path
          d="M622.558 239.961L604.928 240.786L582.106 259.409L565.5 269.5L561.5 265C564.468 265.652 572.79 257.203 577.429 253.188C584.478 247.087 592.212 237.777 596.853 231.885C599.624 228.366 603.768 226.201 608.239 225.932L619.109 225.279L622.558 239.961Z"
          fill="#C7A280"
        />
        <path
          d="M622.558 239.961L607.001 241C607.001 241 600.107 248.472 596.001 252.5C589.478 258.898 571.5 266.5 571.5 266.5L567.501 262.5C566.001 260.5 571.368 256.515 576.007 252.5C583.056 246.398 592.212 237.777 596.853 231.885C599.624 228.366 603.768 226.201 608.239 225.932L619.109 225.279L622.558 239.961Z"
          fill="#223A43"
        />
        <path
          d="M580.187 258.248C580.135 258.248 580.083 258.226 580.046 258.184C579.978 258.106 579.986 257.988 580.064 257.92C586.88 251.974 594.27 243.159 599.27 236.811C599.471 236.556 599.681 236.304 599.897 236.063C599.966 235.986 600.084 235.979 600.161 236.048C600.238 236.117 600.244 236.235 600.176 236.312C599.966 236.548 599.76 236.794 599.564 237.042C594.554 243.403 587.147 252.237 580.31 258.202C580.274 258.232 580.23 258.248 580.187 258.248Z"
          fill="#17282F"
        />
        <path
          d="M619.319 226.354C619.234 226.354 619.157 226.296 619.137 226.209L619.028 225.747C619.005 225.646 619.067 225.546 619.167 225.522C619.268 225.498 619.369 225.561 619.392 225.661L619.501 226.124C619.524 226.224 619.462 226.325 619.362 226.348C619.347 226.352 619.333 226.354 619.319 226.354Z"
          fill="#F46A77"
        />
        <path
          d="M664.487 180.85C664.487 180.85 661.185 192.665 658.426 195.096C669.725 193.256 669.725 193.256 669.725 193.256L675.309 179.592L664.487 180.85Z"
          fill="#F9ABAA"
        />
        <path
          d="M672.827 173.548L665.167 171.279C665.167 171.279 663.391 172.984 661.403 175.283C660.932 175.828 660.278 176.183 659.565 176.28L658.315 176.45C658.092 176.48 657.798 176.652 657.676 176.842C657.433 177.22 657.522 177.724 657.883 177.997L658.737 178.64C656.406 181.901 654.838 185.312 657.084 186.704C661.934 189.711 671.7 188.012 671.7 188.012L675.974 181.429L672.827 173.548Z"
          fill="#F9ABAA"
        />
        <path
          d="M658.171 181.605C658.071 181.605 657.988 181.526 657.985 181.425C657.981 181.322 658.061 181.236 658.165 181.232L659.876 181.174C659.977 181.169 660.066 181.251 660.069 181.354C660.072 181.457 659.992 181.544 659.889 181.548L658.177 181.605C658.175 181.605 658.173 181.605 658.171 181.605Z"
          fill="#F46A77"
        />
        <path
          d="M633.202 226.362C633.202 226.362 624.002 200 657.093 193.931C658.872 192.222 659.743 191.207 660.164 190.609C660.482 190.158 661.057 189.968 661.581 190.142C663.533 190.789 668.02 192.077 670.204 191.661C670.636 191.578 671.059 191.85 671.176 192.275C673.072 199.148 668.48 212.5 668.48 212.5L658.034 231.025L633.202 226.362Z"
          fill="#F8A531"
        />
        <path
          d="M610.462 228.254C609.924 228.26 609.488 227.818 609.5 227.28L609.563 224.751C609.584 223.893 609.96 223.082 610.602 222.512L613.094 220.299C613.979 219.514 615.145 219.123 616.324 219.216L621.289 219.608C621.739 219.643 622.192 219.618 622.635 219.533L644.96 215.26L654.551 200.545L662.237 205.071L649.624 223.012L621.77 223.668C621.77 223.668 617.062 224.435 615.574 223.69L611.807 224.917L611.389 227.457C611.314 227.913 610.923 228.249 610.462 228.254Z"
          fill="#B28B67"
        />
        <path
          d="M665.141 205.575L662.283 210.083L661.332 211.581L660.048 213.606L659.103 215.096L656.166 219.729C655.878 220.183 655.296 220.354 654.809 220.126C649.096 217.446 646.466 214.152 645.583 212.828C645.353 212.484 645.342 212.038 645.555 211.683L647.672 208.157L648.592 206.621L649.88 204.474L650.817 202.913L653.521 198.406C654.801 196.272 657.062 195.091 659.38 195.091C660.586 195.091 661.806 195.41 662.912 196.08C666.184 198.061 667.189 202.344 665.141 205.575Z"
          fill="#DD9128"
        />
        <path
          d="M660.046 213.609L659.102 215.098C653.611 213.377 649.478 209.89 647.67 208.159L648.59 206.623C651.894 210.298 657.522 212.676 660.046 213.609Z"
          fill="white"
        />
        <path
          d="M662.283 210.084L661.332 211.582C655.687 209.75 651.513 206.078 649.881 204.475L650.818 202.914C653.99 206.636 659.598 209.073 662.283 210.084Z"
          fill="white"
        />
        <path
          d="M655.258 220.414C655.079 220.414 654.899 220.376 654.728 220.296C648.96 217.59 646.302 214.245 645.426 212.933C645.157 212.53 645.144 212.002 645.393 211.588L652.963 198.971C653.016 198.883 653.131 198.854 653.22 198.907C653.308 198.96 653.337 199.075 653.284 199.164L645.714 211.78C645.539 212.071 645.548 212.443 645.736 212.725C646.595 214.012 649.204 217.292 654.887 219.957C655.288 220.146 655.769 220.005 656.007 219.63L664.981 205.476C665.446 204.743 665.758 203.943 665.91 203.098C665.928 202.997 666.025 202.93 666.127 202.947C666.228 202.966 666.296 203.063 666.278 203.164C666.117 204.057 665.787 204.902 665.296 205.676L656.322 219.83C656.085 220.204 655.677 220.414 655.258 220.414Z"
          fill="#DD9128"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M558.064 270.688L561.385 265.993C561.708 265.537 561.503 264.892 561.02 264.61C558.344 263.053 556.333 259.493 556.333 259.493C551.298 259.632 546.035 257.623 543.465 256.478C542.582 256.084 541.517 256.449 541.112 257.327L540.566 258.511L550.756 266.557L556.701 271.25L558.064 270.688Z"
          fill="#17282F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M566.377 274.453L568.583 269.099C568.791 268.593 568.467 268.022 567.944 267.859C564.666 266.835 561.496 263.542 561.496 263.542C556.202 264.619 550.063 263.534 547.093 262.859C546.126 262.639 545.139 263.25 544.952 264.225L544.716 265.454L557.592 271.652L565.104 275.267L566.377 274.453Z"
          fill="#223A43"
        />
        <path
          d="M660.105 280.971C659.476 280.971 658.919 280.567 658.723 279.97L649.14 250.663C648.466 248.601 646.542 247.205 644.372 247.205H623.63C621.503 247.205 619.608 248.545 618.899 250.549L608.482 280.002C608.277 280.583 607.728 280.971 607.113 280.971C606.135 280.971 605.441 280.066 605.686 279.144L615.506 242.304H652.671L661.516 279.179C661.735 280.093 661.044 280.971 660.105 280.971Z"
          fill="#63352C"
        />
        <path
          d="M608.276 248.322H655.025C656.178 248.322 657.248 247.714 657.837 246.721L677.871 212.987C677.871 212.987 654.946 191.365 650 189C645.053 186.636 645.765 203.675 650 224C635.924 221.527 620.782 221.575 609.534 227.026C603.429 230.768 604.237 240.739 605.05 245.593C605.313 247.169 606.678 248.322 608.276 248.322Z"
          fill="#A7A6B4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4896_54125">
        <rect
          x="535"
          y="178"
          width="127"
          height="134"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_4896_54125">
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "StretchedLegs"
};
</script>
