<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="346.5" cy="157.5" r="62.5" fill="#8AAFAF" />
    <g clip-path="url(#clip0_4896_54085)">
      <rect x="282" y="86" width="125" height="125" rx="60.6796" fill="white" />
      <rect
        x="282"
        y="86"
        width="125"
        height="125"
        rx="60.6796"
        fill="#C6DDDD"
      />
      <path
        d="M298.752 132.202V173.541"
        stroke="#A7A6B4"
        stroke-width="1.849"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M315.568 110.249V157.66"
        stroke="#A7A6B4"
        stroke-width="1.849"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M338.455 124.495V165.834"
        stroke="#A7A6B4"
        stroke-width="1.849"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M336.264 124.256C339.107 126.102 338.701 128.095 335.362 128.683L301.412 134.67C298.074 135.259 293.017 134.229 290.174 132.383L275.895 123.105C273.052 121.258 273.458 119.265 276.796 118.677L310.747 112.69C314.085 112.102 319.143 113.131 321.985 114.978L336.264 124.256Z"
        fill="#A7A6B4"
      />
      <path
        d="M336.264 121.296C339.107 123.143 338.701 125.136 335.362 125.724L301.412 131.711C298.074 132.3 293.017 131.27 290.174 129.423L275.895 120.146C273.052 118.299 273.458 116.307 276.796 115.717L310.747 109.731C314.085 109.142 319.143 110.172 321.985 112.018L336.264 121.296Z"
        fill="#B9C0D6"
      />
      <path
        d="M346.3 149.604C346.312 151.353 346.321 153.16 346.321 155.151C346.554 158.478 352.711 158.961 354.495 160.27C358.583 163.267 350.369 163.111 347.372 162.527C341.02 161.289 340.715 162.683 339.333 160.931C338.295 159.614 339.152 157.858 339.774 156.613C340.482 154.586 340.448 152.051 339.781 149.759"
        fill="#3E79AE"
      />
      <path
        d="M317.422 162.712C317.399 162.768 318.256 161.548 318.234 161.605C318.094 161.947 317.537 162.454 317.422 162.712C316.313 165.864 318.7 169.429 320.802 171.414C321.269 171.823 322.049 172.958 322.049 173.717C321.991 174.534 320.706 174.826 320.064 174.942C317.67 175.235 314.595 174.423 313.641 172.316C312.532 169.864 310.488 167.937 310.488 165.543C310.488 164.55 310.838 163.791 311.364 163.032C311.656 162.624 312.298 161.222 312.532 159.938C312.928 157.957 313.072 156.693 313.226 155.374C313.226 155.374 316.21 156.784 316.385 156.726C316.56 156.668 317.399 162.768 317.399 162.768"
        fill="#1A1A21"
      />
      <path
        d="M346.571 153.566C346.559 151.99 346.151 126.014 345.527 120.408C345.06 115.27 342.97 114.687 337.988 113.286C328.023 110.327 322.262 109.938 311.986 108.225C301.554 106.668 300.931 113.831 301.086 114.609C301.242 115.387 309.183 121.46 310.429 121.615C311.674 121.771 336.337 125.498 336.337 125.498C335.336 130.814 338.806 146.158 338.845 152.873C338.857 154.919 346.583 155.142 346.571 153.566Z"
        fill="#17282F"
      />
      <path
        d="M320.328 161.765C320.936 160.257 329.112 134.698 330.512 130.807C333.456 122.631 328.467 122.246 321.328 119.748C316.657 118.19 311.986 116.478 307.307 114.762C302.237 112.219 304.183 109.09 309.027 107.914C309.027 107.914 299.997 105.423 295.948 107.759C291.122 109.783 287.852 115.544 290.187 120.526C293.457 127.844 303.578 128.778 310.429 129.713C313.387 130.18 315.611 130.663 318.57 130.974C315.445 136.318 312.224 157.661 312.224 157.661C312.097 160.89 318.849 164.404 320.328 161.765Z"
        fill="#223A43"
      />
      <path
        d="M290.811 112.116C293.832 104.898 293.262 100.905 292.562 92.1485C291.917 84.0807 292.295 74.2502 298.87 67.8964C303.254 63.6585 309.134 62.959 313.878 68.2286C315.987 70.571 315.912 81.5822 319.299 90.1477C322.351 97.8642 327.243 105.273 322.304 110.024C318.474 113.71 310.748 112.699 308.911 112.7C307.074 112.7 296.416 105.109 290.811 112.116Z"
        fill="#F8A531"
      />
      <path
        d="M315.764 69.2593C322.148 75.0208 327.831 81.3537 328.25 82.9715C328.873 85.9299 329.115 100.908 327.247 104.178L324.756 101.92C325.378 99.117 322.503 88.2917 323.012 85.1047C323.188 83.9971 316.531 80.3053 316.18 79.8387C315.83 79.3715 315.919 70.8963 315.919 70.8963L315.764 69.2593Z"
        fill="#B28B67"
      />
      <path
        d="M322.652 105.657C315.589 106.396 294.504 106.214 292.245 103.465C290.238 101.024 291.68 89.23 292.445 84.3258C293.12 80.0041 292.553 72.8423 299.103 73.1153C310.21 73.5813 297.191 92.9656 299.201 97.5579C299.609 98.4923 301.536 98.8424 302.938 98.8424C309.568 99.1437 315.699 100.848 322.341 100.986C323.43 100.675 327.245 101.998 327.946 102.543C329.035 103.477 328.491 104.645 328.257 105.813C327.946 107.292 327.712 108.382 326 108.616C324.209 108.771 323.664 105.579 322.652 105.657Z"
        fill="#B28B67"
      />
      <path
        d="M297.381 69.7708C299.483 68.058 297.818 71.1674 300.776 71.401C302.645 71.6346 304.669 72.1795 305.214 74.1265C305.759 76.3058 305.291 78.9524 304.98 81.1329C304.28 86.3484 302.272 91.7968 301.69 93.602C301.108 95.4072 290.961 94.267 291.258 91.3444C291.555 88.4217 292.45 74.2904 297.381 69.7708Z"
        fill="#F8A531"
      />
      <path
        d="M299.209 94.457C299.209 94.457 300.661 94.4404 301.474 93.7513C302.286 93.0617 303.596 78.886 303.596 78.886"
        stroke="#B0741F"
        stroke-width="0.154084"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="290"
        y="319.064"
        width="292"
        height="222.443"
        transform="rotate(-73.5617 290 319.064)"
        fill="#A1BFBF"
      />
      <path
        d="M378.643 158.473H381.837V155.913H378.643V158.473Z"
        fill="#A7A6B4"
      />
      <path
        d="M380.715 155.85V191.619"
        stroke="#3E79AE"
        stroke-width="1.64968"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M375.282 90.1801C375.059 89.203 375.16 88.6899 375.402 86.9924C375.968 87.5797 377.58 87.8585 378.16 87.0952C379.597 85.2053 380.331 81.3665 380.739 78.5549C380.83 77.9302 380.913 77.2005 380.796 76.6247C380.375 74.5313 378.585 72.9641 376.353 72.2624C372.374 70.9361 369.169 73.1467 368.617 76.6836C367.994 79.9026 371.683 88.5398 366.885 90.5167C366.885 90.5167 367.562 93.4267 372.036 93.0528C376.509 92.6788 375.282 90.1801 375.282 90.1801Z"
        fill="#B28B67"
      />
      <path
        d="M367.135 186.473C366.891 188.384 366.701 190.581 365.908 192.742C365.101 194.945 363.275 196.916 365.908 198.077C368.305 199.134 369.625 201.762 372.553 201.982C373.529 202.036 375.385 202.036 376.068 200.999C376.702 200.125 375.043 199.689 374.505 199.305C373.383 198.54 372.309 197.067 371.576 195.865C371.088 194.992 371.186 193.953 371.332 192.972C371.722 190.569 372.785 184.725 373.469 182.432"
        fill="#F57B6D"
      />
      <path
        d="M368.489 198.087C368.497 198.095 368.506 198.104 368.515 198.113L368.41 198.076C368.439 198.076 368.462 198.084 368.489 198.087Z"
        fill="#C5242A"
      />
      <path
        d="M373.135 79.6705C372.929 78.9144 371.967 79.1894 371.623 79.6705C371.004 80.5641 371.554 82.6262 372.929 81.9388C371.623 82.6949 370.523 83.451 369.973 84.8257C369.424 86.1988 369.218 86.6805 367.642 86.7504C362.431 86.9802 361.301 79.9268 366.674 79.1206C365.712 77.4709 364.887 75.6838 365.299 73.6904C365.78 71.2847 367.774 69.1131 370.042 68.4258C373.822 67.326 379.734 68.2603 381.59 72.3844C382.483 74.4465 382.365 76.4866 380.784 78.1363C380.921 77.174 380.796 76.6236 379.735 76.0962C379.033 75.7481 377.775 75.1685 377.019 75.7872C376.4 76.4058 376.813 77.3681 375.988 77.8493C375.094 78.3992 374.785 77.8834 374.51 79.1206C374.449 79.4566 374.372 80.0417 374.143 80.2891C373.857 80.5987 373.41 80.5289 373.353 80.4838C373.25 80.4041 373.215 79.8998 373.135 79.6705Z"
        fill="#1A1A21"
      />
      <path
        d="M384.929 194.634C383.711 192.999 384.56 189.497 384.659 187.529C386.04 177.446 389.142 162.155 388.112 159.594C386.958 156.721 386.15 143.232 383.921 138.128L381.101 128.114L363.357 128.564L363.625 129.615C371.61 140.841 367.144 132.595 372.461 147.294C375.112 152.862 378.263 159.997 378.608 162.779C379.002 165.947 377.54 169.086 377.327 173.168C376.952 180.341 379.974 185.009 378.648 191.511C378.646 191.524 378.646 191.537 378.643 191.55C378.543 191.915 378.439 192.277 378.339 192.667C377.899 194.25 376.532 196.927 378.729 197.527C379.754 197.746 380.779 197.746 381.805 198.182C382.879 198.564 383.85 199.236 384.831 199.875C386.513 200.97 388.389 200.972 389.269 200.374C390.876 199.283 385.365 195.273 384.929 194.634Z"
        fill="#17282F"
      />
      <path
        d="M367.191 189.328C367.025 189.178 366.914 189.01 366.832 188.831C366.649 190.1 366.391 191.427 365.908 192.742C365.101 194.945 363.275 196.916 365.908 198.077C368.305 199.134 369.625 201.762 372.553 201.982C373.529 202.036 375.385 202.036 376.068 200.999C376.702 200.125 375.043 199.689 374.505 199.305C373.383 198.54 372.309 197.067 371.576 195.865C371.088 194.992 371.186 193.953 371.332 192.972C371.453 192.23 371.638 191.158 371.856 189.964C371.607 190.179 371.316 190.341 370.949 190.428C369.712 190.795 368.062 190.29 367.191 189.328Z"
        fill="#1A1A21"
      />
      <path
        d="M379.035 189.116C378.934 189.915 378.807 190.714 378.646 191.511C378.643 191.524 378.643 191.537 378.641 191.55C378.54 191.915 378.436 192.277 378.336 192.667C377.897 194.25 376.53 196.927 378.726 197.527C379.752 197.746 380.777 197.746 381.803 198.182C382.877 198.564 383.847 199.236 384.829 199.875C386.51 200.97 388.387 200.972 389.267 200.374C390.873 199.283 385.362 195.273 384.927 194.634C384.034 193.436 384.251 191.236 384.47 189.371C383.753 190.611 380.247 190.611 379.035 189.116Z"
        fill="#1A1A21"
      />
      <path
        d="M381.787 128.114L364.043 128.564L361.644 130.534C358.961 138.939 363.362 147.027 364.666 157.482C365.063 160.661 365.254 162.235 364.73 165.58C363.432 173.857 367.22 181.495 366.825 189.877C366.709 192.348 371.436 193.125 371.915 190.333C373.432 181.505 374.153 172.499 375.044 163.519C375.258 158.873 376.082 153.95 377.449 147.714C379.129 140.057 379.855 137.063 381.787 128.114Z"
        fill="#223A43"
      />
      <path
        d="M375.5 91C375.878 96.1206 382.221 101.628 383.671 105.691C384.674 108.499 383.527 109.692 382.624 111.823C382.153 112.935 382.745 114.136 383.682 114.898C387.104 117.681 389.094 122.339 389.821 127.097C390.49 131.482 390.055 137.391 385.354 139.122C378.826 141.527 376.813 140.841 359.454 138.234C359.329 132.38 361.515 125.241 362.415 121.339C364.217 113.532 359.986 99.028 362 95C362.756 93.4878 365 95 366.372 90.5734C366.3 90.3881 366.821 90.3782 366.885 90.5151C367.757 92.3809 367.304 90.3283 369 92C372 93 371.551 93.5305 375.5 91C375.531 90.6486 375.515 90.7525 375.5 91Z"
        fill="#F8A531"
      />
      <path
        d="M366.584 93.0079C366.965 98.1069 364.422 114.893 364.205 116.357C363.987 117.822 365.959 121.781 367.285 126.717C368.473 131.146 369.486 133.54 370.853 137.257C371.487 138.684 373.28 140.143 374.505 140.873C375.048 141.283 375.797 142.515 374.472 142.25C373.904 142.174 372.962 141.264 372.446 140.956C372.294 141.652 372.039 142.373 371.989 143.042C371.979 143.648 372.199 144.303 371.892 144.819C371.239 145.915 369.305 145.2 368.456 144.868C366.78 144.307 366.879 142.967 367.326 141.486C367.619 140.7 368.527 138.883 367.855 137.958C366.444 135.633 364.918 132.705 363.743 130.476C362.282 127.798 360.984 125.078 359.521 122.4C358.862 121.173 358.449 119.54 358.116 118.227C356.632 113.269 357.378 105.71 358.183 100.491C358.376 99.2409 359.014 97.224 359.349 95.969C360.125 93.0705 363.278 90.567 366.471 90.6111"
        fill="#B28B67"
      />
      <path
        d="M361.987 91.3806C363.548 90.1086 364.303 90.3265 366.5 90.5C367.888 90.6735 367.4 93.1694 367.805 94.6154C368.209 96.2339 367.862 98.1993 367.631 99.8188C367.111 103.692 365.62 107.738 365.188 109.079C364.755 110.42 357.22 109.573 357.44 107.402C357.661 105.232 358.326 94.7371 361.987 91.3806Z"
        fill="#F8A531"
      />
      <path
        d="M363.172 110C363.172 110 364.193 109.988 364.764 109.504C365.335 109.019 366.256 99.0542 366.256 99.0542"
        stroke="#B0741F"
        stroke-width="0.108314"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4896_54085">
        <rect
          x="282"
          y="86"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CirculationSittingStanding"
};
</script>
