<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "AssessmentWrapper"
};
</script>

<style lang="scss" scoped>
::v-deep {
  .assessment-loader {
    position: relative;
    z-index: 1;
  }

  .assessment-container {
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    max-width: 100%;

    @media screen and (min-width: 968px) {
      display: grid;
    }

    &.is-step-outro-page {
      max-width: 700px;
      margin: 0 auto;
      padding-top: 1rem;
    }
  }

  .assessment-full-width {
    grid-column: 1 / -1 !important;
  }

  .assessment-stepper {
    grid-column: 2;
    grid-row: 1;
    position: relative;

    &.is-full-width {
      grid-column: 1/-1;
    }
  }

  .assessment-form {
    grid-column: 2;
    margin-bottom: 6rem !important;
    position: relative;
  }

  .assessment-navigation {
    position: relative;
  }

  .pain-person-placeholder {
    grid-column: 1;
    grid-row: 2;
    height: 1px;
  }

  .assessment-outro-page {
    position: relative;
  }

  // Two Column Form Layout
  .two-column-form-layout {
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / -1;
    grid-column-gap: 2rem;

    @media screen and (min-width: 968px) {
      display: grid;
    }

    // Title/header content positioning
    > div:not(.assessment-form-pain-container) {
      grid-column: 2;
    }

    // Pain container positioning
    > .assessment-form-pain-container {
      grid-column: 1 / -1;
    }
  }

  .assessment-dynamic-image {
    display: none;

    @media screen and (min-width: 968px) {
      display: block;
    }
  }

  // Transitions
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
