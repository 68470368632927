import BaseScoreHelper from "./base-score-helper";
import { withErrorHandling } from "@/components/assessment/desk/results-helpers/error-handling.js";

export default class EnvironmentScoreHelper extends BaseScoreHelper {
  static maxEnvironmentScore = 4;

  static calculateEnvironmentPercentage(results) {
    const setupResults = results?.setup;
    if (setupResults?.noise == null || setupResults?.temperature == null) {
      return null;
    }

    let temperatureScore = 0;
    const tempVal = Math.abs(setupResults.temperature);

    // The max environment score is 4 with noise being 75% (3) and temperature being 25% (1) of that.
    // Extreme temperatures give the full score of 1, chilly / warm get 0.2.
    if (tempVal > 0) {
      temperatureScore = tempVal > 1 ? 1 : 0.2;
    }

    // As the noise slider has a max of 4, we subtract one from the answer to get the score.
    // The noise at library / conversation levels however are both score 0.
    const noiseScore = setupResults.noise < 2 ? 0 : setupResults.noise - 1;

    return this.getPercentageScore(
      temperatureScore + noiseScore,
      this.maxEnvironmentScore
    );
  }

  static getTemperatureScore(temperature) {
    const tempVal = Math.abs(temperature);
    if (tempVal === 0) return 0;
    return tempVal > 1 ? 1 : 0.2;
  }

  static getNoiseScore(noiseLevel) {
    return noiseLevel < 2 ? 0 : noiseLevel - 1;
  }

  static hasEnvironmentalData(results) {
    const setupResults = results?.setup;
    return setupResults?.noise != null && setupResults?.temperature != null;
  }

  static getEnvironmentWeight() {
    return 0.2;
  }
}

// Wrap methods with error handling
EnvironmentScoreHelper.calculateEnvironmentPercentage = withErrorHandling(
  EnvironmentScoreHelper.calculateEnvironmentPercentage,
  null
);

EnvironmentScoreHelper.getTemperatureScore = withErrorHandling(
  EnvironmentScoreHelper.getTemperatureScore,
  0
);

EnvironmentScoreHelper.getNoiseScore = withErrorHandling(
  EnvironmentScoreHelper.getNoiseScore,
  0
);

EnvironmentScoreHelper.hasEnvironmentalData = withErrorHandling(
  EnvironmentScoreHelper.hasEnvironmentalData,
  false
);

EnvironmentScoreHelper.getEnvironmentWeight = withErrorHandling(
  EnvironmentScoreHelper.getEnvironmentWeight,
  0
);
