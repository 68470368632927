<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4896_54070)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.6133 420.188L126.41 526.309L140.895 605.987H155.433L140.921 420.188H95.6133Z"
        fill="#997659"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.7717 420.188C83.9205 475.317 83.073 505.387 82.5921 510.395C82.1112 515.404 79.3808 548.95 60.7754 607.669H75.8969C100.537 551.338 109.138 517.792 111.964 510.395C114.79 502.998 123.137 472.929 135.322 420.188H81.7717Z"
        fill="#B28B67"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M94.9531 420.188C102.964 456.704 116.819 514.995 136.518 595.058H157.782C159.353 512.881 152.672 458.794 141.101 420.188H94.9531Z"
        fill="#17282F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M81.666 420.188C83.7705 475.317 78.3165 529.631 61.4219 595.916H84.1099C108.795 540.426 126.488 486.381 138.579 420.188L84.526 405.008C84.526 405.008 82.7625 409.187 82.1906 412.014C81.5563 415.149 81.666 420.188 81.666 420.188Z"
        fill="#223A43"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.6191 618.598L59.3883 606.197C59.4217 605.658 59.9453 605.29 60.4727 605.402C67.7594 606.95 77.8093 603.465 77.8093 603.465C87.924 610.63 102.506 613.918 107.64 614.902C108.634 615.093 109.271 616.095 108.995 617.068L107.846 621.12H77.8093H60.2878L58.6191 618.598Z"
        fill="#17282F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M137.648 618.598L138.418 606.197C138.451 605.658 138.975 605.29 139.502 605.402C146.789 606.95 156.839 603.465 156.839 603.465C166.953 610.63 181.535 613.918 186.669 614.902C187.663 615.093 188.3 616.095 188.024 617.068L186.875 621.12H156.839H139.317L137.648 618.598Z"
        fill="#17282F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M111.753 294.149C118.458 298.191 125.046 300.144 128.139 299.346C135.894 297.347 136.607 268.977 130.607 257.792C124.606 246.608 93.7584 241.733 92.2036 263.893C91.6639 271.584 94.8934 278.448 99.665 284.049L91.1094 323.926H115.961L111.753 294.149Z"
        fill="#B28B67"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M132.955 262.815C132.708 261.365 132.107 259.755 131.621 258.384C130.996 256.615 130.048 255.021 129.006 253.519C127.044 250.69 124.69 248.135 122.091 246.015C117.343 242.143 111.3 239.909 105.357 240.634C102.356 241.001 99.4433 242.145 96.9641 244.05C94.7242 245.772 92.5696 248.305 89.6691 248.487C86.5132 248.685 83.6203 245.9 81.0892 244.179C78.2354 242.24 75.1995 240.827 71.8642 240.277C66.2767 239.357 61.0276 241.245 57.1768 245.829C53.079 250.708 50.1003 257.862 52.7913 264.326C53.2915 265.527 53.935 266.54 54.8586 267.38C55.7056 268.149 56.9992 268.969 57.3426 270.199C57.7074 271.506 56.6177 273.193 56.1952 274.373C55.5899 276.063 55.0929 277.838 55.2696 279.675C55.5599 282.692 57.4561 285.573 59.4583 287.556C61.4959 289.574 64.0037 290.731 66.6822 291.265C68.4707 291.621 70.2986 291.818 72.1163 291.684C73.0187 291.618 73.8149 291.367 74.6892 291.168C75.5394 290.974 76.0146 291.199 76.7359 291.666C80.0869 293.832 83.6899 294.67 87.5592 294.348C90.8247 294.076 94.5886 293.285 97.2525 291.047C100.211 288.562 100.152 285.136 99.4558 281.475C100.193 281.84 102.234 282.066 101.015 280.609C100.532 280.032 99.6189 279.798 98.9991 279.458C98.2774 279.063 97.5447 278.552 96.9843 277.908C94.7216 275.305 97.0069 270.008 99.8509 269.135C104.076 267.838 105.022 273.814 108.039 275.36C109.773 276.248 111.587 275.034 112.886 273.793C114.627 272.132 115.813 269.93 116.837 267.69C117.681 265.846 118.466 263.975 119.326 262.139C119.727 261.282 121.118 258.859 120.419 257.89C123.446 257.286 126.817 258.544 129.423 260.193C130.5 260.875 131.322 261.627 131.896 262.83C132.02 263.088 132.288 263.855 132.624 263.886C133.251 263.945 133.016 263.179 132.955 262.815Z"
        fill="#070707"
      />
      <path
        d="M79.3007 340.975C77.8583 334.456 79.8115 327.281 84.3593 322.393C85.5171 321.149 87.2044 319.968 88.7955 320.568C90.3886 321.169 90.8764 323.181 90.8827 324.884C90.9078 331.97 86.1898 338.836 79.5659 341.352"
        fill="#E7E8E5"
      />
      <path
        d="M85.1287 327.312C85.0938 327.289 84.8691 327.548 84.4916 328.039C84.1078 328.525 83.5997 329.264 82.9961 330.194C81.844 332.068 80.2787 334.795 79.6298 338.182C79.3101 339.868 79.2976 341.526 79.5055 342.998C79.7079 344.476 80.1762 345.742 80.6165 346.766C81.0938 347.776 81.5383 348.563 81.9103 349.062C82.2662 349.572 82.4776 349.841 82.5132 349.817C82.6179 349.752 81.8356 348.617 81.017 346.598C80.6395 345.58 80.2306 344.344 80.0666 342.922C79.8984 341.503 79.9263 339.92 80.2334 338.296C80.8558 335.036 82.322 332.329 83.3694 330.417C84.4609 328.513 85.2103 327.369 85.1287 327.312Z"
        fill="#DADBD8"
      />
      <path
        d="M117.898 310.775C118.097 311.018 130.456 324.827 130.456 324.827C130.456 324.827 148.36 339.618 149.528 350.516C150.84 356.434 148.034 360.696 145.678 363.101C144.494 364.31 144.454 366.498 145.798 367.528C151.064 371.566 162.09 380.681 166.652 389.439C169.981 395.827 171.864 399.773 172.438 406.954C173.353 418.4 172.044 427.264 163.538 434.978C158.348 439.685 147.191 442.373 147.191 442.373C147.191 442.373 87.2843 423.647 79.2154 416.379C78.819 416.022 78.7514 415.459 78.9424 414.961L85.9943 396.569L77.9469 341.241C77.9469 341.241 75.4807 319.135 82.3419 313.509C86.8157 309.841 93.473 306.534 93.473 306.534L117.898 310.775Z"
        fill="#F8A531"
      />
      <path
        d="M115.767 370.594C115.769 370.615 116.103 370.617 116.704 370.556C117.305 370.496 118.17 370.343 119.206 370.026C121.264 369.41 124.033 367.961 126.174 365.397C128.313 362.83 129.243 359.846 129.479 357.71C129.606 356.634 129.601 355.756 129.552 355.154C129.504 354.552 129.442 354.225 129.421 354.227C129.344 354.23 129.538 355.564 129.225 357.675C128.927 359.768 127.98 362.662 125.895 365.165C123.809 367.662 121.131 369.114 119.125 369.782C117.105 370.469 115.756 370.517 115.767 370.594Z"
        fill="#C68427"
        stroke="#C68427"
        stroke-width="0.778448"
      />
      <path
        d="M117.076 373.105C119.261 373.105 121.51 372.263 123.213 370.856C125.918 368.62 127.449 365.265 128.342 361.858C128.32 361.909 128.298 361.959 128.276 362.009C127.993 362.793 127.53 363.593 126.952 364.366C126.712 364.714 126.451 365.059 126.171 365.396C124.029 367.96 121.261 369.409 119.202 370.025C118.638 370.198 118.125 370.322 117.677 370.408C115.298 371.232 113.017 371.929 113.017 371.929C114.226 372.74 115.637 373.105 117.076 373.105Z"
        fill="#DB8E24"
      />
      <path
        d="M117.679 370.408C118.127 370.322 118.64 370.198 119.204 370.025C121.262 369.409 124.031 367.96 126.172 365.396C126.453 365.059 126.713 364.714 126.953 364.366C125.381 366.465 122.952 368.364 120.956 369.189C120.006 369.582 118.83 370.01 117.679 370.408ZM128.277 362.009C128.299 361.959 128.322 361.909 128.343 361.858C128.377 361.731 128.41 361.602 128.442 361.474C128.397 361.651 128.341 361.83 128.277 362.009Z"
        fill="#0F191D"
      />
      <path
        d="M124.146 434.107C126.843 434.913 129.634 435.303 132.398 435.078L133.017 432.607C133.005 432.18 132.928 431.754 132.774 431.336C132.317 430.09 131.516 429.003 130.703 427.953C119.711 413.77 104.795 403.235 90.112 392.921C89.9187 392.784 89.7135 392.645 89.4933 392.565C88.2319 393.877 86.8257 395.342 85.3732 396.864C85.1969 397.297 85.0702 397.75 85.0117 398.224C84.8166 399.792 85.4024 401.353 86.116 402.763C89.6421 409.729 96.2394 414.503 102.235 419.503C106.68 423.21 110.933 427.194 115.838 430.266C118.393 431.866 121.214 433.232 124.146 434.107Z"
        fill="#DB8E24"
      />
      <path
        d="M75.5046 337.68C75.7014 332.968 76.6798 322.929 81.7844 317.235C87.9219 310.424 95.1529 311.101 99.7698 319.022C104.477 327.1 102.283 335.487 102.627 339.5C103.05 344.449 100.673 362.128 100.273 366.307L99.9051 381.17C99.9051 381.17 128.87 425.238 135.904 428.75L126.563 435.367C118.352 434.553 86.268 403.128 78.4244 388.012C75.1864 381.773 75.3085 342.391 75.5046 337.68Z"
        fill="#B28B67"
      />
      <path
        d="M103.099 354.225L72.8489 348.139C72.8489 348.139 75.336 329.223 78.6703 320.033C81.2634 312.884 89.3164 310.191 89.3164 310.191L102.614 318.225C102.614 318.225 104.889 328.986 104.711 338.83C104.531 348.676 103.099 354.225 103.099 354.225Z"
        fill="#F8A531"
      />
      <path
        d="M86.2706 314.371C86.2692 314.383 86.4464 314.41 86.7842 314.451C87.1575 314.495 87.6488 314.553 88.2622 314.624C89.5406 314.786 91.4087 314.985 93.6264 315.595C95.8183 316.195 98.4394 317.34 100.394 319.673C102.348 321.991 103.469 325.265 103.848 328.736C104.275 332.222 103.857 335.59 103.756 338.639C103.61 341.682 103.478 344.427 103.368 346.747C103.271 349.026 103.192 350.885 103.137 352.216C103.118 352.834 103.103 353.329 103.092 353.704C103.086 354.045 103.088 354.224 103.101 354.225C103.114 354.225 103.135 354.047 103.165 353.708C103.193 353.333 103.231 352.84 103.278 352.225C103.362 350.894 103.479 349.037 103.624 346.759C103.758 344.439 103.915 341.695 104.091 338.65C104.209 335.603 104.64 332.239 104.208 328.695C103.826 325.179 102.676 321.831 100.649 319.457C99.6411 318.278 98.4499 317.371 97.2426 316.717C96.0354 316.054 94.8142 315.648 93.6941 315.347C91.4401 314.761 89.5623 314.603 88.2768 314.483C87.632 314.428 87.131 314.396 86.7904 314.379C86.4506 314.361 86.2713 314.358 86.2706 314.371Z"
        fill="#C68427"
        stroke="#C68427"
        stroke-width="0.778448"
      />
      <path
        d="M153.007 366.307C152.92 366.064 151.758 363.833 149.902 363.493C148.59 363.252 146.005 363.962 144.604 364.398C143.989 364.589 143.5 365.046 143.241 365.635L141.448 369.709C141.448 369.709 142.245 371.431 144.777 370.344C144.777 370.344 140.587 375.218 141.166 376.836C141.745 378.454 143.115 378.968 143.115 378.968C143.115 378.968 141.063 380.888 142.237 382.384C143.41 383.882 144.942 383.061 144.942 383.061C144.942 383.061 145.192 385.8 147.937 384.545C150.682 383.29 154.45 380.3 154.45 380.3C154.45 380.3 155.254 381.784 153.688 382.507C152.121 383.231 151.223 384.556 151.223 384.556C151.223 384.556 151.545 386.722 153.376 385.976C155.209 385.23 157.023 384.947 158.456 383.34C159.889 381.733 161.421 380.911 160.652 379.269C159.883 377.629 159.525 377.391 159.525 377.391C159.525 377.391 160.008 374.846 158.754 373.926C157.5 373.005 157.5 373.005 157.5 373.005C157.5 373.005 158.233 370.462 156.77 369.433C155.308 368.404 153.464 367.33 153.464 367.33L153.007 366.307Z"
        fill="#B28B67"
      />
      <path
        d="M143.064 378.895C143.101 378.929 143.605 378.449 144.412 377.669C145.218 376.888 146.334 375.813 147.59 374.65C148.847 373.489 150.007 372.461 150.848 371.717C151.69 370.974 152.207 370.509 152.176 370.47C152.147 370.434 151.575 370.831 150.69 371.528C149.805 372.224 148.615 373.227 147.353 374.393C146.091 375.56 144.998 376.669 144.235 377.496C143.471 378.323 143.03 378.863 143.064 378.895Z"
        fill="#B77A23"
      />
      <path
        d="M144.563 383.204C144.627 383.277 146.797 381.489 149.41 379.211C152.025 376.933 154.091 375.028 154.028 374.955C153.964 374.882 151.795 376.669 149.18 378.948C146.567 381.225 144.5 383.131 144.563 383.204Z"
        fill="#B77A23"
      />
      <path
        d="M144.554 370.706C144.618 370.778 145.797 369.835 147.186 368.601C148.577 367.365 149.651 366.305 149.587 366.233C149.523 366.161 148.345 367.104 146.955 368.339C145.564 369.574 144.49 370.634 144.554 370.706Z"
        fill="#B77A23"
      />
      <path
        d="M160.351 434.991C160.351 434.991 159.626 430.685 157.039 430.881C154.45 431.077 154.14 431.272 152.484 430.391C150.828 429.51 149.793 427.064 145.446 426.966C141.098 426.868 136.751 428.826 134.267 427.847L125.157 434.991C125.157 434.991 133.542 442.233 136.232 443.309C138.924 444.386 145.342 444.679 146.377 444.288C147.412 443.896 154.45 442.233 155.176 441.841C155.9 441.45 161.904 438.71 162.007 437.828C162.111 436.948 160.93 436.162 160.083 436.363C159.235 436.565 154.777 437.728 152.691 436.616C150.606 435.504 150.002 435.372 150.002 435.372L160.351 434.991Z"
        fill="#B28B67"
      />
      <path
        d="M162.376 415.738C162.348 415.758 162.608 416.084 163.228 416.21C163.814 416.339 164.815 416.183 165.395 415.347C165.945 414.49 165.705 413.506 165.361 413.015C165.007 412.49 164.606 412.375 164.599 412.41C164.564 412.459 164.891 412.647 165.148 413.141C165.406 413.609 165.553 414.462 165.104 415.154C164.638 415.832 163.794 416.023 163.263 415.965C162.707 415.917 162.409 415.686 162.376 415.738Z"
        fill="#C68427"
      />
    </g>
    <defs>
      <clipPath id="clip0_4896_54070">
        <rect width="708" height="704" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CirculationThirdTrimester"
};
</script>
