<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="97" y="494" width="513" height="9" rx="2" fill="#C27100" />
  </svg>
</template>

<script>
export default {
  name: "StraightPlank"
};
</script>
