<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4896_54115)">
      <circle cx="599.5" cy="249.5" r="62.5" fill="#8AAFAF" />
      <g clip-path="url(#clip1_4896_54115)">
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="#C6DDDD"
        />
        <path
          d="M601 262.421L600.854 281.947L600.844 283.677C600.691 300.136 594.876 283.801 594.509 283.153C594.509 282.5 594.509 279.989 594.509 279.989L594 261L601 262.421Z"
          fill="#C7A280"
        />
        <path
          d="M593 260.421L592.896 279.947L592.888 281.677C592.779 298.136 588.626 281.801 588.364 281.153C588.363 280.5 588.363 277.989 588.363 277.989L588 259L593 260.421Z"
          fill="#B28B67"
        />
        <path
          d="M612.213 212.598C611.181 212.885 610.135 213.176 609.208 213.715C608.531 214.109 607.934 214.624 607.342 215.137C606.749 215.65 606.156 216.162 605.563 216.674C605.04 217.127 604.488 217.626 604.339 218.301C604.298 218.48 604.292 218.678 604.384 218.837C604.552 219.127 604.953 219.159 605.288 219.146C608.963 218.997 612.518 217.447 616.192 217.631C616.737 217.659 617.316 217.718 617.801 217.466C618.535 217.082 618.758 216.155 618.89 215.337C619.038 214.42 619.512 211.24 618.173 210.949C617.829 210.874 617.095 211.242 616.746 211.339C616.257 211.475 615.769 211.61 615.279 211.746C614.257 212.03 613.235 212.314 612.213 212.598Z"
          fill="#B28B67"
        />
        <path
          d="M631.805 200.519C631.975 199.76 632.015 198.91 632.15 197.937C632.648 194.335 633.225 190.743 633.713 187.141C634.215 183.446 634.933 179.781 635.905 176.18C636.313 174.665 636.796 173.125 637.768 171.894C638.73 170.673 640.274 169.801 641.81 170.039C643.089 170.237 644.167 171.174 644.801 172.303C645.496 173.543 645.722 174.995 645.838 176.405C645.996 178.316 645.963 180.237 645.793 182.147C645.197 188.873 644.583 195.614 643.385 202.262C643.039 204.183 642.63 206.133 641.648 207.818C639.919 210.785 636.679 212.507 633.499 213.802C626.733 216.554 619.46 218.052 612.157 218.199C611.909 218.205 611.702 218.009 611.694 217.761C611.632 215.698 611.473 211.871 611.617 211.618C611.807 211.281 612.19 211.113 612.547 210.965C618.223 208.612 624.563 206.382 629.814 203.207C631.101 202.428 631.574 201.559 631.805 200.519Z"
          fill="#F38B2C"
        />
        <path
          d="M624.473 214.19C624.242 212.965 616.174 213.379 614.5 208.5C611.81 200.661 621.19 188.169 623.315 184.769C624.057 183.58 625.006 182.537 625.949 181.501C628.686 178.494 631.423 177.671 634.16 174.664C634.786 173.976 635.416 174.376 636.15 173.806C639.008 171.587 643.392 171.622 646.212 173.888C648.158 175.451 648.339 175.143 648.54 177.63C648.741 180.118 648.487 182.617 648.234 185.099C647.904 188.328 647.574 191.556 647.244 194.784C646.572 201.365 645.898 207.961 645.93 214.577C644.753 215.053 639.668 215.704 638.443 215.649C634.026 215.451 631.249 215.734 626.839 215.405C625.634 215.315 624.698 215.378 624.473 214.19Z"
          fill="#FAB64D"
        />
        <path
          d="M607.301 216.967C606.268 217.254 605.223 217.545 604.296 218.084C603.619 218.478 603.022 218.993 602.43 219.505C601.836 220.019 601.244 220.531 600.651 221.044C600.128 221.496 599.577 221.995 599.426 222.67C599.386 222.849 599.38 223.046 599.472 223.205C599.64 223.496 600.041 223.529 600.376 223.515C604.051 223.366 607.606 221.817 611.279 222C611.825 222.028 612.404 222.087 612.888 221.834C613.623 221.451 613.846 220.524 613.978 219.706C614.126 218.789 614.6 215.609 613.262 215.318C612.917 215.243 612.184 215.611 611.834 215.708C611.345 215.844 610.856 215.98 610.368 216.115C609.345 216.399 608.323 216.683 607.301 216.967Z"
          fill="#ED7166"
        />
        <path
          d="M630.712 204.34C630.881 203.58 630.921 202.73 631.056 201.757C631.555 198.155 632.132 194.563 632.62 190.962C633.121 187.265 633.839 183.601 634.811 180C635.219 178.486 635.702 176.946 636.674 175.714C637.637 174.493 639.18 173.622 640.716 173.859C641.995 174.057 643.073 174.994 643.707 176.123C644.403 177.363 644.628 178.815 644.745 180.225C644.902 182.136 644.869 184.058 644.7 185.968C644.103 192.694 643.489 199.435 642.292 206.083C641.945 208.002 641.536 209.953 640.554 211.639C638.825 214.605 635.585 216.328 632.405 217.622C625.64 220.375 618.366 221.872 611.064 222.019C610.815 222.024 610.608 221.83 610.601 221.582C610.538 219.518 610.379 215.692 610.524 215.438C610.713 215.102 611.096 214.933 611.453 214.784C617.129 212.432 623.469 210.202 628.72 207.027C630.007 206.248 630.48 205.38 630.712 204.34Z"
          fill="#F79D3A"
        />
        <path
          d="M640.285 214.537C640.86 215.741 648.444 229.385 635.855 233.022C623.266 236.658 596.039 235.674 596.039 235.674C596.039 235.674 599.224 249.435 597.5 258C596.02 265.353 595 276.5 595 276.5L587.5 277C587.5 277 585.897 224.483 590 221.5C593.001 219.317 604.396 216.422 612.742 215.126C614.775 214.81 627.08 214.437 630.374 214.307C635.535 214.105 639.966 213.869 640.285 214.537Z"
          fill="#17282F"
        />
        <path
          d="M646.285 214.537C646.86 215.741 654.444 229.385 641.855 233.022C629.266 236.658 602.039 235.674 602.039 235.674C602.039 235.674 605.224 249.435 603.5 258C602.02 265.353 601 278.5 601 278.5L593.5 277C593.5 277 588.193 225.45 592.296 222.467C595.297 220.284 610.396 216.422 618.742 215.126C620.775 214.81 633.08 214.437 636.374 214.307C641.535 214.105 645.966 213.869 646.285 214.537Z"
          fill="#223A43"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M594 286.714L593.698 280.972C593.669 280.413 593.108 280.034 592.555 280.112C589.489 280.548 585.705 279 585.705 279C581.839 282.229 576.464 283.914 573.736 284.607C572.8 284.846 572.19 285.792 572.416 286.732L572.721 288H585.705H593.279L594 286.714Z"
          fill="#17282F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M602 289.714L601.665 283.933C601.634 283.387 601.094 283.013 600.553 283.093C597.154 283.592 592.87 282 592.87 282C588.567 285.266 582.565 286.951 579.596 287.63C578.628 287.852 578.005 288.831 578.259 289.79L578.579 291H592.87H601.206L602 289.714Z"
          fill="#223A43"
        />
        <path
          d="M598.362 226.065C598.976 225.98 599.644 225.684 600.29 225.396C600.891 225.13 601.513 224.854 602.042 224.777C608.826 223.788 614.975 222.576 620.401 221.509C621.824 221.228 623.216 220.954 624.583 220.689C636.93 218.313 643.936 217.01 644.006 217.001C643.936 217.008 636.879 217.892 624.515 220.274C623.148 220.538 621.755 220.812 620.331 221.093C614.911 222.159 608.767 223.369 601.994 224.356C601.403 224.443 600.752 224.733 600.123 225.012C599.506 225.286 598.868 225.569 598.318 225.645C596.533 225.892 596.575 226.313 598.362 226.065Z"
          fill="#17282F"
        />
        <path
          d="M597.605 273.402L597.724 273.378C598.181 264.836 598.246 254.765 597.771 244.333C597.699 242.739 597.613 241.1 597.519 239.461C597.415 237.655 597.297 235.826 597.17 234.025C597.137 233.542 596.975 233.016 596.788 232.491L596.788 232.49C596.598 232.383 596.411 232.271 596.223 232.159C596.227 232.172 596.233 232.185 596.238 232.199C596.469 232.833 596.71 233.488 596.749 234.041C596.876 235.839 596.993 237.666 597.097 239.47C597.19 241.108 597.275 242.746 597.349 244.339C597.823 254.755 598.062 264.872 597.605 273.402Z"
          fill="#17282F"
        />
        <path
          d="M660.105 280.971C659.476 280.971 658.919 280.567 658.723 279.97L649.14 250.663C648.466 248.601 646.542 247.205 644.372 247.205H623.63C621.503 247.205 619.608 248.545 618.899 250.549L608.482 280.002C608.277 280.583 607.728 280.971 607.113 280.971C606.135 280.971 605.441 280.066 605.686 279.144L615.506 242.304H652.671L661.516 279.179C661.735 280.093 661.044 280.971 660.105 280.971Z"
          fill="#63352C"
        />
        <path
          d="M608.276 248.322H655.025C656.178 248.322 657.248 247.714 657.837 246.721L677.871 212.987C677.871 212.987 654.946 191.365 650 189C645.053 186.636 645.765 203.675 650 224C635.924 221.527 620.782 221.575 609.534 227.026C603.429 230.768 604.237 240.739 605.05 245.593C605.313 247.169 606.678 248.322 608.276 248.322Z"
          fill="#A7A6B4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4896_54115">
        <rect
          x="535"
          y="178"
          width="127"
          height="134"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_4896_54115">
        <rect
          x="535"
          y="178"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "FlatFeet"
};
</script>
