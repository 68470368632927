import BaseScoreHelper from "./base-score-helper";
import QuestionSchema from "@/components/assessment/desk/question-schema.json";
import criteriaChecker from "@/components/common/criteria-checker.js";
import { withErrorHandling } from "@/components/assessment/desk/results-helpers/error-handling.js";
import sentry from "@/sentry.js";
import HealthScoreHelper from "@/components/assessment/desk/results-helpers/health-score-helper.js";

export default class PainHelper extends BaseScoreHelper {
  static hasRedFlags(area, answers) {
    const painSection = QuestionSchema.pain;
    const painQuestions = painSection.pages[0].questions[area];

    for (const [key, answer] of Object.entries(answers)) {
      const question = painQuestions.find(x => x.name === key);

      if (!question) {
        const message = `Question ${key} not found in ${area} pain section when getting red flags.`;
        const context = `Answers: ${JSON.stringify(
          answers
        )}, Questions: ${JSON.stringify(painQuestions)}`;
        sentry.captureMessage(message + context);
        continue;
      }

      const questionOptions = question.options ?? [];
      let hasFlag = false;

      if (Array.isArray(answer)) {
        const values = questionOptions.filter(x =>
          answer.some(t => x.value === t)
        );
        hasFlag = values.some(t => PainHelper.redFlagCheck(answers, t));
      } else {
        const value = questionOptions.find(x => x.value === answer);
        if (value) {
          hasFlag = PainHelper.redFlagCheck(answers, value);
        }
      }

      if (hasFlag) return true;
    }

    return false;
  }

  static redFlagCheck(answers, redFlagValue) {
    if (!redFlagValue?.redFlag) return false;

    if (typeof redFlagValue.redFlag === "boolean") {
      return redFlagValue.redFlag;
    }

    return criteriaChecker.areCriteriaMet(answers, redFlagValue.redFlag);
  }
}

PainHelper.hasRedFlags = withErrorHandling(PainHelper.hasRedFlags, false);
