import sentry from "@/sentry.js";

export function withErrorHandling(method, defaultValue = null) {
  return function (...args) {
    try {
      return method.apply(this, args);
    } catch (error) {
      sentry.captureException(`Error in ${method.name}`, error);
      return defaultValue;
    }
  };
}
