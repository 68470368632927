<template>
  <div>
    <button
      class="add-family-button text-small"
      @click="showDialog = true"
      :class="{ 'is-icon-only': !hasText, active: showDialog }"
    >
      <v-icon color="white" size="20">mdi-plus-circle</v-icon>
      <span
        class="pl-2"
        :class="{ 'd-sr-only': !hasText }"
        style="font-weight: 600; line-height: 1.3"
        >{{ $t("addFamily.button") }}</span
      >
    </button>
    <v-dialog
      v-model="showDialog"
      v-if="showDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '800px'"
    >
      <add-family-dialog
        v-model="showDialog"
        @closeDialog="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import AddFamilyDialog from "@/components/common/add-family/AddFamilyDialog.vue";

export default {
  name: "AddFamilyButton",
  components: {
    AddFamilyDialog
  },
  props: {
    hasText: Boolean
  },
  data() {
    return {
      showDialog: false
    };
  }
};
</script>

<style scoped lang="scss">
.add-family-button {
  border: 1px solid hsla(0, 100%, 1000%, 0.1);
  border-radius: 100px;
  color: white;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 0.5rem 0.75rem;
  transition: border-color 0.05s ease-out;

  &:hover {
    border-color: hsla(0, 100%, 1000%, 0.25);
  }

  &.is-icon-only {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    grid-template-columns: 1fr;
  }

  &.active {
    border-color: hsla(0, 0%, 0%, 0.3);
  }
}

::v-deep .v-dialog {
  border-radius: 24px !important;
}
</style>
