import * as Constants from "@/constants/constants.js";

export const routes = {
  ACCOUNT: "Account",
  ANALYTICS: "Analytics",
  BURNOUT_ASSESSMENT: "BurnoutAssessment",
  CERTIFICATION: "Certification",
  CHOOSE_TEAM: "ChooseTeam",
  CLINICAL_PAGE: "ClinicalPage",
  COGNITIVE_ASSESSMENT: "CognitiveAssessment",
  CREATE_TEAM: "CreateTeam",
  DASHBOARD: "Dashboard",
  DESK_ASSESSMENT: "DeskAssessment",
  DEV_PAGE: "DevPage",
  DRIVER_ASSESSMENT: "DriverAssessment",
  EMAIL_PAIN_CHECK_IN: "EmailPainCheckIn",
  ERROR_PAGE: "ErrorPage",
  FEEDBACK: "Feedback",
  INVITATION_SIGN_UP: "InvitationSignUp",
  JOIN_TEAM: "JoinTeam",
  LOGIN: "Login",
  MENOPAUSE_ASSESSMENT: "MenopauseAssessment",
  MENSTRUATION_ASSESSMENT: "MenstruationAssessment",
  PREGNANCY_ASSESSMENT: "PregnancyAssessment",
  MOTION_HERO: "MotionHero",
  MS_TEAMS_DASHBOARD: "MsTeamsDashboardPage",
  NOT_FOUND: "404",
  PEOPLE: "People",
  PERSONAL_SETTINGS: "PersonalSettings",
  PHYSICAL_LABOUR_ASSESSMENT: "PhysicalLabourAssessment",
  REPORTS_ARCHIVE: "ReportsArchive",
  RESET_PASSWORD: "ResetPassword",
  MAGIC_LINK_LOGIN: "MagicLinkLogin",
  SCORM: "Scorm",
  SETTINGS: "Settings",
  SHAREABLE_LINK: "ShareableLink",
  SHAREABLE_LINK_ALL_DETAILS: "ShareableLinkAllDetails",
  SHAREABLE_LINK_SOME_DETAILS: "ShareableLinkSomeDetails",
  SIGN_UP: "SignUp",
  SLACK_DEV_PAGE: "SlackDevPage",
  SUBSCRIPTION: "Subscription",
  SUGGESTIONS: "Suggestions",
  USERS: "Users",
  VISUAL_DESK_PLANNER: "VisualDeskPlanner",
  WELLNESS: "Wellness",
  SQUAT_TRACKING: "SquatTracking",
  BANKING_ASSESSMENT: "BankingAssessments",
  USER_PROFILE: "UserProfile",
  DESK_ASSESSMENT_V2: "DeskAssessmentV2"
};

export const assessmentRoutes = {
  [Constants.assessmentTypes.deskAssessment]: "DeskAssessment",
  [Constants.assessmentTypes.burnoutAssessment]: "BurnoutAssessment",
  [Constants.assessmentTypes.driverAssessment]: "DriverAssessment",
  [Constants.assessmentTypes.physicalLabourAssessment]:
    "PhysicalLabourAssessment",
  [Constants.assessmentTypes.menopauseAssessment]: "MenopauseAssessment",
  [Constants.assessmentTypes.deskV2]: "DeskAssessmentV2",
  [Constants.assessmentTypes.menstruationAssessment]: "MenstruationAssessment",
  [Constants.assessmentTypes.pregnancyAssessment]: "PregnancyAssessment"
};
