<template>
  <svg
    width="708"
    height="704"
    viewBox="0 0 708 704"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="346.5" cy="157.5" r="62.5" fill="#8AAFAF" />
    <g clip-path="url(#clip0_4896_54090)">
      <rect x="282" y="86" width="125" height="125" rx="60.6796" fill="white" />
      <rect
        x="282"
        y="86"
        width="125"
        height="125"
        rx="60.6796"
        fill="#C6DDDD"
      />
      <path
        d="M293.785 151.05V212.848"
        stroke="#A7A6B4"
        stroke-width="2.76405"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M328 172.348V234.145"
        stroke="#A7A6B4"
        stroke-width="2.76405"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M353 147L353 210"
        stroke="#A7A6B4"
        stroke-width="2.76405"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M385.354 160.826V222.623"
        stroke="#A7A6B4"
        stroke-width="2.76405"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M296.521 154.893H291V150.47H296.521V154.893Z" fill="#A7A6B4" />
      <path
        d="M384.077 160.469C388.326 163.229 387.72 166.208 382.729 167.088L331.977 176.037C326.987 176.918 319.427 175.378 315.177 172.618L293.832 158.749C289.582 155.988 290.189 153.009 295.179 152.129L345.932 143.18C350.922 142.3 358.482 143.839 362.731 146.6L384.077 160.469Z"
        fill="#A7A6B4"
      />
      <path
        d="M384.077 156.046C388.326 158.807 387.72 161.786 382.729 162.666L331.977 171.616C326.987 172.495 319.427 170.956 315.177 168.196L293.832 154.326C289.582 151.565 290.189 148.587 295.179 147.707L345.932 138.757C350.922 137.878 358.482 139.416 362.731 142.177L384.077 156.046Z"
        fill="#B9C0D6"
      />
      <path
        d="M399.08 198.36C399.097 200.976 399.111 203.676 399.111 206.652C399.46 211.627 408.663 212.348 411.331 214.305C417.441 218.785 405.162 218.551 400.682 217.679C391.186 215.829 390.731 217.912 388.666 215.293C387.113 213.325 388.394 210.7 389.324 208.838C390.382 205.808 390.332 202.019 389.335 198.592"
        fill="#3E79AE"
      />
    </g>
    <defs>
      <clipPath id="clip0_4896_54090">
        <rect
          x="282"
          y="86"
          width="125"
          height="125"
          rx="60.6796"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CirculationRoutineChange"
};
</script>
